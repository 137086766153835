import React from "react";

import { gettext, ngettext } from "app/lang";
import { navigate } from "app/page/Link";

interface Replacements {
    [key: string]: any;
}

interface BaseProps {
    text: string;
    vars?: Replacements;
    context?: string; // only for gettext extractor
    comment?: string; // only for gettext extractor
}

interface Singular extends BaseProps {
    plural?: never;
    count?: never;
}

interface Plural extends BaseProps {
    plural: string;
    count: number;
}

type Props = Singular | Plural;

export function isPlural(item: Props): item is Plural {
    return item.hasOwnProperty("plural") && item.hasOwnProperty("count");
}

const ID_PREFIX = "__trans_";
const LINK_CLASS = [
    "MuiTypography-root",
    "MuiLink-root",
    "MuiLink-underlineHover",
    "MuiTypography-colorPrimary"
];

let idCounter = 0;

function getTransId() {
    idCounter++;

    return ID_PREFIX + idCounter;
}

function attachLinkListeners(id: string) {
    const container = document.getElementById(id);

    if (!container || container.dataset.__trans_listener) {
        return;
    }

    const links = container.getElementsByTagName("A");

    for (const link of links) {
        const href = link.getAttribute("href");

        if (href) {
            // I suppose MUI will not know that it needs to add these classes to HEAD,
            // so this might only work when other components using these classes
            // are already on the page
            LINK_CLASS.forEach(className => link.classList.add(className));

            link.addEventListener("click", event => {
                event.preventDefault();
                event.stopPropagation();
                navigate(href);
            });
        }
    }

    container.dataset.__trans_listener = "yes";
}

export default function Trans(props: Props) {
    let vars: Replacements | undefined = undefined;
    let text: string;

    if (props.vars !== undefined) {
        vars = {};

        for (const name in props.vars) {
            vars[name] = escape(props.vars[name]);

        }
    }

    if (isPlural(props)) {
        if (vars === undefined) {
            vars = { count: props.count };
        } else {
            vars["count"] = props.count;
        }

        // eslint-disable-next-line
        text = ngettext(props.text, props.plural, props.count, vars);
    } else {
        // eslint-disable-next-line
        text = gettext(props.text, vars);
    }

    const id = getTransId();

    if (text.includes("<a")) {
        setTimeout(() => attachLinkListeners(id), 0);
    }

    // This is only okay because we ensure that text does not contain
    // untrusted HTML. Never ever use this elsewhere.
    return <span id={id} dangerouslySetInnerHTML={{ __html: text }} />;
}

const dangerousChars = /[&<>\"\']/;

const regexAmp = /&/g;
const regexLt = /</g;
const regexGt = />/g;
const regexApos = /\'/g;
const regexQuot = /\"/g;

function escape(value: any) {
    const str = String((value === null || value === undefined) ? "" : value);

    return dangerousChars.test(str)
        ? str
            .replace(regexAmp, "&amp;")
            .replace(regexLt, "&lt;")
            .replace(regexGt, "&gt;")
            .replace(regexApos, "&#39;")
            .replace(regexQuot, "&quot;")
        : str;
}