import _ from "app/lang";
import { PartialContext } from "app/page/ContextProvider";

export type UserCloudPermission = "manage_licenses"
    | "manage_snvr"
    | "manage_vpc"
    | "manage_sites"
    | "manage_users";

// https://stackoverflow.com/questions/55020193/is-it-possible-to-create-a-typescript-type-from-an-array
const USER_ADDON_PERMISSIONS = (<T extends string[]>(...o: T) => o)(
    "change_device_license"
);
const USER_SITE_PERMISSIONS = (<T extends string[]>(...o: T) => o)(
    "read_device",
    "read_site",
    "register",
    "delete_device",
    "change_site_config",
    "change_device_config",
    "rename_device",
    "change_device_state",
    "view_sensitive_config",
    "edit_site",
    "live_actions",
    "live_data",
    "firmware",
    "export_data",
    "view_config",
    "dashboards",
    "edit_map",
    "change_lock_config"
);

export type UserAddonPermission = typeof USER_ADDON_PERMISSIONS[number];
export type UserSitePermission = typeof USER_SITE_PERMISSIONS[number];
export type UserPermission = UserCloudPermission | UserSitePermission | UserAddonPermission;

export enum Units {
    Metric = "METRIC",
    Imperial = "IMPERIAL"
}

export function isUnit(value: unknown): value is Units {
    return Object.values(Units).includes(value as Units);
}

export interface UserAttributes {
    id: string | number;
    firstname: string;
    lastname: string;
    email: string;
    timezone: string;
    units: Units;
    date_format: string;
    time_format: string;
    preferred_language: string;
    is_administrator?: boolean;
    subscribe_newsletter: boolean;
    support_tools: boolean;
}

export const inviteStatusText = {
    "INVITATION_DECLINED": _("Invite declined"),
    "REGISTRATION_PENDING": _("Invite pending"),
    "REGISTERED": _("Invited"),
    "EXPIRED": _("Invite expired")
} as const;

export type UserRole = "ROLE_OWNER"
    | "ROLE_ADMIN"
    | "ROLE_GUEST"
    | "ROLE_USER";

export type UserRegistrationStatus = keyof typeof inviteStatusText;

const enum PermissionBits {
    MANAGE_LICENSES = 1,
    MANAGE_SNVR = 2,
    MANAGE_VPC = 4,
    MANAGE_SITES = 8
}

export interface UserCloudAttributes {
    role_id: UserRole;
    permissions: PermissionBits;
    push_alerts: boolean;
}

const userCloudPermissionBitNames: [PermissionBits, UserCloudPermission][] = [
    [PermissionBits.MANAGE_LICENSES, "manage_licenses"],
    [PermissionBits.MANAGE_SNVR, "manage_snvr"],
    [PermissionBits.MANAGE_VPC, "manage_vpc"],
    [PermissionBits.MANAGE_SITES, "manage_sites"]
];

const CLOUD_PERMISSIONS: UserCloudPermission[] = [
    "manage_users"
];

const userRoleCloudPermissions: Record<UserRole, UserCloudPermission[]> = {
    ROLE_OWNER: CLOUD_PERMISSIONS,
    ROLE_ADMIN: CLOUD_PERMISSIONS,
    ROLE_GUEST: [],
    ROLE_USER: []
};

export type UserLocale = "en"
    | "es_419"
    | "hu"
    | "ja"
    | "lt"
    | "zh_CN"
    | "zh_TW";

export const userLocalesTextual: { [key in UserLocale]: string } = {
    en: "English",
    es_419: "Español (America Latina)",
    hu: "Magyar",
    ja: "日本語",
    lt: "Lietuvių",
    zh_CN: "简体中国",
    zh_TW: "繁體中文"
};

export class User {
    public readonly id: string;
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly email: string;
    public readonly timezone: string;
    public readonly dateFormat: string;
    public readonly timeFormat: string;
    public readonly locale: UserLocale;
    public readonly role?: UserRole;

    public readonly isAdministrator: boolean;
    public readonly support_tools: boolean;

    private permissions: UserPermission[];

    constructor(context: PartialContext) {
        const user = context.user;

        this.id = typeof user.id === "number" ? user.id.toString() : user.id;
        this.firstName = user.firstname;
        this.lastName = user.lastname;
        this.email = user.email;
        this.timezone = user.timezone;
        this.locale = user.preferred_language as UserLocale;
        this.dateFormat = user.date_format;
        this.timeFormat = user.time_format;
        this.support_tools = user.support_tools;
        this.isAdministrator = (user.is_administrator === true);
        this.permissions = [];

        if (context.userCloud !== undefined) {
            this.role = context.userCloud.role_id;

            for (const [bit, permission] of userCloudPermissionBitNames) {
                if (context.userCloud.permissions & bit) {
                    this.permissions.push(permission);
                }
            }

            const rolePermissions = userRoleCloudPermissions[context.userCloud.role_id];

            this.permissions = this.permissions.concat(rolePermissions);
        }

        if (context.permissions === true) {
            this.permissions = this.permissions.concat(USER_SITE_PERMISSIONS);
        } else if (Array.isArray(context.permissions)) {
            this.permissions = this.permissions.concat(context.permissions);
        }

        if (context.addon !== undefined && this.permissions.includes("manage_licenses")) {
            this.permissions = this.permissions.concat(USER_ADDON_PERMISSIONS);
        }
    }

    hasPermission(permission: UserPermission) {
        return this.permissions.includes(permission);
    }
}

const textualRoles = {
    "ROLE_OWNER": _("Owner"),
    "ROLE_ADMIN": _("Administrator"),
    "ROLE_GUEST": _("Guest"),
    "ROLE_USER": _("Site User"),
};

export function getUserRoleTranslated(role: UserRole) {
    return (role && role in textualRoles) ? textualRoles[role] : _("Unknown");
}

export default User;
