import classNames from "classnames";
import React from "react";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

const DEFAULT_SIZE = 24;
const SMALL_SIZE = 16;

const styles = createStyles({
    root: {
        fill: "currentColor"
    },

    spin: {
        animation: "icon-spin 2s linear infinite"
    },

    "@global": {
        "@keyframes icon-spin": {
            "0%": {
                transform: "rotate(0)"
            },
            "100%": {
                transform: "rotate(359deg)"
            }
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    id: string;
    className?: string;
    size?: number;
    spin?: boolean;
    small?: boolean;
}

function Icon(props: Props) {
    const {
        spin = false,
        size = props.small ? SMALL_SIZE : DEFAULT_SIZE,
        classes
    } = props;

    const className = classNames(props.className, {
        [classes.root]: true,
        [classes.spin]: spin
    });

    const href = "#" + props.id;

    return (
        <svg className={className} width={size} height={size}>
            <use href={href} xlinkHref={href} />
        </svg>
    );
}

export default withStyles(styles)(Icon);