export type SearchResultDomain = "cloud" | "user" | "site" | "device" | "client";

export type SearchResultSource = "bssid"
    | "clouddesc"
    | "cloudname"
    | "devhost"
    | "devmac"
    | "devname"
    | "devnote"
    | "devsn"
    | "ethmac"
    | "lanip"
    | "radmac"
    | "rssid"
    | "sitedesc"
    | "siteloc"
    | "sitemails"
    | "sitename"
    | "sitenote"
    | "ssid"
    | "useremail"
    | "userfname"
    | "userlname"
    | "wanip"
    | "wchost"
    | "wcip"
    | "wclbl"
    | "wcmac";

export type SearchResultType = "mac_addr" | "ip_addr" | "token";

export interface SearchResultMatch {
    source: SearchResultSource;
    value: string;
}

export interface SearchResultAttributes {
    object_type: SearchResultDomain;
    title: string;
    object_id: string;
    cloud_id: string;
    site_id: string;
    deleted_at: number;
    updated_at: number;
    fields: SearchResultMatch[];
    historical: boolean;
}

export interface SearchType {
    type: SearchResultType;
    matches(query: string): boolean;
    sources: SearchResultSource[];
    limitSearchLabel: string;
}

function datatableHref(route: string) {
    return function (result: SearchResultAttributes) {
        const cloudId = result.cloud_id || ":cloudId";

        return `/v2/cloud/${cloudId}/${route}?search=${encodeURIComponent(result.title)}`;
    };
}

const hrefBuilders: Record<SearchResultDomain, (result: SearchResultAttributes) => string> = {
    cloud:  datatableHref("clouds"),
    user:   datatableHref("users"),
    site:   datatableHref("sites"),
    device: datatableHref("devices"),
    client: (result: SearchResultAttributes) => `/site/${result.site_id}/client/${result.object_id}`
};

export class SearchResult {
    public readonly domain: SearchResultDomain;
    public readonly title: string;
    public readonly matches: SearchResultMatch[];
    public readonly deletedAt?: Date;
    public readonly updatedAt?: Date;
    public readonly isHistorical: boolean;
    public readonly targetId: string;
    public readonly targetLink: string;

    constructor(attrs: SearchResultAttributes) {
        this.domain = attrs.object_type;
        this.title = attrs.title;
        this.matches = attrs.fields;
        this.isHistorical = attrs.historical;
        this.targetId = attrs.object_id;
        this.targetLink = hrefBuilders[attrs.object_type](attrs);

        if (attrs.deleted_at) {
            this.deletedAt = new Date(attrs.deleted_at);
        }

        if (attrs.updated_at) {
            this.updatedAt = new Date(attrs.updated_at * 1000);
        }
    }
}
