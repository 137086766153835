import React from "react";

import { handleJavaScriptError } from "./errorHandling";

interface State {
    error: Error;
    errorInfo: React.ErrorInfo;
}

class ErrorBoundaryInner extends React.Component<{}, State> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        if (this.state === null) {
            this.setState({ error, errorInfo });
        }
    }

    shouldComponentUpdate() {
        return this.state === null;
    }

    componentDidUpdate() {
        if (this.state !== null) {
            const { error, errorInfo } = this.state;

            handleJavaScriptError(error, errorInfo);
        }
    }

    render() {
        if (this.state === null) {
            return this.props.children;
        }

        return null;
    }
}

export default ErrorBoundaryInner;