import React, { useContext, useEffect, useRef, useState } from "react";

import { Popper } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";

import request from "app/http";
import _ from "app/lang";
import { DeviceHealth } from "app/models";
import { CloudContext, getUrlParams, pageContext } from "app/page/ContextProvider";
import PushAlertsToggle from "app/private/AppBar/AlertsAction/PushAlertsToggle";
import { setupTimer } from "app/timers";
import { AlertType } from "shared/types";

import { ActionProps } from "../Action";
import ActionButton from "../ActionButton";
import ActionPopover from "../ActionPopover";

import AlertList from "./AlertList";
import { AlertAttributes, RecentAlert } from "./models";

const MAX_ALERTS = 5;

export interface AlertPayloadData {
    id: string;
    alert: string;
    alert_type: AlertType;
    cloud_id: string;
    cloud_name: string;
    created_at: string;
    device_id: string;
    device_name: string;
    health: string;
    health_id: DeviceHealth;
    problem_at: string;
    site_id: string;
    site_name: string;
    vpc_id: string;
}

export interface RecentAlertsTotalsResponse {
    notViewedTotal: number;
}

export interface RecentAlertsResponse {
    alerts: AlertAttributes[];
    sees_one_site_only: boolean;
}

function responseToUnseenAlertCount(response: RecentAlertsTotalsResponse) {
    return response.notViewedTotal;
}

interface AlertsData {
    alerts: RecentAlert[];
    showSites: boolean;
}

function responseToAlertsData(response: RecentAlertsResponse) {
    return {
        alerts: response.alerts.map(attrs => new RecentAlert(attrs)),
        showSites: !response.sees_one_site_only
    };
}

interface Props extends ActionProps {

}

let alertQueueCleanTimer: (number | undefined) = undefined;

function AlertsAction(props: Props) {
    const { name, open, tooltip, iconId, onClick, onClose, fullScreen } = props;

    const [unseenCount, setUnseenCount] = useState(0);
    const [alerts, setAlerts] = useState<RecentAlert[] | null>(null);
    const [showSites, setShowSites] = useState(true);
    const [lastAlert, setLastAlert] = useState<RecentAlert | null>(null);
    const [lastAlertVisible, setLastAlertVisible] = useState<boolean>(false);

    const context = useContext(pageContext) as CloudContext;

    const pageParams = getUrlParams();
    const cloudId = pageParams.cloudId;

    const updateRecentAlertCount = () => {
        request(`/apiv2/cloud/${cloudId}/recent-alerts-totals`)
            .then(responseToUnseenAlertCount)
            .then(setUnseenCount);
    };

    const setAlertsData = (data: AlertsData) => {
        if (!data.showSites) {
            setShowSites(false);
        }
        setAlerts(data.alerts);
    };

    const updateRecentAlerts = () => {
        return request({ url: `/apiv2/cloud/${cloudId}/recent-alerts`, data: { limit: MAX_ALERTS } })
            .then(responseToAlertsData)
            .then(setAlertsData);
    };

    window.fe2.addAlertNotification = (payload) => {
        const alertProps = { ...payload.data, problem_at: undefined };

        delete alertProps.problem_at;

        const alert = new RecentAlert(alertProps);

        setLastAlert(alert);
        setLastAlertVisible(true);

        // Since we receive notifications for all clouds,
        // we have to check if current notification belongs
        // to current cloud
        if (payload.data.cloud_id === context.cloud.id) {
            setUnseenCount(unseenCount + 1);
        }

        if (alertQueueCleanTimer) {
            window.clearTimeout(alertQueueCleanTimer);
        }

        alertQueueCleanTimer = window.setTimeout(() => setLastAlertVisible(false), 3000);
    };

    const openRef = useRef(open);

    const periodicRefresh = () => {
        updateRecentAlertCount();

        if (openRef.current) {
            updateRecentAlerts();
        }
    };

    useEffect(
        () => {
            if (!context.userCloud) {
                return;
            }

            let refreshInterval;

            if (context.userCloud.push_alerts) {
                // Push alerts will update the count badge
                // so we don't need to refresh that often
                refreshInterval = 5 * 60000;
            } else {
                refreshInterval = 60000;
            }

            return setupTimer("alert-count", periodicRefresh, refreshInterval);
        },
        [context.cloud.id, context.userCloud.push_alerts]
    );

    const gotoUrlRef = useRef<string | null>(null);

    useEffect(() => {
        openRef.current = open;

        if (open) {
            updateRecentAlerts();
        } else {
            let markAsViewed: Promise<void>;

            if (alerts !== null && alerts.some(alert => !alert.get("is_viewed"))) {
                markAsViewed = request({ url: `/apiv2/cloud/${cloudId}/mark-alerts-as-viewed`, method: "POST" });
                alerts.forEach(alert => alert.set("is_viewed", false));
            } else {
                markAsViewed = Promise.resolve();
            }

            setUnseenCount(0);

            if (gotoUrlRef.current !== null) {
                const gotoUrl = gotoUrlRef.current;

                markAsViewed.then(() => location.href = gotoUrl);
            }
        }

    }, [open]);

    const handleClick = () => onClick(name);
    const handleClose = () => onClose(name);

    const handleSeeMore = () => {
        gotoUrlRef.current = `/cloud/${cloudId}/activity?health=HEALTH_CRITICAL+HEALTH_WARNING#alert`;
        handleClose();
    };

    const handleLinkClick = (href: string) => {
        gotoUrlRef.current = href;
        handleClose();
    };

    const handleAlertClick = (alertId: string) => {
        gotoUrlRef.current = `/cloud/${cloudId}/activity?id=${alertId}#alert`;
        handleClose();
    };

    const buttonElement = useRef<HTMLButtonElement>(null);

    return (
        <>
            <ActionButton onClick={handleClick}
                tooltip={tooltip}
                iconId={iconId}
                active={open}
                badgeContent={unseenCount}
                buttonRef={buttonElement}
                trackingId={props.trackingId}
            />

            {lastAlert !== null && (
                <Popper
                    open={lastAlertVisible && !open}
                    anchorEl={buttonElement.current}
                    transition
                    style={{ zIndex: 400 }}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper elevation={3} square>
                                <AlertList alerts={[lastAlert]} trackingId={`${props.trackingId}-lastAlert`} />
                            </Paper>
                        </Grow>
                    )}
                </Popper>
              )}

            <ActionPopover fixedWidth={400}
                open={open}
                title={_("Recent Alerts")}
                onClose={handleClose}
                anchorEl={buttonElement.current}
                fullScreen={fullScreen}
                settingsComponent={<PushAlertsToggle />}
            >
                <AlertList alerts={alerts}
                    showSites={showSites}
                    onSeeMoreClick={handleSeeMore}
                    onAlertClick={handleAlertClick}
                    onLinkClick={handleLinkClick}
                    trackingId={`${props.trackingId}-alerts`}
                />
            </ActionPopover>
        </>
    );
}

export default AlertsAction;
