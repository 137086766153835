import React from "react";

export function progressBarStop() {
    window.clearTimeout(window.pageLoadTimer);

    const progressBar = document.getElementById("global-progress-bar");

    if (!progressBar) {
        throw new Error("#global-progress-bar not found");
    }

    const computedStyle = getComputedStyle(progressBar);
    const computedWidth = computedStyle.getPropertyValue("width");

    // has animation has already started ?
    if (parseInt(computedWidth, 10) > 0) {
        progressBar.style.animation = "none";
        progressBar.style.width = "100%";

        window.setTimeout(() => {
            progressBar.style.display = "none";
        }, 300);
    } else {
        progressBar.style.animation = "none";
        progressBar.style.display = "none";
    }

    return null;
}

export function progressBarStart() {
    const progressBar = document.getElementById("global-progress-bar");

    if (!progressBar) {
        throw new Error("#global-progress-bar not found");
    }

    if (progressBar.style.display === "none") {
        progressBar.style.display = "block";
        progressBar.style.width = "0";
        progressBar.style.animationName = "animate-width";
        progressBar.style.animationDuration = "10s";
        progressBar.style.animationDelay = "500ms";
    }

    return null;
}

/**
 * This is just a wrapper for global progress bar
 * that exists in server side generated HTML
 */
export class ProgressBar extends React.PureComponent {
    UNSAFE_componentWillMount(): void {
        progressBarStart();
    }

    componentWillUnmount(): void {
        progressBarStop();
    }

    render() {
        return null;
    }
}
