import Long from "long";
import moment from "moment-timezone";

import { getCurrentTime } from "app/currentTime";
import _ from "app/lang";
import { getFrequencyChannel } from "shared/wlan";

interface FormatTimeOptions {
    withSeconds: boolean;
}

interface FormatFromNowOptions {
    utc: boolean;
}

function toFixed(num: number) {
    if (num < 10) {
        // More precision for small numbers
        return Math.round(num * 100) / 100;
    } else if (num < 100) {
        return Math.round(num * 10) / 10;
    } else {
        return Math.round(num);
    }
}

const _mcsPattern = /^(MCS\d+) +(\d+(?:\.\d+)?)$/;
const _vhtPattern1 = /^(VHT)\s+(0[xX][0-9a-fA-F]+)\s+(\d+)$/;
const _vhtPattern2 = /^(VHT\s+NSS\d+-MCS\d+)\s+(\d+)$/;
const VHTRates: { [key: string]: string } = {
    "0x90": "0",
    "0x91": "1",
    "0x92": "2",
    "0x93": "3",
    "0x94": "4",
    "0x95": "5",
    "0x96": "6",
    "0x97": "7",
    "0x98": "8",
    "0x99": "9",
    "0x9a": "0",
    "0x9b": "1",
    "0x9c": "2",
    "0x9d": "3",
    "0x9e": "4",
    "0x9f": "5",
    "0xa0": "6",
    "0xa1": "7",
    "0xa2": "8",
    "0xa3": "9"
};

interface Props {
    time_format: string;
    date_format: string;
    timezone: string;
}

export default class Formatter {
    timeFormat: string;
    dateFormat: string;
    timezone: string;

    constructor(props: Props) {
        this.timeFormat = props.time_format;
        this.dateFormat = props.date_format;
        this.timezone = props.timezone;
    }

    formatDate(date: Date, format?: string) {
        if (!(date instanceof Date)) {
            throw new TypeError("formatDate() expects a Date argument");
        }

        return moment(date).tz(this.timezone).format(format || this.dateFormat);
    }

    formatDateMoment(date: moment.Moment, format?: string) {
        return date.tz(this.timezone).format(format || this.dateFormat);
    }

    formatTime(time: Date, options: FormatTimeOptions = { withSeconds: false }) {
        if (!(time instanceof Date)) {
            throw new TypeError("formatTime() expects a Date argument");
        }

        const format = options.withSeconds
            ? this.timeFormat.replace(":mm", ":mm:ss")
            : this.timeFormat;

        return moment(time).tz(this.timezone).format(format);
    }

    formatDateTime(dateTime: Date, options: FormatTimeOptions = { withSeconds: false }) {
        if (!(dateTime instanceof Date)) {
            throw new TypeError("formatDateTime() expects a Date argument");
        }

        const timeFormat = options.withSeconds
            ? this.timeFormat.replace(":mm", ":mm:ss")
            : this.timeFormat;

        return moment(dateTime).tz(this.timezone).format(this.dateFormat + " " + timeFormat);
    }

    fromNow(date: Date, options?: FormatFromNowOptions) {
        if (!(date instanceof Date)) {
            throw new TypeError("formatDateTime() expects a Date argument");
        }

        const defaults: FormatFromNowOptions = {
            // whether the given date-time is in UTC
            utc: true
        };

        options = Object.assign({}, defaults, options);

        // unlike formatDateTime, we want a UTC date here, not a local one
        // therefore, if we are given a local date, convert to UTC
        const convertTo: any = options.utc ? this.timezone : "utc";
        const nowUtc = moment.utc(getCurrentTime());

        return moment(date, convertTo).from(nowUtc);
    }

    formatDuration(seconds: number) {
        return moment.duration(seconds, "seconds").humanize();
    }

    formatMonth(months: number) {
        const year = Math.floor(months / 12);
        const month = Math.floor(months % 12);
        const year_label = (year > 1) ? " years" : " year";
        const month_label = (month > 1) ? " months" : " month";
        let output = "";

        if (year !== 0) {
            output = year + year_label;
        }

        if (month !== 0) {
            output += " " + month + month_label;
        }
        return output;
    }

    formatChannel(frequency: number) {
        return getFrequencyChannel(frequency) + ` (${frequency} ${_("MHz")})`;
    }

    formatBytes(value: Long): string;
    formatBytes(value: number): string;

    formatBytes(param: number | Long) {
        let value: Long;

        if (typeof param === "number") {
            value = Long.fromNumber(param, true);
        } else if (param instanceof Long) {
            value = param;
        } else {
            throw new TypeError("invalid parameter passed to formatBytes()");
        }

        const base = 1000;
        const units = [_("B"), _("kB"), _("MB"), _("GB"), _("TB")];
        let exponentPow = 0;

        for (let exponent = units.length - 1; exponent >= 0; exponent--) {
            exponentPow = Math.pow(base, exponent);
            if (value.greaterThanOrEqual(exponentPow)) {
                return toFixed(value.divide(exponentPow).toNumber())
                    + " " + units[exponent];
            }
        }

        return value;
    }

    formatTransferRate(text: string) {
        let match;

        if ((match = _mcsPattern.exec(text))) {
            return match[2] + " " + _("Mbps") + " (" + match[1] + ")";
        }

        if ((match = _vhtPattern1.exec(text))) {
            return match[3] + " " + _("Mbps") +
                " (VHT MCS " + VHTRates[match[2]] + ")";
        }

        if ((match = _vhtPattern2.exec(text))) {
            return match[2] + " " + _("Mbps") +
                " (" + match[1] + ")";
        }

        if (!isNaN(parseFloat(text))) {
            return text + " " + _("Mbps");
        }

        return text;
    }

    formatTransferBits(v: number) {
        // 1 kbps = 1000 bps
        const mul = 1000;

        if (v >= mul * mul * mul) {
            return toFixed(v / mul / mul / mul) + " " + _("Gb/s");
        } else if (v >= mul * mul) {
            return toFixed(v / mul / mul) + " " + _("Mb/s");
        } else if (v >= mul) {
            return toFixed(v / mul) + " " + _("kb/s");
        } else {
            return toFixed(v) + " " + _("b/s");
        }
    }

    formatFrequency(frequency: number) {
        let ghz: string;

        if (frequency >= 2412 && frequency <= 2484) {
            ghz = "2.4";
        } else if (frequency >= 4980 && frequency <= 5865) {
            ghz = "5";
        } else if (frequency >= 58320 && frequency <= 69120) {
            ghz = "60";
        } else {
            ghz = (frequency / 1000).toFixed(2).toString();
        }

        return ghz + " " + _("GHz");
    }

    formatNumber(num: number | string) {
        return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    formatCurrency(amount: number, symbol = "$") {
        const formatted = symbol + this.formatNumber(Math.abs(amount).toFixed(2));

        return amount < 0 ? `(${formatted})` : formatted;
    }
}
