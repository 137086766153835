import React, { useContext, useEffect, useState } from "react";

import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { isWidthUp } from "@material-ui/core/withWidth";

import _ from "app/lang";
import { pageContext } from "app/page/ContextProvider";
import { navigate } from "app/page/Link";
import { addSystemErrorListener, removeSystemErrorListener } from "app/page/Page";
import { appBarHeight } from "app/theme";
import { getBreakpoint } from "app/useBreakpoint";
import { getFullScreen } from "app/useFullScreen";
import useWidth from "app/useWidth";

import ActionButton from "./ActionButton";
import AlertsAction from "./AlertsAction";
import CloudAction from "./CloudAction";
import InboxAction from "./InboxAction";
import MobileMenuAction from "./MobileMenuAction";
import SearchAction from "./SearchAction";
import TasksAction from "./TasksAction";
import UserAction from "./UserAction/UserAction";

const styles = (theme: Theme) => createStyles({
    container: {
        flexWrap: "nowrap",
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 20,
            width: "100%",
            flexShrink: 1
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: appBarHeight,
        },
    },

    root: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
        },
    }
});

interface Props extends WithStyles<typeof styles> {

}

function ActionBar(props: Props) {
    const [activeAction, setActiveAction] = useState<string | null>(null);
    const [unreadCount, setUnreadCount] = useState(0);

    const width = useWidth();
    const breakpoint = getBreakpoint(width);
    const fullScreen = getFullScreen(width);

    const showDropdowns = isWidthUp("md", breakpoint, true);

    const { classes } = props;
    const { cloud, user } = useContext(pageContext);

    const showMobileMenu = breakpoint === "xs";

    useEffect(() => {
        // Close active action on system errors so that the error message is visible
        const errorListener = () => {
            setActiveAction(null);
        };

        addSystemErrorListener((errorListener));

        return () => removeSystemErrorListener(errorListener);
    }, []);

    const isActionActive = (itemId: string) => activeAction === itemId;
    const closeAction = () => setActiveAction(null);

    const userAction = (
        <UserAction name="user"
            trackingId="navbar-user"
            tooltip={_("User")}
            iconId="account_circle"
            dropdownText={_("Hi, :name", {name: user.firstname})}
            open={isActionActive("user")}
            dropdown={showDropdowns}
            onClick={setActiveAction}
            onClose={closeAction}
            fullScreen={showMobileMenu}
            showMenuButton={!showMobileMenu}
        />
    );

    if (!cloud) {
        return (
            <div className={classes.root}>
                {userAction}
            </div>
        );
    }

    const mobileMenu = showMobileMenu ? (
        <MobileMenuAction name="mobile"
            trackingId="navbar-mobileMenu"
            tooltip={_("More")}
            iconId="more_vert"
            open={isActionActive("mobile")}
            onClick={setActiveAction}
            onClose={closeAction}
            onInboxClick={() => setActiveAction("inbox")}
            onCloudClick={() => setActiveAction("cloud")}
            onUserClick={() => setActiveAction("user")}
            unreadPostCount={unreadCount}
        />
    ) : null;

    return (
        <div className={classes.container}>
            <div className={classes.root}>
                <SearchAction name="search"
                    trackingId="navbar-search"
                    tooltip={_("Search")}
                    iconId="search"
                    open={isActionActive("search")}
                    fullScreen={fullScreen}
                    onClick={setActiveAction}
                    onClose={closeAction}
                />

                <TasksAction name="tasks"
                    trackingId="navbar-tasks"
                    tooltip={_("Tasks")}
                    iconId="list"
                    open={isActionActive("tasks")}
                    fullScreen={fullScreen}
                    onClick={setActiveAction}
                    onClose={closeAction}
                />

                <InboxAction name="inbox"
                    trackingId="navbar-inbox"
                    tooltip={_("Latest News")}
                    iconId="inbox"
                    open={isActionActive("inbox")}
                    fullScreen={showMobileMenu}
                    onClick={setActiveAction}
                    onClose={closeAction}
                    showMenuButton={!showMobileMenu}
                    unreadCount={unreadCount}
                    setUnreadCount={setUnreadCount}
                />

                <AlertsAction name="alerts"
                    trackingId="navbar-alerts"
                    tooltip={_("Alerts")}
                    iconId="notifications"
                    open={isActionActive("alerts")}
                    fullScreen={fullScreen}
                    onClick={setActiveAction}
                    onClose={closeAction}
                />

                <ActionButton
                    trackingId="navbar-LinqPath"
                    tooltip={_("LinqPath") + "™"}
                    iconId="linqpath"
                    onClick={() => navigate("/v2/cloud/:cloudId/linqpath")}
                />

                <CloudAction name="cloud"
                    trackingId="navbar-cloud"
                    tooltip={_("Cloud")}
                    iconId="cloud"
                    dropdownText={cloud.name}
                    open={isActionActive("cloud")}
                    dropdown={showDropdowns}
                    onClick={setActiveAction}
                    onClose={closeAction}
                    fullScreen={showMobileMenu}
                    showMenuButton={!showMobileMenu}
                />

                {userAction}

                {mobileMenu}
            </div>
        </div>
    );
}

export default withStyles(styles)(ActionBar);