import classNames from "classnames";
import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";

const DEFAULT_SIZE = 24;

const styles = createStyles({
    root: {
        display: "flex",
        overflow: "hidden"
    },

    spinner: {
        alignSelf: "center",
        justifySelf: "center",
        margin: "auto",
        verticalAlign: "middle"
    },

    center: {
        margin: "auto"
    }
});

interface Props extends WithStyles<typeof styles> {
    size?: number;
    center?: boolean;
    className?: string;
}

function Loading(props: Props) {
    const { classes, size = DEFAULT_SIZE, center = false, className } = props;

    return (
        <div className={classNames(classes.root, className, center && classes.center)}>
            <CircularProgress
                className={classes.spinner}
                size={size}
            />
        </div>
    );
}

export default withStyles(styles)(Loading);
