import React, { useEffect, useRef, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import SearchField from "app/SearchField";
import request from "app/http";
import _ from "app/lang";

import { ActionProps } from "../Action";
import ActionButton from "../ActionButton";
import ActionFooter from "../ActionFooter";
import ActionPopover from "../ActionPopover";

import ResultList from "./ResultList";
import { SearchResult, SearchResultAttributes, SearchResultSource, SearchType } from "./models";

const MIN_QUERY_LENGTH = 3;

const allSources: SearchResultSource[] = [
    "bssid",
    "clouddesc",
    "cloudname",
    "devhost",
    "devmac",
    "devname",
    "devnote",
    "devsn",
    "ethmac",
    "lanip",
    "radmac",
    "rssid",
    "sitedesc",
    "siteloc",
    "sitemails",
    "sitename",
    "sitenote",
    "ssid",
    "useremail",
    "userfname",
    "userlname",
    "wanip",
    "wchost",
    "wcip",
    "wclbl",
    "wcmac"
];

const limitedSources: SearchResultSource[] = [
    "devname",
    "devsn",
    "devhost",
    "devnote",
    "sitename",
    "siteloc",
    "sitedesc",
    "sitemails",
    "sitenote",
    "cloudname",
    "userfname",
    "userlname",
    "useremail",
    "rssid",
    "ssid",
    "wclbl",
    "wchost",
    "clouddesc"
];

const macSources: SearchResultSource[] = [
    "devmac",
    "bssid",
    "radmac",
    "ethmac",
    "wcmac"
];

const ipSources: SearchResultSource[] = [
    "wanip",
    "lanip",
    "wcip"
];

const defaultSearchType: SearchType = {
    type: "token",
    matches: (query: string) => true,
    sources: limitedSources,
    limitSearchLabel: _("Limit search to names and locations only.")
};

const targetedSearchTypes: SearchType[] = [
    {
        type: "mac_addr",
        matches: (query: string) => {
            /*
             * The standard (IEEE 802) format for printing EUI-48 addresses in
             * human-friendly form is six groups of two hexadecimal digits,
             * separated by hyphens (-). Other conventions include six groups
             * of two hexadecimal digits separated by colons (:), and three groups
             * of four hexadecimal digits separated by dots (.)
             */
            return /[a-f0-9]{2}[:\-\.][a-f0-9]{2}/i.test(query);
        },
        sources: macSources,
        limitSearchLabel: _("Limit search to :type only.", { type: _("MAC addresses") })
    },
    {
        type: "ip_addr",
        matches: (query: string) => {
            return /^[0-9.]+$/i.test(query) && (
                /[12][0-9]{1,2}\./i.test(query) || /\.[12][0-9]{1,2}/i.test(query)
            );
        },
        sources: ipSources,
        limitSearchLabel: _("Limit search to :type only.", { type: _("IP addresses") })
    }
];

const getSearchType = (query: string): SearchType => {
    for (const type of targetedSearchTypes) {
        if (type.matches(query)) {
            return type;
        }
    }

    return defaultSearchType;
};

const styles = (theme: Theme) => createStyles({
    gutters: {
        padding: theme.spacing(2)
    }
});

type Props = ActionProps & WithStyles<typeof styles>;

interface FindResponse {
    current: SearchResultAttributes[];
    historical: SearchResultAttributes[];
}

function SearchAction(props: Props) {
    const {
        name,
        open,
        tooltip,
        iconId,
        onClick,
        onClose,
        fullScreen,
        classes
    } = props;

    const [searchQuery, setSearchQuery] = useState("");
    const [limitSearch, setLimitSearch] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState<SearchResult[] | null>(null);
    const [limitSearchLabel, setLimitSearchLabel] = useState(defaultSearchType.limitSearchLabel);

    useEffect(() => {
        if (!open) {
            setSearchResults(null);
            setSearchQuery("");
            setLimitSearchLabel(defaultSearchType.limitSearchLabel);
        }
    }, [open]);

    const performSearch = (query: string, limitSources: boolean) => {
        query = query.trim();

        const searchType = getSearchType(query);
        const sources = limitSources ? searchType.sources : allSources;

        setIsSearching(true);
        setLimitSearchLabel(searchType.limitSearchLabel);

        request({
            url: "/apiv2/cloud/:cloudId/search/find",
            method: "POST",
            data: {
                query: query,
                sources: sources,
            }
        }).then((data: FindResponse) => {
            return data.current.concat(data.historical);
        }).then(results => {
            return results.map(attrs => new SearchResult(attrs));
        }).then(results => {
            setSearchResults(results);
        }).finally(() => {
            setIsSearching(false);
        });
    };

    const handleQueryChange = (query: string) => {
        setSearchQuery(query);

        if (query.length >= MIN_QUERY_LENGTH) {
            performSearch(query, limitSearch);
        } else {
            setSearchResults(null);
            setLimitSearchLabel(defaultSearchType.limitSearchLabel);
        }
    };

    const handleLimitChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
        setLimitSearch(checked);

        if (searchQuery.length >= MIN_QUERY_LENGTH) {
            performSearch(searchQuery, checked);
        }
    };

    const buttonElement = useRef<HTMLButtonElement>(null);

    const handleClick = () => onClick(name);
    const handleClose = () => onClose(name);

    const hasResults = (searchResults !== null && searchResults.length > 0);

    let searchStatus: string | undefined;

    if (searchResults === null) {
        // Non-breakable space: use this so that the status is rendered blank
        // and total search field height doesn't change until there are results
        // to render.
        searchStatus = "\u00a0";
    } else if (searchResults.length === 0) {
        searchStatus = _("Nothing found. Please refine your search phrase and try again.");
    }

    return (
        <>
            <ActionButton onClick={handleClick}
                tooltip={tooltip}
                iconId={iconId}
                active={open}
                buttonRef={buttonElement}
                trackingId={props.trackingId}
            />
            <ActionPopover fixedWidth={400}
                open={open}
                title={_("Search Everywhere")}
                showTitleOnlyWhenFullScreen
                onClose={handleClose}
                anchorEl={buttonElement.current}
                fullScreen={fullScreen}
            >
                <div className={classes.gutters}>
                    <SearchField busy={isSearching}
                        onChange={handleQueryChange}
                        minLength={MIN_QUERY_LENGTH}
                        status={searchStatus}
                    />
                </div>
                {hasResults && <Divider />}
                <ResultList
                    trackingId={`${props.trackingId}-resultList`}
                    query={searchQuery}
                    results={searchResults}
                    onClick={() => props.onClose(name)}
                />
                <ActionFooter>
                    <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label={limitSearchLabel}
                        onChange={handleLimitChange}
                        checked={limitSearch}
                    />
                </ActionFooter>
            </ActionPopover>
        </>
    );

}

export default withStyles(styles)(SearchAction);
