import classNames from "classnames";
import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";

const styles = createStyles({
    root: {
        position: "relative",
        padding: "10px 40px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        userSelect: "none"
    },

    button: {
        position: "absolute",
        top: 6,
        padding: 3,
        color: "#fff",
        opacity: 0.8,
        "&:hover": {
            opacity: 1.0
        },
        "&:focus": {
            opacity: 1.0
        }
    },

    backButton: {
        left: 7
    },

    forwardButton: {
        right: 7
    }
});

const useStyles = makeStyles(styles);

interface Props {
    className: string;
    onBack?: () => void;
    onForward?: () => void;
    backTip?: string;
    forwardTip?: string;
    children: React.ReactNode;
}

function MenuHeader(props: Props) {
    const {
        className,
        onBack,
        backTip,
        onForward,
        forwardTip,
        children
    } = props;

    const classes = useStyles();

    return (
        <div className={classNames(className, classes.root)}>
            <div className="focus-container" tabIndex={0} style={{ display: "none", width: 0 }} />
            {backTip ? (
                <Tooltip title={backTip}>
                    <IconButton
                        onClick={onBack}
                        className={classNames(classes.button, classes.backButton)}
                    >
                        <Icon id="chevron_left" />
                    </IconButton>
                </Tooltip>
            ) : null}
            {children}
            {forwardTip ? (
                <Tooltip title={forwardTip}>
                    <IconButton
                        onClick={onForward}
                        className={classNames(classes.button, classes.forwardButton)}
                    >
                        <Icon id="chevron_right" />
                    </IconButton>
                </Tooltip>
            ) : null}

        </div>
    );
}

export default MenuHeader;
