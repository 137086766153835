import React from "react";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import Loading from "app/Loading";
import DialogCloseButton from "app/private/DialogCloseButton";
import useFullScreen from "app/useFullScreen";

const maxWidthBreakpoint = "sm";

interface Props {
    open: boolean;
    title: string;
    canClose?: boolean;
    onClose?: () => void;
    onExited: () => void;
    children: React.ReactNode;
    isLoading?: boolean;
    ref?: any;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

function DialogWrapper(props: Props) {
    const { open, title, onClose, onExited, isLoading = false, children, ref } = props;
    const fullScreen = useFullScreen();
    const canClose = props.canClose !== false;

    const content = isLoading
        ? <Box pb={3}><Loading size={48} /></Box>
        : children;

    const width = (props.maxWidth !== undefined) ? props.maxWidth : maxWidthBreakpoint;

    return (
        <Dialog open={open}
            maxWidth={width}
            fullWidth
            fullScreen={fullScreen}
            onExited={onExited}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown={!canClose}
            ref={ref}
        >
            <DialogTitle>
                <DialogCloseButton visible={canClose} onClick={onClose} />
                {title}
            </DialogTitle>
            {content}
        </Dialog>
    );
}

export default DialogWrapper;
