interface Timers {
    [key: string]: number;
}

const timers: Timers = {};

export function debounce(callback: CallableFunction, delay: number, name?: string): void {
    if (!name) {
        name = callback.name;
    }

    window.clearTimeout(timers[name]);

    timers[name] = window.setTimeout(callback, delay);
}

export function clearDebouceTimer(name: string) {
    window.clearTimeout(timers[name]);
}
