import React from "react";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";

import Button from "app/Button";
import _ from "app/lang";
import { createEvent } from "app/utils";

import SystemDialog, { SystemDialogCommands } from "./SystemDialog";

interface Props {
    title: string;
    submitLabel: string;
    cancelLabel: string;
    initialValue?: string;
    allowBlank: boolean;
    validate: (value: string) => boolean | string | Promise<boolean | string>;
    placeholder?: string;
    children: React.ReactNode;
    onSubmit: (value: string) => void;
}

interface State {
    value: string;
    submitAttempted: boolean;
    validationError: string | null;
}

class InputDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.initialValue || "",
            submitAttempted: false,
            validationError: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    private dialogCommands!: SystemDialogCommands;

    render() {
        const {
            title,
            children,
            submitLabel,
            cancelLabel,
            placeholder
        } = this.props;

        const { value, validationError, submitAttempted } = this.state;

        let form: HTMLFormElement;

        return (
            <SystemDialog title={title}
                commandsRef={commands => this.dialogCommands = commands}
                onClose={this.handleClose}
            >
                <DialogContent>
                    <DialogContentText>
                        {children}
                    </DialogContentText>
                    <form onSubmit={this.handleSubmit} ref={element => form = element!}>
                        <TextField
                            autoFocus
                            fullWidth
                            margin="normal"
                            placeholder={placeholder}
                            value={value}
                            onChange={this.handleChange}
                            error={validationError !== null}
                            spellCheck={false}
                            helperText={validationError}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={this.handleCancel}
                    >
                        {cancelLabel}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={submitAttempted && (validationError !== null)}
                        onClick={() => form.dispatchEvent(createEvent("submit"))}
                    >
                        {submitLabel}
                    </Button>
                </DialogActions>
            </SystemDialog>
        );
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;

        if (!this.state.submitAttempted) {
            this.setState({ value });
            return;
        }

        this.validate(value).then(validationError => {
            this.setState({
                value,
                validationError
            });
        });
    }

    private handleCancel() {
        this.dialogCommands.close();
    }

    private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const value = this.state.value;
        const form = event.target as HTMLFormElement;

        this.validate(value).then(validationError => {
            if (validationError !== null) {
                this.setState({
                    validationError,
                    submitAttempted: true
                }, () => {
                    const input = form.elements[0] as HTMLInputElement;

                    input.focus();
                });

                return;
            }

            this.dialogCommands.close(value);
        });
    }

    private handleClose(value: string | undefined) {
        if (value !== undefined) {
            this.props.onSubmit(value);
        }
    }

    private validate(value: string): Promise<string | null> {
        if (!this.props.allowBlank && /^\s*$/.test(value)) {
            return Promise.resolve(_("Blank values not allowed."));
        }

        if (this.props.validate === undefined) {
            return Promise.resolve(null);
        }

        let validatePromise = this.props.validate(value);

        if (typeof validatePromise !== "object") {
            validatePromise = Promise.resolve(validatePromise);
        }

        return validatePromise.then((result: string | boolean) => {
            let errorMessage: string | null;

            if (typeof result === "string") {
                errorMessage = result;
            } else if (result === true) {
                errorMessage = null;
            } else {
                errorMessage = _("The value you entered is not valid.");
            }

            return errorMessage;
        });

    }
}

export default InputDialog;