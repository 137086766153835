const storage = {
    hasItem: (key: string): boolean => {
        return window.localStorage && !!window.localStorage.getItem(key);
    },

    getItem: <T>(key: string, defaults?: any): T => {
        if (!window.localStorage && defaults) {
            return defaults;
        }

        if (!storage.hasItem(key) && defaults) {
            storage.setItem(key, defaults);
        }

        return JSON.parse(
            window.localStorage.getItem(key) as string
        );
    },

    setItem: <T>(key: string, value: T): void => {
        window.localStorage && window.localStorage.setItem(
            key,
            JSON.stringify(value)
        );
    }
};

export default storage;