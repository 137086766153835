import React from "react";
import ReactDOM from "react-dom";

import productBrandImages from "@public-json/productBrandImages.json";

import { detectModernBrowser } from "app/detect";
import { HttpError } from "app/http";
import { SystemMessageOptions } from "app/page/Page";
import {
    disableAlert,
    getUserAlert,
    setUserAlert,
    shouldShowAlert
} from "app/userAlertStore";
import { importExternalScript, parseFwVersion, versionCompare } from "app/utils";

import { AlertPayloadData } from "./private/AppBar/AlertsAction/AlertsAction";

// Expose react so it can be used in legacy dashboard
window.React = React;
window.ReactDOM = ReactDOM;

// Legacy code can use fe2 methods
window.fe2 = window.fe2 || {};
window.fe2.importExternalScript = importExternalScript;
window.fe2.productBrandImages = productBrandImages;
window.fe2.shouldShowUserAlert = shouldShowAlert;
window.fe2.disableUserAlert = disableAlert;
window.fe2.getUserAlert = getUserAlert;
window.fe2.setUserAlert = setUserAlert;
window.fe2.versionCompare = versionCompare;
window.fe2.parseFwVersion = parseFwVersion;

declare global {
    interface Window {
        React: typeof React;
        ReactDOM: typeof ReactDOM;
        fe2: {
            setUserAlert: typeof setUserAlert;
            getUserAlert: typeof getUserAlert;
            shouldShowUserAlert: typeof shouldShowAlert;
            disableUserAlert: typeof disableAlert;
            setLastBreadcrumb(text: string): void;
            showSystemMessage(opts: SystemMessageOptions): void;
            clearSystemMessage(): void;
            addAlertNotification(payload: { data: AlertPayloadData }): void;
            importExternalScript: typeof importExternalScript;
            HttpError: typeof HttpError;
            productBrandImages: typeof productBrandImages;
            versionCompare: typeof versionCompare;
            parseFwVersion: typeof parseFwVersion;
        };
        IgniteNotifications?: any;
        firebase?: any;
        dataLayer?: any;
        jsError?: Error;
        jsErrorTrace?: string;
        pageLoadTimer: number;
    }
}

function getContainer(id: string): HTMLElement {
    const container = document.getElementById(id);

    if (container === null) {
        throw new Error(`Container element id="${id}" was not found in document`);
    }

    return container;
}

function loadIcons(): Promise<void> {
    const iconsLink = document.getElementById("icons-link") as HTMLLinkElement;

    // This fetch call should use a cached icons file, which we previously preloaded
    // Will insert SVG to document head, so that icons can be accesses by just their ID
    // thus avoiding loading the SVG on every render
    return fetch(iconsLink.href)
        .then(response => response.text())
        .then(svgText => {
            const parser = new DOMParser();
            const svgElement = parser.parseFromString(svgText, "image/svg+xml");

            getContainer("svg-compartment").appendChild(svgElement.documentElement);
        });
}

function renderPage(component: React.ReactElement) {
    loadIcons();

    ReactDOM.render(component, getContainer("app"));
}

export function render(component: React.ReactElement) {
    if (!detectModernBrowser()) {
        console.log("Detected old browser, will load polyfills.");
        // Delays rendering until polyfills are loaded. Old browsers are very slow, right ?
        import("app/private/polyfills").then(() => renderPage(component));
    } else {
        renderPage(component);
    }
}
