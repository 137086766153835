import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "app/Icon";
import Loading from "app/Loading";
import _ from "app/lang";

import TaskListItem from "./TaskListItem";
import { Task } from "./models";

interface Props {
    tasks: Task[] | null;
    noTasksMessage: string;
    onTaskClick: (id: string) => void;
    onLinkClick: (href: string) => void;
    trackingId: string;
}

function TaskList(props: Props) {
    const { tasks, onLinkClick, onTaskClick } = props;

    if (tasks === null) {
        return <TaskListLoading />;
    }

    if (tasks.length === 0) {
        return <TaskListEmpty message={props.noTasksMessage} />;
    }

    return (
        <List disablePadding>
            {tasks.map(task => (
                <TaskListItem key={task.id}
                    trackingId={`${props.trackingId}-item`}
                    task={task}
                    onLinkClick={onLinkClick}
                    onTaskClick={onTaskClick}
                />
            ))}
        </List>
    );
}

function TaskListLoading() {
    return (
        <List disablePadding>
            <Divider />
            <ListItem dense>
                <Loading center size={40} />
            </ListItem>
        </List>
    );
}

function TaskListEmpty(props: { message: string }) {
    return (
        <List disablePadding>
            <Divider />
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <Icon id="beach_access" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.message}
                    secondary={_("(during last 7 days)")}
                />
            </ListItem>
        </List>
    );
}

export default TaskList;
