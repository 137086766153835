import React from "react";

import Button from "app/Button";
import { progressBarStop } from "app/ProgressBar";
import { FetchJSONError, HttpError, NetworkError, ValidationError } from "app/http";
import _ from "app/lang";
import { staticContext } from "app/page/ContextProvider";
import { navigate } from "app/page/Link";
import { showSystemDialog, showSystemMessage } from "app/page/Page";
import { askConfirmation } from "app/page/dialogs";
import ErrorReportDialog from "app/private/ErrorReportDialog";
import { MessageActions, MessageVariant } from "app/private/SystemMessage";
import { unregisterTimers } from "app/timers";
import { reloadPage } from "app/utils";
import InformationalDialog from "shared/dialogs/InformationalDialog.tsx";

function handleFetchJSONError(error: FetchJSONError) {
    let message;
    let actionButton: MessageActions = (
        <Button variant="text" onClick={reloadPage}>
            {_("Reload Page")}
        </Button>
    );

    if (navigator && navigator.onLine === false) {
        message = _(
            "It looks like you might be offline. Please check your internet "
            + "connection and try reloading the page."
        );
    } else if (error instanceof NetworkError) {
        message = _(
            "Could not reach the cloud servers. Please check your "
            + "internet connection and try reloading the page."
        );
    } else if (error instanceof ValidationError) {
        if (error.appVersion !== null && error.appVersion !== staticContext.appVersion) {
            showAppUpdatedNotification("error");
            showAppUpdatedDialog();
            return;
        }
        // TODO: maybe a more detailed message for validation errors?
    } else if (error instanceof HttpError) {
        switch (error.statusCode) {
            case 401:
                // This should decrease the amount of non 200
                // responses from our servers
                unregisterTimers();

                showSystemDialog(
                    (
                        <InformationalDialog
                            title={_("Login Session Expired")}
                            text={_("Your login session has expired. Please log in again if prompted.")}
                            onClick={reloadPage}
                        />
                    ),
                    "ajaxError"
                );
                return;
            case 403:
                message = _("Access denied: your account does not have sufficient permissions.");
                actionButton = (
                    <Button variant="text" onClick={() => navigate("/")}>
                        {_("Open default cloud")}
                    </Button>
                );
                // Access Control list
                if (error.responseJSON) {
                    message = error.responseJSON.message;
                }
                break;
            case 429:
                message = _(
                    "Sorry, but there are too many requests coming from your network. "
                    + "Please try reloading the page later."
                );
                break;
            case 500:
                message = _(
                    "Sorry, there was an error while processing your request. We are working to " +
                    "fix this issue. Please try again later."
                );

                if (error.responseJSON && error.responseJSON.incidentId) {
                    message = _(
                        "Sorry, there was an error while processing your request. We are working to " +
                        "fix this issue. Please try again later. Incident ID [:incidentId].",
                        error.responseJSON
                    );
                }

                break;
            case 502:
                message = _("There was a temporary problem loading parts of the page. Please try reloading.");
                break;
            case 503:
                message = _("The service might not be available due to maintenance. Please try reloading the page.");
                break;
            default:
                // Let user report unknown errors so that we could handle
                // them properly in code that calls fetch
                return showErrorMessage(error);
        }
    }

    showSystemMessage({
        variant: "error",
        content: message,
        actions: actionButton,
        domain: "ajaxError"
    });
}

export function showErrorMessage(error: Error, info?: React.ErrorInfo) {
    const message = _(
        "Sorry, there was an error on this page. Please try "
        + "reloading the page, and if the error is recurring, "
        + "click the button to inspect and report it."
    );

    const handleReport = () => {
        const reportDialog = (
            <ErrorReportDialog error={error} errorInfo={info} />
        );

        showSystemDialog(reportDialog);
    };

    const actions = (
        <>
            <Button variant="text" onClick={reloadPage}>
                {_("Reload Page")}
            </Button>
            <Button variant="text" onClick={handleReport}>
                {_("Report Error...")}
            </Button>
        </>

    );

    showSystemMessage({
        variant: "error",
        content: message,
        actions,
        domain: "scriptError"
    });
}

export function handleJavaScriptError(error: Error, info?: React.ErrorInfo) {
    progressBarStop();

    if (window.jsError) {
        // Only handle the first error. Subsequent errors
        // might be caused by the first one.
        return;
    }

    // Selenium tests check this variable to see if there was an error
    window.jsError = error;

    if (error instanceof FetchJSONError) {
        handleFetchJSONError(error);
    } else {
        showErrorMessage(error, info);
    }
}

export function showAppUpdatedNotification(level: MessageVariant) {
    const message = _(
        "We have updated the Cloud Controller. Please reload the page "
        + "to use the latest application version."
    );

    const actions = (
        <Button variant="text" onClick={reloadPage}>
            {_("Reload Page")}
        </Button>
    );

    showSystemMessage({
        variant: level,
        content: message,
        actions,
        domain: "appVersion"
    });
}

let appUpdatedDialogShown = false;

function showAppUpdatedDialog() {
    if (appUpdatedDialogShown) {
        return;
    }

    appUpdatedDialogShown = true;

    const message = _(
        "Parts of the page cannot be displayed correctly because the Cloud "
        + "Controller has been updated. Please reload the page to continue using it."
    );

    const options = {
        title: _("Page Reload Required"),
        submitLabel: _("Reload Page")
    };

    askConfirmation(message, options).then(confirmed => {
        if (confirmed) {
            reloadPage();
        }
    });
}
