import _ from "app/lang";

export type CloudPlanType = "trial" | "intro" | "vpc";
export type CloudSuspendType = "CLOUD_SUSPENDED" | "ADDONS_SUSPENDED";

export const cloudPlanTypeTextual: { [key in CloudPlanType]: string } = {
    trial: _("Trial Plan"),
    intro: _("Core Cloud Plan"),
    vpc: _("Virtual Private Cloud Plan")
};

export interface CloudAttributes {
    id: string;
    name: string;
    plan_type: CloudPlanType;
    badge_of_courage: boolean;
    suspended_at: string | null;
    suspend_type: CloudSuspendType | null;
    large_site_support_allowed: boolean;
    enable_credit_card: boolean;
    enable_advertisement: boolean;
    authport_enabled: boolean;
    access_control_list: string;
}

export const enum CloudPlan {
    Trial = "trial",
    Core = "intro",
    VPC = "vpc"
}

export class Cloud {
    readonly id: string;
    readonly name: string;
    readonly planType: CloudPlanType;
    readonly badgeOfCourage: boolean;
    readonly largeSiteSupportAllowed: boolean;
    readonly enableCreditCard: boolean;
    readonly suspendedAt?: Date;
    readonly suspendType?: CloudSuspendType;
    readonly enableAdvertisement?: boolean;
    readonly accessControlList: string;

    constructor(attrs: CloudAttributes) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.planType = attrs.plan_type;
        this.badgeOfCourage = attrs.badge_of_courage;
        this.largeSiteSupportAllowed = attrs.large_site_support_allowed;
        this.enableCreditCard = attrs.enable_credit_card;
        this.enableAdvertisement = attrs.enable_advertisement;
        this.accessControlList = attrs.access_control_list;

        if (attrs.suspended_at !== null) {
            this.suspendedAt = new Date(attrs.suspended_at + "Z");
        }

        if (attrs.suspend_type !== null) {
            this.suspendType = attrs.suspend_type;
        }
    }

    isSuspended() {
        return this.suspendedAt !== undefined;
    }

    isCourageous() {
        return this.badgeOfCourage;
    }
}

export default Cloud;
