interface FrequencyToChannelDictrionary {
    [frequency: number]: number;
}

const frequencyToChannelMap: FrequencyToChannelDictrionary = {
    // 2.4 GHz
    2412: 1,
    2417: 2,
    2422: 3,
    2427: 4,
    2432: 5,
    2437: 6,
    2442: 7,
    2447: 8,
    2452: 9,
    2457: 10,
    2462: 11,
    2467: 12,
    2472: 13,
    2484: 14,

    // 5 GHz
    5180: 36,
    5200: 40,
    5220: 44,
    5240: 48,
    5260: 52,
    5280: 56,
    5300: 60,
    5320: 64,
    5500: 100,
    5520: 104,
    5540: 108,
    5560: 112,
    5580: 116,
    5600: 120,
    5620: 124,
    5640: 128,
    5660: 132,
    5680: 136,
    5700: 140,
    5745: 149,
    5765: 153,
    5785: 157,
    5805: 161,
    5825: 165,

    // 60 GHz
    58320: 1,
    60480: 2,
    62640: 3,
    64800: 4,
    66960: 5,
    69120: 6
};

export function getFrequencyChannel(frequency: number) {
    if (frequencyToChannelMap[frequency] === undefined) {
        return 0;
    }

    return frequencyToChannelMap[frequency];
}
