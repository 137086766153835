
import { CloudAttributes, InvitationAttributes } from "./types";

export interface Cloud {
    id: string;
    name: string;
    company: string|null;
}

export function createCloud(attrs: CloudAttributes): Cloud {
    return {
        id: attrs.id,
        name: attrs.name,
        company: attrs.company
    };
}

export function compareClouds(a: Cloud, b: Cloud) {
    const nameA = a.name.toLocaleLowerCase();
    const nameB = b.name.toLocaleLowerCase();

    return nameA.localeCompare(nameB);
}

export interface Invitation {
    cloud: Cloud;
    invitedBy: string;
    accepted: boolean;
    rejected: boolean;
    processing?: boolean;
}

export function createInvitation(attrs: InvitationAttributes): Invitation {
    return {
        cloud: createCloud(attrs),
        invitedBy: attrs.invited_by,
        accepted: false,
        rejected: false
    };
}
