import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import { breakpoints } from "app/theme";
import useWidth from "app/useWidth";

export const fullScreenBreakpoint: Breakpoint = "sm";

export function getFullScreen(width: number) {
    return width <= breakpoints[fullScreenBreakpoint];
}

export function useFullScreen() {
    return getFullScreen(useWidth());
}

export default useFullScreen;