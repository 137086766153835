import React from "react";
import StackTrace, { StackFrame } from "stacktrace-js";

import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import Button from "app/Button";
import { progressBarStop } from "app/ProgressBar";
import _ from "app/lang";
import { pageContext, PartialContext } from "app/page/ContextProvider";
import { fullScreenBreakpoint } from "app/useFullScreen";
import { reloadPage } from "app/utils";
import SystemDialog, { SystemDialogCommands } from "shared/dialogs/SystemDialog";

interface ServiceContext {
    service: string;
    version: string;
}

interface HttpRequestContext {
    userAgent: string;
    url: string;
}

interface ReportLocation {
    filePath: string;
    lineNumber: number;
    functionName: string;
}

interface ReportContext {
    user: string;
    httpRequest: HttpRequestContext;
    reportLocation?: ReportLocation;
}

interface ReportPayload {
    serviceContext: ServiceContext;
    context: ReportContext;
    message: string;
}

function getReportPayload(error: Error, context: PartialContext, errorInfo?: React.ErrorInfo): Promise<ReportPayload> {
    const serviceContext: ServiceContext = {
        service: "web4_js",
        version: context.appVersion
    };

    const reportContext: ReportContext = {
        user: context.user.email,
        httpRequest: {
            userAgent: window.navigator.userAgent,
            url: window.location.href
        }
    };

    if (errorInfo !== undefined) {
        reportContext.reportLocation = {
            filePath: "React",
            lineNumber: 0,
            functionName: errorInfo.componentStack
        };
    }

    const payload = {
        serviceContext,
        context: reportContext,
        message: error ? error.toString() : _("Error")
    };

    const firstFrameIndex = 0;

    // This will use sourcemaps and normalize the stack frames
    return StackTrace.fromError(error).then((stack: StackFrame[]) => {
        for (let s = firstFrameIndex; s < stack.length; s++) {
            payload.message += "\n";

            let fnName = stack[s].getFunctionName();

            if (typeof fnName !== "string" || fnName.length === 0) {
                fnName = "unknown";
            }
            // Reconstruct the stackframe to a JS stackframe as expected by Error Reporting parsers.
            // stack[s].source should not be used because not populated when created from source map.
            payload.message += ["    at ", fnName, " (", stack[s].getFileName(), ":",
                stack[s].getLineNumber(), ":", stack[s].getColumnNumber(), ")"].join("");
        }

        window.jsErrorTrace = payload.message;

        return payload;
    }).catch(() => {
        return payload;
    });
}

function sendErrorPayload(payload: ReportPayload): Promise<Response> {
    const baseAPIUrl = "https://clouderrorreporting.googleapis.com/v1beta1/projects/";
    const projectId = "ignitenet-cloud";
    const apiKey = "AIzaSyCJ7-tffaj_QHsKgAXcHqeNNH2RDgV4SVI";

    const url = baseAPIUrl + projectId + "/events:report?key=" + apiKey;
    const options = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json; charset=UTF-8"
        }
    };

    return fetch(url, options);
}

const progressSize = 40;

const styles = (theme: Theme) => createStyles({
    payloadContainer: {
        position: "relative"
    },

    reportPayload: {
        fontFamily: "'Lucida Console', 'Andale Mono', 'Monaco', monospace",
        fontSize: 13,
        whiteSpace: "pre",
        overflowY: "auto",
        "-ms-overflow-style": "-ms-autohiding-scrollbar",
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        border: "1px solid",
        borderColor: "#b9b9b9",
        backgroundColor: "#f3f3f3",
        color: "#3c3c3c",
        borderRadius: 3,
        height: theme.spacing(30),

        [theme.breakpoints.down(fullScreenBreakpoint)]: {
            height: theme.spacing(37)
        }
    },

    submitStatus: {
        minHeight: theme.spacing(2.5),
        marginTop: theme.spacing(1)
    },

    progress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -progressSize / 2,
        marginLeft: -progressSize / 2,
    },

    dialogContentRoot: {
        overflowY: "hidden"
    }
});

interface Props extends WithStyles<typeof styles> {
    canClose?: boolean;
    error: Error;
    errorInfo?: React.ErrorInfo;
}

interface State {
    reportPayload?: ReportPayload;
    disableSubmit: boolean;
    showProgress: boolean;
    statusText?: string;
    submitFailed?: boolean;

}

class ErrorReportDialog extends React.Component<Props, State> {
    static contextType = pageContext;
    context!: PartialContext;

    constructor(props: Props, _context?: PartialContext) {
        super(props, _context);

        this.state = {
            showProgress: true,
            disableSubmit: true
        };

        this.handleReport = this.handleReport.bind(this);
    }

    componentDidMount() {
        const { error, errorInfo } = this.props;

        progressBarStop();

        getReportPayload(error, this.context, errorInfo).then(
            reportPayload => this.setState({
                disableSubmit: false,
                showProgress: false,
                reportPayload
            })
        );
    }

    render() {
        const {
            reportPayload,
            showProgress,
            disableSubmit,
            statusText,
            submitFailed
        } = this.state;

        const { canClose, classes } = this.props;

        const content = (reportPayload !== undefined)
            ? JSON.stringify(reportPayload, null, 2)
            : null;

        const progress = (showProgress)
            ? <CircularProgress color="primary" className={classes.progress} />
            : null;

        const reloadButton = (canClose === false) ? (
            <Button variant="outlined" onClick={reloadPage}>
                {_("Reload Page")}
            </Button>
        ) : null;

        let dialogCommands: SystemDialogCommands;

        const cancelButton = (canClose !== false) ? (
            <Button variant="outlined" onClick={() => dialogCommands.close()}>
                {_("Cancel")}
            </Button>
        ) : null;

        return (
            <SystemDialog title={_("Page Script Error")}
                commandsRef={commands => dialogCommands = commands}
                canClose={canClose}
            >
                <DialogContent className={classes.dialogContentRoot}>
                    <DialogContentText>
                        {_(
                            "A script error has occured on this page that "
                            + "could prevent it from working properly. "
                            + "Would you like to submit an error report?"
                        )}

                        <br />
                        <br />

                        {_("This is what will be submitted:")}
                    </DialogContentText>

                    <div className={classes.payloadContainer}>
                        <div className={classes.reportPayload}>
                            {content}
                        </div>
                        {progress}
                    </div>

                    <div className={classes.submitStatus}>
                        <DialogContentText color={submitFailed ? "error" : "textSecondary"}>
                            {statusText}
                        </DialogContentText>
                    </div>
                </DialogContent>

                <DialogActions>
                    {reloadButton}
                    {cancelButton}
                    <Button variant="contained"
                        disabled={reportPayload === undefined || disableSubmit}
                        onClick={this.handleReport}
                    >
                        {_("Report Error")}
                    </Button>
                </DialogActions>
            </SystemDialog>
        );
    }

    private async handleReport() {
        const { reportPayload } = this.state;

        if (reportPayload === undefined) {
            return;
        }

        this.setState({
            disableSubmit: true,
            showProgress: true,
            submitFailed: false,
            statusText: ""
        });

        const response = await sendErrorPayload(reportPayload);

        if (response.status === 200) {
            this.setState({
                disableSubmit: true,
                showProgress: false,
                submitFailed: false,
                statusText: _("Error successfully reported. Thank you.")
            });
        } else {
            this.setState({
                disableSubmit: false,
                showProgress: false,
                submitFailed: true,
                statusText: _("Oops. Something went wrong.")
            });
        }
    }
}

export default withStyles(styles)(ErrorReportDialog);
