import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";

import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";

import request from "app/http";
import _ from "app/lang";
import { getUserRoleTranslated } from "app/models";
import User from "app/models/User";
import { pageContext, PageLevel } from "app/page/ContextProvider";
import { appBarHeight, drawerMenuHeaderHeight, drawerWidth } from "app/theme";
import GroupingList from "shared/GroupingList";

import CloudMenu from "./CloudMenu";
import DeviceMenu from "./DeviceMenu";
import DrawerSiteSelector, { siteSelectorHeight, groupSelectorHeight } from "./DrawerSiteSelector";
import MenuHeader from "./MenuHeader";
import SNVRMenu from "./SNVRMenu";
import SimpleMenuItem from "./SimpleMenuItem";
import SiteMenu from "./SiteMenu";

const FETCH_LIMIT = 100;

const styles = (theme: Theme) => {
    const transition = theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    });

    return createStyles({
        root: {
            position: "fixed",
            top: appBarHeight,
            bottom: 0,
            width: drawerWidth,
            overflow: "hidden",
            background: theme.colors.drawer.background,
            boxShadow: "1px 1px 3px 0 rgba(213, 213, 213, 0.5), 0.5px 0.5px 1px 0 rgba(236, 236, 236, 0.5)",
            color: theme.colors.drawer.main,
            [theme.breakpoints.down("sm")]: {
                zIndex: theme.zIndex.drawer,
                top: 0,
                boxShadow: "none",
            }
        },

        open: {
            [theme.breakpoints.down("sm")]: {
                left: 0,
                transition,
            },
        },

        closing: {
            [theme.breakpoints.down("sm")]: {
                left: -drawerWidth,
                transition
            },
        },

        closed: {
            [theme.breakpoints.down("sm")]: {
                left: -drawerWidth
            },
        },

        menuContainer: {
            position: "absolute",
            top: 0,
            width: 3 * drawerWidth,
            bottom: 46,
            overflowY: "auto",
            overflowX: "hidden",
            "-ms-overflow-style": "-ms-autohiding-scrollbar",
            transition,
            borderBottom: theme.colors.drawer.borderBottom
        },

        menuOuter: {
            display: "inline-block",
            position: "relative",
            width: drawerWidth,
            verticalAlign: "top",
            height: "100%"
        },

        menuHeader: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            textTransform: "uppercase",
            color: "#fff",
            height: drawerMenuHeaderHeight,
            width: drawerWidth,
            position: "absolute",
            top: 0
        },

        menuInner: {
            position: "absolute",
            top: drawerMenuHeaderHeight,
            bottom: 0,
            width: drawerWidth,
            overflowX: "hidden",
            overflowY: "auto",
            outline: "none",
            "-ms-overflow-style": "-ms-autohiding-scrollbar",
            overscrollBehaviorY: "contain",
            scrollSnapType: "y mandatory",
        },

        menuInnerWithSiteSelector: {
            "&$menuInner": {
                top: drawerMenuHeaderHeight + siteSelectorHeight
            }
        },

        menuInnerWithGroupSelector: {
            "&$menuInner": {
                top: drawerMenuHeaderHeight + siteSelectorHeight + groupSelectorHeight
            }
        },

        headerButton: {
            height: 22,
            fontSize: 18,
            color: "inherit",
            verticalAlign: "middle"
        },

        cloudMenu: {
            "& $menuHeader": {
                backgroundColor: theme.colors.level.cloud.main,
            }
        },

        groupMenu: {
            "& $menuHeader": {
                backgroundColor: theme.colors.level.group.main,
            }
        },

        siteMenu: {
            "& $menuHeader": {
                backgroundColor: theme.colors.level.site.main
            }
        },

        smartNVRMenu: {
            "& $menuHeader": {
                backgroundColor: theme.colors.level.smartnvr.main
            }
        },

        deviceMenu: {
            "& $menuHeader": {
                backgroundColor: theme.colors.level.device.main
            }
        },

        footer: {
            position: "fixed",
            bottom: 0,
            width: "inherit"
        }
    });
};

export interface SiteGroupingInfo {
    id: string;
    name: string;
}

export interface SiteGroupingResponse {
    userCloudFilter: SiteGroupingInfo;
    siteGroupings: SiteGroupingInfo[];
}

export type DrawerState = "open" | "closing" | "closed";

interface Props extends WithStyles<typeof styles> {
    state: DrawerState;
    onAnimationEnd(): void;
}

function Drawer(props: Props) {
    const context = useContext(pageContext);
    const { classes } = props;
    const [selected, setSelected] = useState<SiteGroupingInfo| null>(null);
    const [siteGroupings, setSiteGroupings] = useState<SiteGroupingInfo[] | undefined>(undefined);
    const [activeMenu, setActiveMenu] = useState<PageLevel>(context.pageLevel);
    const [activeSiteGroup, setActiveSiteGroup] = useState<boolean>(false);
    const [backendFilter, setBackendFilter] = useState<boolean>(false);
    const [isSiteGroupingLoading, setIsSiteGroupingLoading] = useState<boolean>(true);
    const menuContainer = useRef<HTMLDivElement>(null);

    useEffect(() => setActiveMenu(context.pageLevel), [context.pageLevel]);

    function onTransitionEnd(event: React.TransitionEvent) {
        if (event.target === menuContainer.current) {
            updateMenuFocus();
        }
    }

    const fetchSiteGrouping = (_filter: string | undefined) => {
        request({
            url: "/apiv2/cloud/:cloudId/site-filters-select",
            data: {
                limit: FETCH_LIMIT,
                filter: _filter,
                allFields: false
            }
        }).then((data: SiteGroupingResponse) => {
            const cloudFilter = data.userCloudFilter;
            const filteredsiteGroupings = data.siteGroupings;

            if (filteredsiteGroupings.length === FETCH_LIMIT) {
                setBackendFilter(true);
            }

            setSelected(cloudFilter);
            setSiteGroupings(filteredsiteGroupings);
            setActiveSiteGroup(cloudFilter.id !== undefined);
            setIsSiteGroupingLoading(false);
        });
    };

    function renderSiteSelector(isCloud: boolean) {
        let tmpSelected = null;
        const user = new User(context);

        if (context.site) {
            tmpSelected = {
                id: context.site.id,
                name: context.site.name,
            };
        }

        return (user.hasPermission("manage_sites")) ? (
            <DrawerSiteSelector selected={tmpSelected} isCloud={isCloud} />
        ) : ( <DrawerSiteSelector selected={tmpSelected} isCloud={false} /> );
    }

    function renderCloudMenu() {
        const { pageLevel } = context;
        const user = new User(context);
        const permission = user.hasPermission("manage_sites");
        const menuClass = permission ? classes.menuInnerWithGroupSelector : classes.menuInnerWithSiteSelector;
        let forwardTipText;

        if (pageLevel !== "cloud") {
            if (pageLevel === "smartnvr") {
                forwardTipText = _("Smart NVR");
            } else {
                forwardTipText = _("Site Menu");
            }
        } else {
           forwardTipText = undefined;
        }

        const renderGroupSelector = () => {
            return (permission) ? (
                <GroupingList
                    backendFilter={backendFilter}
                    siteGroupings={siteGroupings}
                    setSiteGroupings={setSiteGroupings}
                    selected={selected}
                    setSelected={setSelected}
                    fetchSiteGrouping={fetchSiteGrouping}
                />
            ) : null;
        };

        let cloudOrGroup = classes.cloudMenu;
        let cloudOrGroupMenu = "Cloud Menu";

        if (activeSiteGroup === true) {
            cloudOrGroup = classes.groupMenu;
            cloudOrGroupMenu = "Group Menu";
        }

        if (isSiteGroupingLoading) {
            if (permission) {
                fetchSiteGrouping(undefined);
            } else {
                setIsSiteGroupingLoading(false);
            }
            return "";
        } else {
            return (
                <div className={classNames(classes.menuOuter, cloudOrGroup)} data-level="cloud">
                    <MenuHeader className={classes.menuHeader}
                        forwardTip={forwardTipText}
                        onForward={() => setActiveMenu("site")}
                    >
                        {_(cloudOrGroupMenu)}
                    </MenuHeader>
                    {renderGroupSelector()}
                    {renderSiteSelector(true)}
                    <div className={classNames(classes.menuInner, menuClass)} tabIndex={-1}>
                        <CloudMenu />
                    </div>
                </div>
            );
        }
    }

    function renderSiteMenu() {
        if (!context.site) {
            return null;
        }

        const { pageLevel } = context;

        return (
            <div className={classNames(classes.menuOuter, classes.siteMenu)} data-level="site">
                <MenuHeader className={classes.menuHeader}
                    backTip={_("Cloud Menu")}
                    onBack={() => setActiveMenu("cloud")}
                    forwardTip={pageLevel === "device" ? _("Device Menu") : undefined}
                    onForward={() => setActiveMenu("device")}
                >
                    {_("Site Menu")}
                </MenuHeader>
                {renderSiteSelector(false)}
                <div className={classNames(classes.menuInner, classes.menuInnerWithSiteSelector)} tabIndex={-1}>
                    <SiteMenu />
                </div>
            </div>
        );
    }

    function renderSNVRMenu() {
        if (!context.smartNVR) {
            return null;
        }

        return (
            <div className={classNames(classes.menuOuter, classes.smartNVRMenu)} data-level="smartnvr">
                <MenuHeader className={classes.menuHeader}
                    backTip={_("Cloud Menu")}
                    onBack={() => setActiveMenu("cloud")}
                >
                    {_("Smart NVR")}
                </MenuHeader>
                <div className={classes.menuInner} tabIndex={-1}>
                    <SNVRMenu />
                </div>
            </div>
        );
    }

    function renderDeviceMenu() {
        if (!context.device || !context.site) {
            return null;
        }

        return (
            <div className={classNames(classes.menuOuter, classes.deviceMenu)} data-level="device">
                <MenuHeader className={classes.menuHeader}
                    backTip={_("Site Menu")}
                    onBack={() => setActiveMenu("site")}
                >
                    {_("Device Menu")}
                </MenuHeader>
                {renderSiteSelector(false)}
                <div className={classNames(classes.menuInner, classes.menuInnerWithSiteSelector)} tabIndex={-1}>
                    <DeviceMenu />
                </div>
            </div>
        );
    }

    function updateMenuFocus() {
        if (menuContainer.current === null) {
            return;
        }

        for (const child of menuContainer.current.children) {
            if (child instanceof HTMLElement) {
                if (child.dataset.level === activeMenu) {
                    const focusHolder = child.querySelector<HTMLElement>(".focus-container");

                    // This neat trick will make the first element in the
                    // newly visible menu header to focus as soon as user
                    // presses the Tab key

                    if (focusHolder) {
                        focusHolder.style.display = "block";
                        focusHolder.focus();
                        focusHolder.style.display = "none";
                    }
                }
            }
        }
    }

    const offsets: Record<PageLevel, number> = {
        "cloud": 0,
        "site": -1 * drawerWidth,
        "smartnvr": -1 * drawerWidth,
        "device": -2 * drawerWidth
    };

    const rootClasses = [
        classes.root,
        classes[props.state]
    ];

    let menus = null;
    let footer = null;

    if (context.cloud && context.userCloud) {
        const userRole = getUserRoleTranslated(context.userCloud.role_id);

        menus = (
            <>
                {renderCloudMenu()}
                {renderSiteMenu()}
                {renderSNVRMenu()}
                {renderDeviceMenu()}
            </>
        );

        footer = (
            <SimpleMenuItem
                text={userRole}
                icon="account_circle"
                href={`/v2/cloud/${context.cloud.id}/clouds`}
            />
        );
    }

    // if (isSiteGroupingLoading === true && context.cloud && context.userCloud) {
    return (
        <div className={rootClasses.join(" ")} onAnimationEnd={props.onAnimationEnd}>
            <div className={classNames(classes.menuContainer, "track-menu")}
                ref={menuContainer}
                style={{ left: offsets[activeMenu] }}
                onTransitionEnd={onTransitionEnd}
            >
                {menus}
            </div>
            <div className={classes.footer}>
                {footer}
            </div>
        </div>
    );
    /*
    } else {
        return "";
    }
    */
}

export default withStyles(styles)(Drawer);