import React from "react";

import Badge from "@material-ui/core/Badge";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import Icon from "app/Icon";
import ListItemBadge from "app/ListItemBadge";
import ListItemCaret from "app/ListItemCaret";
import _ from "app/lang";

import InvitationCard from "./InvitationCard";
import { Invitation } from "./models";

interface Props {
    invitations: Invitation[];
    unreadCount: number;
    onAccept: (invitation: Invitation) => Promise<void>;
    onReject: (invitation: Invitation) => Promise<void>;
    opened: boolean;
    onMenuClick: (opened: boolean) => void;
    trackingId: string;
}

function createTrackingValue(invitation: Invitation): string {
    return `accepted=${invitation.accepted},rejected=${invitation.rejected},processing=${invitation.processing}`;
}

function InvitationList(props: Props) {
    const { invitations, unreadCount, onAccept, onReject, opened, onMenuClick } = props;

    if (invitations.length === 0) {
         return null;
    }

    return (
        <>
            <MenuItem button
                onClick={() => onMenuClick(!opened)}
                disabled={unreadCount < 1}
                data-tracking-id={`${props.trackingId}-invitations`}
            >
                <ListItemIcon>
                    <Badge color="secondary" variant="dot" invisible={unreadCount < 1}>
                        <Icon id="mail" size={16} />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={_("Invitations")} />
                {(unreadCount > 0) ? (
                    <>
                        <ListItemBadge value={unreadCount} />
                        <ListItemCaret open={opened} />
                    </>
                 ) : null}
            </MenuItem>
            <Collapse in={opened && unreadCount > 0} appear>
                <List disablePadding>
                    {invitations.map(item => (
                        <Collapse key={item.cloud.id} in={!item.accepted && !item.rejected}>
                            <Divider />
                            <ListItem disableGutters>
                                <InvitationCard invitation={item}
                                    onAccept={onAccept}
                                    onReject={onReject}
                                    trackingId={`${props.trackingId}-invitation(${createTrackingValue(item)})`}
                                />
                            </ListItem>
                        </Collapse>
                    ))}
                </List>
            </Collapse>
            <Divider />
        </>
    );
}

export default InvitationList;