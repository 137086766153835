import classNames from "classnames";
import React, { MouseEventHandler } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) => createStyles({
    root: {
        flexShrink: 1,
        flexGrow: 1,
        overflowY: "auto",
        "-ms-overflow-style": "-ms-autohiding-scrollbar"
    }
});

const useStyles = makeStyles(styles);

interface Props {
    children: React.ReactNode;
    className?: string;
    onClick?: MouseEventHandler<HTMLElement>;
}

function ActionBody(props: Props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)} onClick={props.onClick}>
            {children}
        </div>
    );
}

export default ActionBody;
