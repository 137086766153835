import classNames from "classnames";
import React from "react";

import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) => createStyles({
    root: {
        boxSizing: "border-box",
        borderRadius: 8,
        minWidth: 16,
        height: 16,
        lineHeight: "16px",
        fontSize: theme.typography.pxToRem(11),
        fontWeight: theme.typography.fontWeightRegular,
        padding: "0 3px",
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.background.default,
        textAlign: "center"
    }
});

export interface InlineBadgeProps {
    value: number;
    max?: number;
    showZero?: boolean;
    className?: string;
}

type Props = InlineBadgeProps & WithStyles<typeof styles>;

function InlineBadge(props: Props) {
    const { value, max, showZero = false, className, classes } = props;

    if (value === 0 && !showZero) {
        return null;
    }

    let content: string;

    if (max !== undefined && value > max) {
        content = max + "+";
    } else {
        content = value.toString();
    }

    return (
        <span className={classNames(className, classes.root)}>{content}</span>
    );
}

export default withStyles(styles)(InlineBadge);