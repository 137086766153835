import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Trans from "app/Trans";
import _ from "app/lang";

import { Invitation } from "./models";

const styles = (theme: Theme) => createStyles({
    root: {
        boxShadow: "none"
    },

    content: {
        paddingTop: 0,
        paddingBottom: theme.spacing(1),

        fontSize: 12,
        "& b": {
            fontWeight: 600
        }
    },

    actions: {
        color: theme.palette.text.secondary,
        paddingTop: 0,
        paddingBottom: 0
    }
});

type InvitationCallback = (invitation: Invitation) => void;

interface Props extends WithStyles<typeof styles> {
    invitation: Invitation;
    onAccept: InvitationCallback;
    onReject: InvitationCallback;
    trackingId: string;
}

function InvitationCard(props: Props) {
    const { invitation, onAccept, onReject, classes } = props;
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const onButtonClick =  (callback: InvitationCallback) => {
        return () => {
            setButtonsDisabled(true);
            callback(invitation);
        };
    };

    const { cloud: { name: cloud }, invitedBy } = invitation;

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <Trans
                    text="<b>:invitedBy</b> invites you to join the cloud <b>:cloud</b>."
                    vars={{ cloud, invitedBy }}
                />
            </CardContent>
            <CardActions className={classes.actions} disableSpacing>
                <Button variant="text"
                    color="primary"
                    size="small"
                    disabled={buttonsDisabled}
                    onClick={onButtonClick(onAccept)}
                    data-tracking-id={`${props.trackingId}-accept`}
                >
                    {_("Accept")}
                </Button>
                <Button variant="text"
                    color="inherit"
                    size="small"
                    disabled={buttonsDisabled}
                    onClick={onButtonClick(onReject)}
                    data-tracking-id={`${props.trackingId}-reject`}
                >
                    {_("Decline")}
                </Button>
            </CardActions>
        </Card>
    );
}

export default withStyles(styles)(InvitationCard);