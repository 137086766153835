import React from "react";

// This is the only place where we import MUI Link
// eslint-disable-next-line no-restricted-imports
import MuiLink, { LinkProps } from "@material-ui/core/Link";

import Icon from "app/Icon";

interface Props extends LinkProps {
    trackingId?: string;
}

export default function ExternalLink(props: Props) {
    const { trackingId, ...domProperties } = props;
    const openInNewIcon = props.target === "_blank" ? (
        <>
            &nbsp;<Icon id="open_in_new" size={14} />
        </>
    ) : null;

    return (
        <MuiLink {...domProperties} rel="noopener" data-tracking-id={props.trackingId}>
            {props.children}
            {openInNewIcon}
        </MuiLink>
    );
}
