import moment from "moment-timezone";

import request from "app/http";

export interface UserAlert {
    alertCount: number;
    displayAlert: boolean;
}

export type UserAlertType = "DEVICE_ADDED"
    | "INTRO_DASHBOARD_WELCOME"
    | "INTRO_DASHBOARD_ADD_BUTTON"
    | "INTRO_DASHBOARD_TITLE_MENU"
    | "INTRO_DASHBOARD_WIDGET"
    | "SITE_CONFIG_NOTICE_ALERT"
    | "FREE_TRIAL_50_DEVICES"
    | "ADVERTISEMENT";

type AlertStore = { [key in UserAlertType]: UserAlert };

let alertLoadPromise: Promise<AlertStore>;
let alertStore: AlertStore;
let loadedAt: moment.Moment | undefined;

function getAlertUrl(alertType: UserAlertType): string {
    return "/apiv2/user/alert/" + alertType.toLowerCase().split("_").join("-");
}

async function loadFromApi() {
    if (
        !alertLoadPromise
        || !loadedAt
        || loadedAt < moment().subtract(1, "hour")
    ) {
        loadedAt = moment();
        alertLoadPromise = request("/apiv2/user/alert/all");
    }

    alertStore = await alertLoadPromise;
}

export async function getUserAlert(alertType: UserAlertType): Promise<UserAlert | undefined> {
    await loadFromApi();

    return alertStore[alertType];
}

export async function setUserAlert(alertType: UserAlertType, params: UserAlert): Promise<void> {
    if (params.alertCount === undefined) {
        throw new Error("missing alertCount prop in params");
    }

    if (params.displayAlert === undefined) {
        throw new Error("missing displayAlert prop in params");
    }

    alertStore[alertType] = params;

    await request({
        method: "PUT",
        url: getAlertUrl(alertType),
        data: params
    });
}

export async function shouldShowAlert(alertType: UserAlertType): Promise<boolean> {
    const alert = await getUserAlert(alertType);

    return !alert || alert.displayAlert;
}

export async function disableAlert(alertType: UserAlertType): Promise<void> {
    let alert = await getUserAlert(alertType);

    if (alert) {
        alert.displayAlert = false;
        alert.alertCount++;
    } else {
        alert = {
            alertCount: 1,
            displayAlert: false
        };
    }

    await setUserAlert(alertType, alert);
}