import _ from "app/lang";
import Device from "app/models/Device";

import { DrawerMenuItem } from "../DrawerMenu";

export default function (baseUrl: string, device: Device): DrawerMenuItem[] {
    const brandId = ["153", "154", "161" ].includes(device.brandId);

    if (brandId) {
        return [
            {
                icon: "dashboard",
                text: _("Dashboard"),
                href: `${baseUrl}/dashboard`
            },
            {
                icon: "multiline_chart",
                text: _("Statistics"),
                subitems: [
                    {
                        icon: "wifi",
                        text: _("Wireless"),
                        href: `${baseUrl}/wireless`
                    },
                    {
                        icon: "device_hub",
                        text: _("Networks"),
                        href: `${baseUrl}/networks`
                    },
                    {
                        icon: "pie_chart",
                        text: _("System"),
                        href: `${baseUrl}/system`
                    }
                ]
            },
            {
                icon: "list_alt",
                text: _("Activity"),
                href: `${baseUrl}/activity`
            }
        ];
    } else {
        return [
            {
                icon: "dashboard",
                text: _("Dashboard"),
                href: `${baseUrl}/dashboard`
            },
            {
                icon: "multiline_chart",
                text: _("Statistics"),
                subitems: [
                    {
                        icon: "wifi",
                        text: _("Wireless"),
                        href: `${baseUrl}/wireless`
                    },
                    {
                        icon: "device_hub",
                        text: _("Networks"),
                        href: `${baseUrl}/networks`
                    },
                    {
                        icon: "pie_chart",
                        text: _("System"),
                        href: `${baseUrl}/system`
                    }
                ]
            },
            {
                icon: "signal_wifi_3_bar",
                text: _("Clients"),
                href: `${baseUrl}/clients`
            },
            {
                icon: "list_alt",
                text: _("Activity"),
                href: `${baseUrl}/activity`
            }
        ];
    }
}
