import React, { useEffect, useRef, useState } from "react";

import { setupTimer } from "app/timers";

import { ActionProps } from "../Action";
import ActionButton from "../ActionButton";
import ActionPopover from "../ActionPopover";

import MessageList from "./MessageList";
import Subscription from "./Subscription";
import { fetchBlog, Post, updateBlog } from "./blog";

const REFRESH_INTERVAL = 1200000;

interface Props extends ActionProps {
    unreadCount: number;
    setUnreadCount: (count: number) => void;
}

function getUnreadCount(posts: Post[]) {
    let unreadCount = 0;

    for (const post of posts) {
        if (!post.viewed) {
            unreadCount++;
        }
    }

    return unreadCount;
}

export default function InboxAction(props: Props) {
    const {
        name,
        open,
        tooltip,
        iconId,
        onClick,
        onClose,
        fullScreen,
        showMenuButton,
        unreadCount,
        setUnreadCount
    } = props;

    const [posts, setPosts] = useState<Post[]>([]);

    const retrieveBlog = () => {
        fetchBlog().then((freshPosts: Post[]) => {
            setUnreadCount(getUnreadCount(freshPosts));
            setPosts(freshPosts);
        });
    };

    const openRef = useRef(open);

    useEffect(() => {
 openRef.current = open;
});

    // Do not refresh while open, may have unwanted effects
    const refreshBlog = () => {
        if (!openRef.current) {
            retrieveBlog();
        }
    };

    useEffect(() => setupTimer("inbox-refresh", refreshBlog, REFRESH_INTERVAL), []);

    const buttonElement = useRef<HTMLButtonElement>(null);

    const handleClick = () => onClick(name);
    const handleClose = () => onClose(name);

    const handleViewed = (post: Post) => {
        setUnreadCount(unreadCount - 1);
        setPosts([...posts]);

        updateBlog(posts);
    };

    const markAllRead = () => {
        setUnreadCount(0);

        for (const post of posts) {
            post.viewed = true;
        }

        setPosts([...posts]);

        updateBlog(posts);

        onClose(name);
    };

    const button = showMenuButton ? (
        <ActionButton
            onClick={handleClick}
            tooltip={tooltip}
            iconId={iconId}
            active={open}
            badgeContent={unreadCount}
            buttonRef={buttonElement}
            trackingId={props.trackingId}
        />
    ) : null;

    return (
        <>
            {button}
            <ActionPopover
                fixedWidth={400}
                open={open}
                title={tooltip}
                onClose={handleClose}
                anchorEl={buttonElement.current}
                fullScreen={fullScreen}
            >
                <MessageList
                    posts={posts}
                    onViewed={handleViewed}
                    trackingId={`${props.trackingId}-messages`}
                />
                <Subscription
                    unreadCount={unreadCount}
                    onMarkAllRead={markAllRead}
                    trackingId={`${props.trackingId}-subscription`}
                />
            </ActionPopover>
        </>
    );
}
