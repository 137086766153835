import Renderer, { Mobiledoc as Content } from "mobiledoc-vdom-renderer";
import React from "react";

import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";

import ExternalLink from "app/ExternalLink";
import { staticContext } from "app/page/ContextProvider";

export type MobiledocContent = Content;

const render = Renderer({
    createElement: React.createElement,
    getAtomComponent,
    getCardComponent,
    getMarkupComponent
});

interface Props {
    content?: Content;
}

interface State {
    error: boolean;
}

// This has to be a class so that it can implement componentDidCatch()
export class Mobiledoc extends React.Component<Props, State> {
    state = { error: false };

    render() {
        if (this.state.error || !this.props.content) {
            return this.props.children;
        }

        const rendered = render(this.props.content);

        // This is needed in order to avoid the "children need the 'key' attr"
        // React development mode warning.

        return React.createElement(
            React.Fragment,
            null,
            ...rendered
        );
    }

    componentDidCatch() {
        this.setState({ error: true });
    }
}

interface ComponentDictionary {
    [key: string]: React.FunctionComponent<any>;
}

const markupComponents: ComponentDictionary = {
    "a": function Anchor(props: {}) {
        const { href, children } = props as HTMLAnchorElement;

        return (
            <ExternalLink href={href} target="_blank">
                {children}
            </ExternalLink>
        );
    }
};

const atomComponents: ComponentDictionary = {
    "soft-return": function SoftReturn() {
        return <br />;
    }
};

const cardComponents: ComponentDictionary = {
    "image": function Image(props: { payload: {} }) {
        const { src } = props.payload as HTMLImageElement;
        // Should we somehow validate src here? Make sure it's https:// or smth?
        return <CardMedia image={src} />;
    },

    "hr": function HorizontalRuler() {
        return <Divider />;
    }
};

function Unsupported() {
    return null;
}

function unsupportedComponent(name: string, type: string) {
    if (staticContext.environment === "production" || staticContext.environment === "integration") {
        console.warn(`Mobiledoc ${name} of type ${type} is not supported.`);
    }

    return Unsupported;
}

function getAtomComponent(type: string) {
    return atomComponents[type] || unsupportedComponent("Atoms", type);
}

function getCardComponent(type: string) {
    return cardComponents[type] || unsupportedComponent("Cards", type);
}

function getMarkupComponent(type: string) {
    return markupComponents[type] || type;
}

export default Mobiledoc;
