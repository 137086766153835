import { isPageInvisible, isPageVisible, onPageVisibilityChange } from "app/page/visibility";

const refreshTimers = Object.create(null);

type TimerCallback = (count: number) => void;

/**
 * Stars executing timer callback function
 */
function startTimer(timerId: string) {
    if (refreshTimers[timerId] === undefined) {
        console.warn("Timer not found: " + timerId);
        return;
    }

    const timer = refreshTimers[timerId];

    if (timer.interval_id) {
        // Timer is already active
        console.warn("Timer is already active: " + timerId);
        return;
    }

    console.log("starting timer '" + timerId + "' with interval " + timer.interval);

    timer.interval_id = window.setInterval(function () {
        if (isPageInvisible()) {
            console.log("inactivity detected, stopping timer: " + timerId);
            window.clearInterval(timer.interval_id);
            timer.interval_id = null;
            return;
        }

        timer.callback(++timer.call_count);
    }, timer.interval);

    timer.callback(0);
}

onPageVisibilityChange(() => {
    if (isPageVisible()) {
        for (const timerId in refreshTimers) {
            if (!refreshTimers[timerId].interval_id) {
                // The subtle trick here is to run callback as soon as possible,
                // if the timer has been disabled before. User then gets an
                // immediate refresh when he opens the page again.
                startTimer(timerId);
            }
        }
    }
});

/**
 * Sets up a function for periodic execution. This takes into account
 * browser window visibility and will not execute callback if browser
 * window is not visible
 */
export function registerTimer(timerId: string, interval: number, callback: TimerCallback) {
    if (refreshTimers[timerId] !== undefined) {
        console.warn("Timer already exists: " + timerId);
        return;
    } else {
        // We have a new timer
        refreshTimers[timerId] = {
            interval_id: null,
            call_count: 0,
            callback: callback,
            interval: interval
        };
    }

    startTimer(timerId);
}

export function unregisterTimer(timerId: string) {
    clearInterval(refreshTimers[timerId].interval_id);
    delete refreshTimers[timerId];
}

export function unregisterTimers() {
    for (const timerId in refreshTimers) {
        unregisterTimer(timerId);
    }
}

export function setupTimer(timerId: string, callback: TimerCallback, interval: number) {
    registerTimer(timerId, interval, callback);
    return () => unregisterTimer(timerId);
}