import React from "react";

import List from "@material-ui/core/List";

import ActionBody from "../ActionBody";

import ResultListItem from "./ResultListItem";
import { SearchResult } from "./models";

interface Props {
    query: string;
    results: SearchResult[] | null;
    className?: string;
    onClick: () => void;
    trackingId: string;
}

function ResultList(props: Props) {
    const { query, results } = props;

    if (results === null || results.length === 0) {
        return null;
    }

    return (
        <ActionBody>
            <List disablePadding className={props.className}>
                {results.map((result, index) => (
                    <ResultListItem
                        key={index}
                        query={query}
                        result={result}
                        divider={index > 0}
                        onClick={props.onClick}
                        trackingId={`${props.trackingId}-${result.domain}`}
                    />
                ))}
            </List>
        </ActionBody>
    );
}

export default ResultList;