import classNames from "classnames";
import React from "react";

import Badge, { BadgeProps } from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";
import { insertRouteParams } from "app/page/Link";
import { appBarHeight } from "app/theme";

const styles = (theme: Theme) => createStyles({
    root: {
        color: "#eee",
        borderRadius: 0,
        height: appBarHeight,
        minWidth: appBarHeight - 4,
        width: "auto",
        padding: 0,
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.0784314)"
        },
        [theme.breakpoints.down(1050)]: {
            minWidth: appBarHeight - 12
        },
        [theme.breakpoints.down("md")]: {
            minWidth: appBarHeight - 10
        },
        [theme.breakpoints.down("sm")]: {
            flex: "1"
        },
    },

    dropdown: {
        fontSize: 24,
        padding: "3px 12px 0 16px",
        borderRight: "1px solid #7c7c7c",
        "&:last-child": {
            borderRight: "none"
        }
    },

    dropdownText: {
        fontSize: 14,
        color: "#fff",
        display: "inline-block",
        maxWidth: 140,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        alignSelf: "center",
        [theme.breakpoints.down("md")]: {
            display: "none",
        }
    },

    paddedLeft: {
        paddingLeft: theme.spacing(1)
    },

    caret: {
        color: "#eee",
        marginLeft: 4,
        marginRight: -4,
    }
});

interface Props extends WithStyles<typeof styles> {
    tooltip: string;
    iconId: string;
    active?: boolean;
    dropdown?: boolean;
    dropdownIcon?: boolean;
    dropdownText?: string;
    badgeContent?: number;
    badgeColor?: "default" | "primary" | "secondary" | "error";
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    href?: string;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    id?: string;
    dotBadge?: boolean;
    trackingId: string;
}

function NavbarButton(props: Props) {
    const {
        tooltip,
        iconId,
        active,
        dropdown,
        dropdownIcon = true,
        dropdownText,
        onClick,
        badgeContent = 0,
        badgeColor = "default",
        classes,
        buttonRef,
        dotBadge
    } = props;

    let href: string | undefined;

    if (props.href) {
        href = insertRouteParams(props.href);
    }

    const buttonClasses = classNames({
        [classes.root]: true,
        [classes.dropdown]: dropdown
    });

    let badgeAnchor: React.ReactNode = null;
    let caretNode: React.ReactNode = null;
    let labelNode: React.ReactNode = null;

    if (dropdown) {
        const caretIcon = active ? "arrow_drop_up" : "arrow_drop_down";

        caretNode = (
            <Icon id={caretIcon} className={classes.caret} />
        );

        const iconNode = dropdownIcon ? (
            <Icon id={iconId} />
        ) : null;

        const textNode = dropdownText ? (
            <span className={classNames(classes.dropdownText, dropdownIcon && classes.paddedLeft)}>
                {dropdownText}
            </span>
        ) : null;

        if (iconNode) {
            badgeAnchor = iconNode;
            labelNode = textNode;
        } else {
            badgeAnchor = textNode;
        }

    } else {
        badgeAnchor = <Icon id={iconId} />;
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.blur(); // Get rid of the tooltip!
        if (onClick !== undefined) {
            onClick(event);
        } else if (href !== undefined) {
            location.href = href;
        }
    };

    const badgeProps: BadgeProps = {
        color: badgeColor,
        badgeContent,
        max: 99,
        showZero: false,
        children: badgeAnchor,
        variant: dotBadge ? "dot" : "standard"
    };

    return (
        <Tooltip title={tooltip} placement="bottom">
            <IconButton id={props.id}
                className={buttonClasses}
                onClick={handleClick}
                buttonRef={buttonRef}
                data-tracking-id={props.trackingId}
            >
                <Badge {...badgeProps} />
                {labelNode}
                {caretNode}
            </IconButton>
        </Tooltip>
    );
}

export default withStyles(styles)(NavbarButton);
