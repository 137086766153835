import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";

import CssBaseline from "app/private/CssBaseline";
import { createTheme } from "app/theme";

import ReportErrorDialog from "./ErrorReportDialog";

interface State {
    error: Error;
    errorInfo: React.ErrorInfo;
}

class ErrorBoundaryPanic extends React.PureComponent<{}, State> {
    static getDerivedStateFromError(error: Error, errorInfo: React.ErrorInfo) {
        return { error, errorInfo };
    }

    render() {
        if (this.state === null) {
            return this.props.children;
        }

        const { error } = this.state;

        return (
            <div>
                <h1>Critical Error</h1>
                <pre>
                    {String(error)}
                    {String(error.stack)}
                </pre>
                <p>
                    This is so bad that we can't even show you a nice report error dialog. <br />
                    Please report this manually to our support.
                </p>
            </div>
        );
    }
}

class ErrorBoundaryOuter extends React.PureComponent<{}, State> {
    static getDerivedStateFromError(error: Error, errorInfo: React.ErrorInfo) {
        // Update state so the next render will show the fallback UI.
        return { error, errorInfo };
    }

    render() {
        if (this.state === null) {
            return this.props.children;
        }

        const { error, errorInfo } = this.state;

        return (
            <ErrorBoundaryPanic>
                <CssBaseline>
                    <ThemeProvider theme={createTheme("cloud", {})}>
                        <ReportErrorDialog
                            error={error}
                            errorInfo={errorInfo}
                            canClose={false}
                        />
                    </ThemeProvider>
                </CssBaseline>
            </ErrorBoundaryPanic>
        );
    }
}

export default ErrorBoundaryOuter;
