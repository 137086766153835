import { useEffect, useState } from "react";

import { Theme, useTheme } from "@material-ui/core/styles";

export default function useWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return width;
}

export function getBreakpoint(width: number) {
    const theme = useTheme<Theme>();

    for (const key of theme.breakpoints.keys) {
        if (width <= theme.breakpoints.values[key]) {
            return key;
        }
    }

    return "xl";
}

export function useBreakpoint() {
    return getBreakpoint(useWidth());
}
