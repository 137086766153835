import moment from "moment-timezone";

import { MobiledocContent } from "app/Mobiledoc";
import { getCurrentTime } from "app/currentTime";
import request from "app/http";

const BLOG_API_URL = "/apiv2/user/inbox-blog-posts";

export interface Post {
    title: string;
    description: string;
    mobiledoc?: MobiledocContent;
    full_title: string;
    guid: string;
    link: string;
    pub_date: string;
    short_post: boolean;
    viewed?: boolean;
}

interface GhostPost {
    id: string;
    uuid: string;
    mobiledoc: string;
    plaintext: string;
    custom_excerpt: string;
    url: string;
    title: string;
    published_at: string;
}

type PostsDictionary = { [uuid: string]: Post };

interface BlogResponse {
    posts: PostsDictionary;
    unreadCount: number;
    updatedAt: string;
}

async function refreshBlogPosts(blog: BlogResponse) {
    const ghostPosts: GhostPost[] = await request("/apiv2/blog-posts");

    // TODO: I'm not sure about this processing, should probably be done in the backend
    for (const post of ghostPosts) {
        let viewed = false;

        if (blog.posts[post.uuid] === undefined) {
            blog.unreadCount++;
        } else {
            viewed = !!blog.posts[post.uuid].viewed;
        }

        let mobiledoc: MobiledocContent | undefined = undefined;

        try {
            mobiledoc = JSON.parse(post.mobiledoc);
        } catch (e) {
            console.error(e);
        }

        blog.posts[post.uuid] = {
            description: post.plaintext,
            mobiledoc: mobiledoc,
            pub_date: post.published_at,
            title: post.title,
            full_title: post.custom_excerpt,
            short_post: post.plaintext.length < 500,
            guid: post.uuid,
            link: post.url,
            viewed: viewed
        };
    }

    // Let this run asynchronously
    request({ url: BLOG_API_URL, method: "POST", data: { posts: blog.posts } });
}

export async function fetchBlog(): Promise<Post[]> {
    let blog: BlogResponse = await request(BLOG_API_URL);

    if (!blog) {
        blog = {
            posts: {},
            unreadCount: 0,
            updatedAt: "1970-01-01T00:00:00.000Z"
        };
    }

    const updatedAt = moment(blog.updatedAt);
    const today = getCurrentTime();

    if (updatedAt.isBefore(today, "day")) {
        await refreshBlogPosts(blog);
    }

    return Object.values(blog.posts).sort((a: Post, b: Post) => {
        return moment(a.pub_date).isAfter(moment(b.pub_date)) ? -1 : 1;
    });
}

export function updateBlog(posts: Post[]): Promise<void> {
    const dictionary: PostsDictionary = {};

    for (const post of posts) {
        dictionary[post.guid] = post;
    }

    return request({ url: BLOG_API_URL, method: "POST", data: { posts: dictionary } });
}
