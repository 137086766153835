import React from "react";

import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";

const styles = (theme: Theme) => createStyles({
    root: {
        marginLeft: theme.spacing(2),
        justifySelf: "flex-end"
    }
});

interface Props extends WithStyles<typeof styles> {
    open: boolean;
    invisible?: boolean;
}

function ListItemCaret(props: Props) {
    const { classes, open } = props;

    return (
        <Icon className={classes.root}
            size={16}
            id={open ? "expand_less" : "expand_more"}
        />
    );

}

export default withStyles(styles)(ListItemCaret);