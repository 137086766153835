import React from "react";

import Menu from "@material-ui/core/Menu";

interface Props {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    children: React.ReactNode;
    maxWidth?: number;
    minWidth?: number;
}

function NavbarMenu(props: Props) {
    const { open, anchorEl, onClose, children, maxWidth = 240, minWidth } = props;

    return (
        <Menu open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            getContentAnchorEl={undefined}
            marginThreshold={0}
            MenuListProps={{ disablePadding: true, style: { minWidth, maxWidth } }}
            disableAutoFocusItem
        >
            {children}
        </Menu>
    );
}

export default NavbarMenu;