import React, { useEffect, useRef, useState } from "react";

import request from "app/http";
import _ from "app/lang";
import { getUrlParams } from "app/page/ContextProvider";
import { setupTimer } from "app/timers";

import { ActionProps } from "../Action";
import ActionButton from "../ActionButton";
import ActionPopover from "../ActionPopover";

import TaskTracker, { TaskCategory, taskTrackerWidth } from "./TaskTracker";
import { Task, TaskAttributes } from "./models";

interface RecentTaskCountsResponse {
    newCompleted: number;
    newRunning: number;
}

interface RecentTasksResponse {
    tasks_viewed_at: string | null;
    tasks: TaskAttributes[];
}

interface TaskCounts {
    finished: number;
    running: number;
}

function responseToTaskCounts(res: RecentTaskCountsResponse): TaskCounts {
    return {
        finished: res.newCompleted,
        running: res.newRunning
    };
}

function responseToTasksArray(res: RecentTasksResponse) {
    return res.tasks.map(attrs => new Task({...attrs, tasks_viewed_at: res.tasks_viewed_at}));
}

const REFRESH_INTERVAL = 60000;
const MAX_TASKS = 20;

interface Props extends ActionProps {
}

function TasksAction(props: Props) {
    const { name, open, tooltip, iconId, onClick, onClose, fullScreen } = props;

    const [counts, setCounts] = useState<TaskCounts>({
        running: 0,
        finished: 0
    });

    const [tasks, setTasks] = useState<Task[] | null>(null);

    const openRef = useRef(open);

    const pageParams = getUrlParams();
    const cloudId = pageParams.cloudId;

    const updateRecentTaskCounts = () => {
        request(`/apiv2/cloud/${cloudId}/recent-tasks-count`)
            .then(responseToTaskCounts)
            .then(setCounts);
    };

    const recentTasksUpdatedRef = useRef(0);

    const updateRecentTasks = () => {
        if (recentTasksUpdatedRef.current === -1) {
            return;
        }

        recentTasksUpdatedRef.current = -1;

        request({url: `/apiv2/cloud/${cloudId}/recent-tasks`, data: {limit: MAX_TASKS}})
            .then(responseToTasksArray)
            .then(setTasks)
            .then(() => recentTasksUpdatedRef.current = Date.now());
    };

    const periodicUpdate = () => {
        updateRecentTaskCounts();

        if (openRef.current) {
            updateRecentTasks();
        }
    };

    const urlRef = useRef<string | null>(null);

    useEffect(() => {
        openRef.current = open;

        if (open) {
            // Do reload the tasks on open if they're outdated
            if (Date.now() - recentTasksUpdatedRef.current > REFRESH_INTERVAL) {
                updateRecentTasks();
            }
        } else {
            let markAsViewed: Promise<void>;

            if (tasks !== null && tasks.some(task => !task.isSeen)) {
                markAsViewed = request({url: `/apiv2/cloud/${cloudId}/mark-task-tracker-as-viewed`, method: "POST"});
                tasks.forEach(task => task.markAsSeen());
            } else {
                markAsViewed = Promise.resolve();
            }

            // Clear the badge if there was any
            if (counts.finished !== 0 || counts.running !== 0) {
                setCounts({ running: 0, finished: 0 });
            }

            // If there is something we need to navigate to, do so right after
            // the tasks are marked as viewed (if they needed to be)
            if (urlRef.current !== null) {
                const targetUrl = urlRef.current;

                markAsViewed.then(() => location.href = targetUrl);
            }
        }
    }, [open]);

    useEffect(() => setupTimer("recent-tasks", periodicUpdate, REFRESH_INTERVAL), []);

    const badgeColor = (counts.running > 0)
        ? "secondary"
        : "default";

    const badgeContent = counts.running || counts.finished;

    const initialCategory = (counts.running > 0)
        ? "in-progress"
        : "finished";

    const handleClick = () => onClick(name);
    const handleClose = () => onClose(name);

    // This could be a global but it's here for better code readability
    const statusParams: Record<TaskCategory, string> = {
        "finished": "SUCCESS+FAIL+TIMEOUT",
        "in-progress": "RUNNING+PENDING"
    };

    const handleTaskClick = (taskId: string, category: TaskCategory) => {
        const statusParam = statusParams[category];

        urlRef.current = `/cloud/${cloudId}/activity?status=${statusParam}&id=${taskId}#maintenance`;
        handleClose();
    };

    const handleLinkClick = (href: string) => {
        urlRef.current = href;
        handleClose();
    };

    const handleSeeAllClick = (category: TaskCategory) => {
        const statusParam = statusParams[category];

        urlRef.current = `/cloud/${cloudId}/activity?status=${statusParam}#maintenance`;
        handleClose();
    };

    const buttonElement = useRef<HTMLButtonElement>(null);

    return (
        <>
            <ActionButton
                trackingId={props.trackingId}
                onClick={handleClick}
                tooltip={tooltip}
                iconId={iconId}
                active={open}
                badgeContent={badgeContent}
                badgeColor={badgeColor}
                buttonRef={buttonElement}
            />

            <ActionPopover
                fixedWidth={taskTrackerWidth}
                open={open}
                title={_("Task Tracker")}
                onClose={handleClose}
                anchorEl={buttonElement.current}
                fullScreen={fullScreen}
            >
                <TaskTracker
                    trackingId={props.trackingId + "-tracker"}
                    tasks={tasks}
                    initialCategory={initialCategory}
                    onTaskClick={handleTaskClick}
                    onLinkClick={handleLinkClick}
                    onSeeMoreClick={handleSeeAllClick}
                />
            </ActionPopover>
        </>
    );

}

export default TasksAction;
