import _ from "app/lang";

import { DrawerMenuItem } from "../DrawerMenu";

export default function (baseUrl: string): DrawerMenuItem[] {
    return [
        {
            icon: "dashboard",
            text: _("Dashboard"),
            href: `${baseUrl}/dashboard`
        },
        {
            icon: "multiline_chart",
            text: _("Statistics"),
            subitems: [
                {
                    icon: "device_hub",
                    text: _("Networks"),
                    href: `${baseUrl}/networks`
                },
                {
                    icon: "pie_chart",
                    text: _("System"),
                    href: `${baseUrl}/system`
                }
            ]
        },
        {
            icon: "list_alt",
            text: _("Activity"),
            href: `${baseUrl}/activity`
        }
    ];
}
