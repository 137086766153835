export const routePrefix = "";

export function cloudRoute(cloudId: string) {
    return `/cloud/${cloudId}`;
}

export function siteRoute(siteId: string) {
    return `/site/${siteId}/dashboard#default`;
}

export function deviceRoute(deviceId: string) {
    return `/device/${deviceId}`;
}
