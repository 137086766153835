import classNames from "classnames";
import moment from "moment-timezone";
import React, { useContext } from "react";

import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Formatter from "app/Formatter";
import Icon from "app/Icon";
import _ from "app/lang";
import { pageContext } from "app/page/ContextProvider";
import Link from "app/page/Link";

import {
    SearchResult,
    SearchResultDomain,
    SearchResultMatch,
    SearchResultSource
} from "./models";

const domainTranslations: Record<SearchResultDomain, string> = {
    cloud: _("Cloud"),
    site: _("Site"),
    device: _("Device"),
    user: _("User"),
    client: _("Client")
};

const deletedTranslations: Record<SearchResultDomain, string> = {
    cloud: _("Deleted cloud"),
    site: _("Deleted site"),
    device: _("Deleted device"),
    user: _("Deleted user"),
    client: _("Deleted client")
};

const domainIcons: Record<SearchResultDomain, string> = {
    cloud: "cloud",
    site: "business",
    device: "ignite_flame",
    user: "person",
    client: "wifi"
};

const sourceTranslations: Record<SearchResultSource, string> = {
    devmac: _("MAC"),
    bssid: _("BSSID"),
    radmac: _("Radio MAC"),
    ethmac: _("LAN MAC"),
    wcmac: _("MAC"),
    wanip: _("IP (WAN)"),
    lanip: _("IP (LAN)"),
    wcip: _("IP"),
    devname: _("Name"),
    devsn: _("Serial"),
    devhost: _("Hostname"),
    devnote: _("Note"),
    sitename: _("Name"),
    siteloc: _("Location"),
    sitedesc: _("Description"),
    sitemails: _("Emails"),
    sitenote: _("Note"),
    cloudname: _("Name"),
    userfname: _("First name"),
    userlname: _("Last name"),
    useremail: _("Email"),
    rssid: _("Remote SSID"),
    ssid: _("SSID"),
    wclbl: _("Name"),
    wchost: _("Hostname"),
    clouddesc: _("Description")
};

const styles = (theme: Theme) => createStyles({
    avatarDeleted: {
        opacity: 0.5
    },

    avatarCloud: {
        backgroundColor: theme.colors.level.cloud.main
    },

    avatarSite: {
        backgroundColor: theme.colors.level.site.main
    },

    avatarDevice: {
        backgroundColor: theme.colors.level.device.main
    }
});

interface Props extends WithStyles<typeof styles> {
    query: string;
    result: SearchResult;
    divider: boolean;
    onClick: () => void;
    trackingId: string;
}

function ResultListItem(props: Props) {
    const { result, query, divider, classes } = props;
    const { domain, title, deletedAt, targetLink, matches, updatedAt } = result;
    const isDeleted = (deletedAt !== undefined);
    const { user } = useContext(pageContext);
    const formatter = new Formatter(user);

    // Only show last updated at if the time has been more than 15s old
    const fifteenSecondsAgo = moment().subtract(15, "seconds");
    const showLastUpdatedAt = moment(updatedAt).isBefore(fifteenSecondsAgo);

    let target: JSX.Element;

    if (isDeleted) {
        target = (
            <>
                {deletedTranslations[domain]}:&nbsp;
                <Typography color="textSecondary" display="inline">
                    {title}
                </Typography>
            </>
        );
    } else {
        target = (
            <>
                {domainTranslations[domain]}:&nbsp;
                {title}
            </>
        );
    }

    const avatarClassNames = classNames({
        [classes.avatarDeleted]: isDeleted,
        [classes.avatarCloud]: domain === "cloud",
        [classes.avatarSite]: domain === "site",
        [classes.avatarDevice]: domain === "device"
    });

    return (
        <>
            {divider && <Divider />}
            <SearchResultItem isDeleted={isDeleted}
                href={targetLink}
                onClick={props.onClick}
                trackingId={props.trackingId}
            >
                <ListItemAvatar>
                    <Avatar className={avatarClassNames}>
                        <Icon id={domainIcons[domain]} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={target}
                    secondary={(
                        <>
                            <SearchResultMatches query={query} matches={matches} />
                            {
                                showLastUpdatedAt && updatedAt ? (
                                    <>
                                        <br />
                                        <Typography variant="caption" color="textSecondary">
                                            {_("Last seen ") + formatter.fromNow(updatedAt)}
                                        </Typography>
                                    </>
                                ) : null
                            }
                        </>
                    )}
                />
            </SearchResultItem>
        </>
    );
}

interface SearchResultItemProps {
    isDeleted: boolean;
    href?: string;
    children: React.ReactNode;
    onClick: () => void;
    trackingId: string;
}

function SearchResultItem(props: SearchResultItemProps) {
    const { isDeleted, href, children } = props;

    if (!isDeleted && href) {
        return (
            <Link onClick={props.onClick} to={href} trackingId={props.trackingId} >
                <ListItem dense button component="div">{children}</ListItem>
            </Link>
        );
    } else {
        return <ListItem dense component="div">{children}</ListItem>;
    }
}

function SearchResultRow(props: { query: string; match: SearchResultMatch }) {
    const { match } = props;
    let query = props.query;

    const label = match.source;
    const value = match.value;
    let index = value.toLowerCase().indexOf(query);

    // Special handling for MAC and BSSID values: they are always
    // returned in colon-separated format, even though we allow the
    // search phrases without separators. In order to highlight the
    // matching parts we need to reformat the search phrase so that
    // it's colon-separated.
    if (index === -1) {
        query = query.replace(/([\da-f]{2})/ig, "$1:").slice(0, -1);
        index = value.indexOf(query);
    }

    // Should never happen
    if (index === -1) {
        return null;
    }

    const trim = (segment: string) => (segment.length < 32)
        ? segment
        : segment.substring(0, 28) + "…";

    const prefix = trim(value.substring(0, index));
    const suffix = trim(value.substring(index + query.length));

    const matchIndication = (
        <span>
            {prefix}
            <b>{value.substring(index, index + query.length)}</b>
            {suffix}
        </span>
    );

    return (
        <span>
            {sourceTranslations[label]}: {matchIndication}{"  "}
        </span>
    );
}

function SearchResultMatches(props: { query: string; matches: SearchResultMatch[] }) {
    let { query, matches } = props;

    query = query.toLowerCase();

    if (matches.length > 3) {
        matches = matches.slice(0, 3);
    }

    return(
        <Typography variant="caption" color="textSecondary">
            {matches.map((entry: SearchResultMatch, index: number) => (
                <SearchResultRow key={index} query={query} match={entry} />
            ))}
        </Typography>
    );
}

export default withStyles(styles)(ResultListItem);
