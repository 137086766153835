import classNames from "classnames";
import React, { useState } from "react";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";
import { appBarHeight } from "app/theme";

const titlePadding = 16;
const titleHeight = 48;

const styles = (theme: Theme) => createStyles({
    paper: {
        boxSizing: "border-box",
        minWidth: 240,
        maxWidth: 600,
        padding: 0,
        maxHeight: `calc(100% - ${appBarHeight}px)`,
        overflowY: "hidden",
        display: "flex"
    },

    fullScreenPaper: {
        borderRadius: 0,
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%"
    },

    root: {
        boxSizing: "border-box",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        flexGrow: 0,
        width: "100%"
    },

    title: {
        width: "100%",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        fontSize: 18,
        padding: titlePadding,
        boxSizing: "border-box",
        height: titleHeight,
        lineHeight: `${titleHeight - (titlePadding * 2)}px`,
        flexGrow: 0,
        flexShrink: 0
    },

    buttons: {
        position: "absolute",
        right: theme.spacing(.5),
        top: 0
    },

    fullScreenTitle: {
        // Make root for the close button
        paddingRight: theme.spacing(3)
    },

    content: {
        flexGrow: 1,
        flexShrink: 1,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        "-ms-overflow-style": "-ms-autohiding-scrollbar"
    },

    contentWithTitle: {
        maxHeight: `calc(100% - ${titleHeight}px)`
    }
});

interface Props extends WithStyles<typeof styles> {
    anchorEl: HTMLElement | null;
    open: boolean;
    fullScreen?: boolean;
    onClose?: () => void;
    children: React.ReactNode;
    title?: string;
    showTitleOnlyWhenFullScreen?: boolean;
    maxWidth?: number;
    minWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    fixedWidth?: number;
    settingsComponent?: React.ReactNode;
}

function getTitleNode(props: Props): React.ReactNode {
    if (props.title && (props.fullScreen || !props.showTitleOnlyWhenFullScreen)) {
        const titleClassNames = classNames(props.classes.title, {
            [props.classes.fullScreenTitle]: props.fullScreen
        });

        return (
            <>
                <div className={titleClassNames}>
                    {props.title}
                </div>
                <Divider />
            </>
        );
    } else {
        return null;
    }
}

function ActionPopover(props: Props) {
    const [contentVisible, setContentVisible] = useState<boolean>(false);

    let closeButton: React.ReactNode = null;

    if (props.fullScreen) {
        closeButton = (
            <IconButton onClick={props.onClose}>
                <Icon id="close" />
            </IconButton>
        );
    }

    const titleNode = getTitleNode(props);
    const popoverClasses = {
        paper: classNames(props.classes.paper, {
            [props.classes.fullScreenPaper]: props.fullScreen
        })
    };

    const contentClassNames = classNames({
        [props.classes.content]: true,
        [props.classes.contentWithTitle]: titleNode !== null
    });

    const paperProps = {
        minWidth: props.minWidth || props.fixedWidth,
        maxWidth: props.maxWidth || props.fixedWidth,
        minHeight: props.minHeight,
        maxHeight: props.maxHeight
    };

    return (
        <Popover
            open={props.open}
            onClose={props.onClose}
            anchorReference={props.fullScreen ? "anchorPosition" : "anchorEl"}
            anchorEl={props.anchorEl}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            marginThreshold={0}
            classes={popoverClasses}
            PaperProps={{ style: props.fullScreen ? {} : paperProps }}
            onExited={() => setContentVisible(false)}
            onEntering={() => setContentVisible(true)}
        >
            <div className={props.classes.root}>
                {titleNode}
                <div className={props.classes.buttons}>{props.settingsComponent}{closeButton}</div>
                <div className={contentClassNames}>
                    {contentVisible ? props.children : null}
                </div>
            </div>
        </Popover>
    );
}

export default withStyles(styles)(ActionPopover);