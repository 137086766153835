import React from "react";

import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import Icon from "app/Icon";

const styles = (theme: Theme) => createStyles({
    root: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1)
    }
});

interface Props extends WithStyles<typeof styles> {
    onClick?: () => void;
    visible?: boolean;
}

function DialogCloseButton(props: Props) {
    if (props.visible !== true) {
        return null;
    }

    const { onClick, classes } = props;

    return (
        <IconButton className={classes.root} onClick={onClick}>
            <Icon id="close" />
        </IconButton>
    );
}

export default withStyles(styles)(DialogCloseButton);
