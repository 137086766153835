import classNames from "classnames";
import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";
import _, { ngettext } from "app/lang";
import Link from "app/page/Link";
import { colorGreen, colorRed } from "app/theme";
import { TaskType } from "shared/types";

import PathSeparator from "../PathSeparator";

import { Task, TaskState } from "./models";

const DEFAULT_COLOR = ""; // Leave blank for Material-UI default
const SUCCESS_COLOR = colorGreen;
const ERROR_COLOR = colorRed;

const styles = (theme: Theme) => createStyles({
    root: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#f3f3f3"
        },
    },

    rootText: {
        backgroundColor: "transparent",
        width: "100%",
    },

    unseen: {
        backgroundColor: "#FCF2E7",
        "&:hover": {
            backgroundColor: "#FEF9F3"
        },
    },

    unseenText: {
        fontWeight: theme.typography.fontWeightMedium
    },

    error: {
        color: ERROR_COLOR
    }
});

const typeTranslations: Record<TaskType, string> = {
    TASK_CONFIG: _("Change Config"),
    TASK_CONFIG_RECEIVED: _("Received Config"),
    TASK_FACTORY_RESET: _("Factory Reset"),
    TASK_FW_UPDATE: _("Upgrade Firmware"),
    TASK_FW_UPDATE_AUTO: _("Auto Firmware Upgrade"),
    TASK_FW_UPDATE_ROLLING: _("Rolling Firmware Upgrade"),
    TASK_NETCAP: _("Packet Capture"),
    TASK_PACKET_DUMP: _("Packet Dump"),
    TASK_REBOOT: _("Reboot"),
    TASK_REPORT: _("Report"),
    TASK_TSHOOT: _("Troubleshooting"),
    TASK_CREATE_SDWAN_CERT: _("SDWAN Certificate"),
    TASK_ADDON_SYNC_KEY: _("Addon Management"),
    TASK_ADDON: _("Addon Management"),
    TASK_MANAGE_DEVICE_UPDATE: _("Managed Device Update"),
    TASK_ONVIF: _("ONVIF Scan")
};

const statusTranslations: Record<TaskState, string | ((count: number) => string)> = {
    TASK_CANCELED: _("Canceled"),
    TASK_FAIL: _("Failed"),
    TASK_PENDING: _("Scheduled"),
    TASK_RUNNING: _("Running"),
    TASK_STANDBY: _("Waiting"),
    TASK_SUCCESS: _("Completed"),
    TASK_TIMEOUT: _("Timed out"),
    // Can't use ngettext() here because e.g. in Lithuanian deviceCount == 51
    // would result in the singular form
    TASK_WAIT_ACK: (count: number) => count > 1 ? _("Contacting devices") : _("Contacting device")
};

const statusIcons: Record<TaskState, string> = {
    TASK_CANCELED: "clear",
    TASK_FAIL: "clear",
    TASK_PENDING: "schedule",
    TASK_RUNNING: "timelapse",
    TASK_STANDBY: "schedule",
    TASK_SUCCESS: "check",
    TASK_TIMEOUT: "clear",
    TASK_WAIT_ACK: "schedule"
};

const statusColors: Record<TaskState, string> = {
    TASK_CANCELED: ERROR_COLOR,
    TASK_FAIL: ERROR_COLOR,
    TASK_PENDING: DEFAULT_COLOR,
    TASK_RUNNING: DEFAULT_COLOR,
    TASK_STANDBY: DEFAULT_COLOR,
    TASK_SUCCESS: SUCCESS_COLOR,
    TASK_TIMEOUT: ERROR_COLOR,
    TASK_WAIT_ACK: DEFAULT_COLOR
};

interface Props extends WithStyles<typeof styles> {
    task: Task;
    divider?: boolean;
    onTaskClick: (id: string) => void;
    onLinkClick: (href: string) => void;
    trackingId: string;
}

function TaskListItem(props: Props) {
    const { task, divider = true, onLinkClick, onTaskClick, classes } = props;

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onLinkClick(event.currentTarget.href);
    };

    const deviceCount = task.getDeviceCount();

    let affected;

    if (task.deviceName) {
        affected = (
            <Link
                to={`/device/${task.deviceId}`}
                onClick={handleLinkClick}
                trackingId={`${props.trackingId}-device`}
            >
                {task.deviceName}
            </Link>
        );
    } else {
        affected = deviceCount > 0
            ? ngettext("1 device affected", ":count devices affected", deviceCount)
            : null;
    }

    let parentLink;

    if (task.siteName) {
        parentLink = (
            <Link
                to={`/site/${task.siteId}`}
                onClick={handleLinkClick}
                trackingId={`${props.trackingId}-site`}
            >
                {task.siteName}
            </Link>
        );
    } else if (task.snvrDeviceName) {
        parentLink = (
            <Link
                to={`/smartnvr/${task.snvrDeviceId}`}
                onClick={handleLinkClick}
                trackingId={`${props.trackingId}-smartnvr`}
            >
                {task.snvrDeviceName}
            </Link>
        );
    }

    let statusTranslated = statusTranslations[task.state];

    if (typeof statusTranslated === "function") {
        statusTranslated = statusTranslated(deviceCount);
    }

    const errorCount = task.getErrorCount();

    let status: JSX.Element;

    if (errorCount > 0) {
        status = (
            <Typography variant="caption" color="textSecondary">
                {statusTranslated}:{" "}
                <Typography color="error">
                    {ngettext("1 error", ":count errors", errorCount)}
                </Typography>
            </Typography >
        );
    } else {
        status = (
            <Typography variant="caption" color="textSecondary">
                {statusTranslated}
            </Typography >
        );
    }

    let taskLocation;

    if (affected !== null) {
        taskLocation = (
            <>
                {parentLink}<PathSeparator />{affected}
            </>
        );
    } else {
        taskLocation = parentLink;
    }

    let progress: JSX.Element | null = null;

    const percentComplete = task.getPercentComplete();

    if (percentComplete && percentComplete !== 100) {
        progress = (
            <LinearProgress
                variant="determinate"
                color="secondary"
                value={percentComplete}
                style={{ height: 2 }}
            />
        );
    }

    const handleTaskClick = () => onTaskClick(task.id);

    const primaryClass = task.isSeen ? classes.root : classNames(classes.root, classes.unseen);
    const primaryTextClass = task.isSeen ? classes.rootText : classNames(classes.rootText, classes.unseenText);

    return (
        <>
            {divider && <Divider />}
            <ListItem
                dense
                className={primaryClass}
                alignItems="flex-start"
                onClick={handleTaskClick}
                data-tracking-id={`${props.trackingId}-${task.type}-${task.state}`}
            >
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: statusColors[task.state] }}>
                        <Icon id={statusIcons[task.state]} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    className={primaryTextClass}
                    primary={typeTranslations[task.type]}
                    secondary={(
                        <>
                            {taskLocation}
                            <br />
                            {status}
                        </>
                    )}
                />
            </ListItem>
            {progress}
        </>
    );
}

export default withStyles(styles)(TaskListItem);
