import classNames from "classnames";
import React from "react";

import ButtonBase from "@material-ui/core/ButtonBase";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import { ThemeColorOptions } from "app/theme";

export type ButtonVariant = "contained" | "outlined" | "text";
export type ButtonSize = "small" | "medium";
export type ButtonType = "button" | "submit" | "reset";

function buttonBackground(colorOptions: ThemeColorOptions) {
    return (colorOptions.bottom !== undefined)
        ? `linear-gradient(to bottom, ${colorOptions.main} 45%, ${colorOptions.bottom})`
        : colorOptions.main;
}

const styles = (theme: Theme) => createStyles({
    root: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        borderRadius: 3,
        border: "none",
        textTransform: "uppercase",
        textAlign: "center",
        fontWeight: 400,
        padding: "8px 16px",
        flexShrink: 0
    },

    disabled: {
        cursor: "default",
        pointerEvents: "none",
        opacity: 0.25
    },

    // Need this in order to be able to reference
    dangerous: {},

    contained: {
        background: buttonBackground(theme.colors.primary),
        color: theme.colors.primary.contrastText,
        boxShadow: "0 1px 4px 0 rgba(0,0,0,.1)",
        "&:hover": {
            background: theme.colors.primary.dark
        },
        "&$dangerous": {
            background: buttonBackground(theme.colors.danger)
        },
        "&$dangerous:hover": {
            background: theme.colors.danger.dark
        }
    },

    outlined: {
        border: `1px solid ${theme.colors.button.outlined.border}`,
        padding: "7px 15px",
        background: buttonBackground(theme.colors.button.outlined),
        color: theme.colors.button.outlined.contrastText,
        "&:hover": {
            background: theme.colors.button.outlined.dark,
            borderColor: theme.colors.button.outlined.darkBorder
        },
        "&$dangerous": {
            background: theme.colors.danger.contrastText,
            borderColor: theme.colors.danger.main,
            color: theme.colors.danger.main
        },
        "&$dangerous:hover": {
            background: buttonBackground(theme.colors.danger),
            border: "none",
            padding: "8px 16px",
            color: theme.colors.danger.contrastText
        }
    },

    text: {
        fontWeight: theme.typography.fontWeightMedium,
        background: theme.colors.button.text.main,
        color: theme.colors.button.text.contrastText,
        "&:hover": {
            background: theme.colors.button.text.dark
        },
        "&$dangerous": {
            color: theme.colors.danger.main
        },
        "&$dangerous:hover": {
            background: fade(theme.colors.danger.main, .25),
            // color: theme.colors.danger.contrastText
        }
    },

    small: {
        lineHeight: 1.230769,
        padding: "4px 8px",
        "&$outlined": {
            padding: "3px 7px",
            "&$dangerous:hover": {
                padding: "4px 8px"
            }
        },
        fontSize: "0.6875rem",
        "&$text": {
            padding: "3px 8px"
        },
        "&>svg": {
            width: 13,
            height: 13,
        },
        "& > svg:first-child": {
            marginLeft: -2,
            marginRight: 3
        },
        "& > * + svg:last-of-type": {
            marginLeft: 3,
            marginRight: -2
        }
    },

    medium: {
        lineHeight: "16px",
        height: 32,
        minWidth: 120,
        "&$text": {
            minWidth: 64
        },
        "& > svg:first-child": {
            marginLeft: -6,
            marginRight: 4
        },
        "& > * + svg:last-of-type": {
            marginLeft: 4,
            marginRight: -6
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    variant?: ButtonVariant;
    dangerous?: boolean;
    size?: ButtonSize;
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    tabIndex?: number;
    type?: ButtonType;
    children: React.ReactNode;
    component?: string;
}

function Button(props: Props) {
    const {
        variant: _variant,
        size: _size,
        dangerous,
        children,
        classes,
        ...others
    } = props;

    const variant = _variant || "outlined";
    const size = _size || "medium";

    const rootClass = classNames({
        [classes.root]: true,
        [classes[variant]]: true,
        [classes[size]]: true,
        [classes.disabled]: props.disabled,
        [classes.dangerous]: dangerous
    });

    return (
        <ButtonBase
            classes={{ root: rootClass }}
            focusRipple
            disableRipple={variant === "text"}
            {...others}
        >
            {children}
        </ButtonBase>
    );
}

export default withStyles(styles, { name: "Button" })(Button);

declare module "@material-ui/core/styles/overrides" {
    interface ComponentNameToClassKey {
        Button: keyof ReturnType<typeof styles>;
    }
}
