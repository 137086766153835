import React, { useContext } from "react";

import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import _ from "app/lang";
import { CloudContext, pageContext } from "app/page/ContextProvider";
import { Cloud } from "app/private/AppBar/CloudAction/models";

import CloudListItem from "./CloudListItem";

interface Props {
    trackingId: string;
    filter: string;
    clouds: Cloud[];
}

export default function CloudListContents(props: Props) {
    const context = useContext(pageContext) as CloudContext;

    if (props.filter.length && props.clouds.length === 0) {
        return (
            <MenuItem disabled>
                <ListItemText primary={_("No clouds found.")} />
            </MenuItem>
        );
    } else {
        return (
            <MenuList dense disablePadding>
                {props.clouds.map(cloud => (
                    <CloudListItem key={cloud.id}
                        cloud={cloud}
                        isCurrent={cloud.id === context.cloud.id}
                        trackingId={`${props.trackingId}-switchCloud`}
                    />
                ))}
            </MenuList>
        );
    }
}
