import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Loading from "app/Loading";

import MessageCard from "./MessageCard";
import { Post } from "./blog";

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        flexShrink: 1,
        maxHeight: "100%",
        overflowY: "auto",
        "-ms-overflow-style": "-ms-autohiding-scrollbar"
    },

    loading: {
        padding: `${theme.spacing(2)}px 0`
    },

    empty: {
        padding: theme.spacing(2)
    }
});

interface Props extends WithStyles<typeof styles> {
    posts: Post[] | null;
    onViewed: (post: Post) => void;
    trackingId: string;
}

function MessageList(props: Props) {
    const { posts, onViewed, classes } = props;
    const [openIndex, setOpenIndex] = useState(-1);

    if (posts === null) {
        return (
            <Loading className={classes.loading} />
        );
    }

    if (posts.length === 0) {
        // The message isn't translated because it's never going to be shown
        // in production environments, only in development.
        return (
            <Typography className={classes.empty} color="textSecondary">
                No news is good news.
            </Typography>
        );
    }

    const handleToggle = (post: Post, index: number) => {
        setOpenIndex(index !== openIndex ? index : -1);

        if (!post.viewed) {
            post.viewed = true;
            onViewed(post);
        }
    };

    return (
        <div className={classes.root}>
            {posts.map((post, index) => (
                <MessageCard key={post.guid}
                    divider={index > 0}
                    open={index === openIndex}
                    post={post}
                    onToggle={() => handleToggle(post, index)}
                    trackingId={`${props.trackingId}-messageCard`}
                />
            ))}
        </div>
    );
}

export default withStyles(styles)(MessageList);
