import classNames from "classnames";
import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

import Icon from "app/Icon";
import MessageThemeProvider from "app/private/MessageThemeProvider";
import { fontFamily, fontSize } from "app/theme";

export type MessageVariant = "success" | "warning" | "error";
export type MessageActions = React.ReactElement<any> | React.ReactElement<any>[] | null;

export const messageIcons: Record<MessageVariant, string> = {
    "success": "check_circle",
    "warning": "error",
    "error": "error"
};

export const messageLinkStyles: CSSProperties = {
    "& a": {
        color: "#579dc4",
        textDecoration: "none"
    },

    "& a:hover": {
        textDecoration: "underline"
    },

    "& a:active": {
        color: "#085278",
        textDecoration: "underline"
    }
};

const styles = (theme: Theme) => createStyles({
    root: {
        position: "relative",
        fontFamily,
        fontSize,
        borderWidth: 0,
        borderBottomWidth: 1,
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap"
        },
        ...messageLinkStyles
    },

    hasClose: {
        "& $content": {
            [theme.breakpoints.down("sm")]: {
                paddingRight: 36
            }
        },
        "& $actions": {
            [theme.breakpoints.down("sm")]: {
                marginRight: 12
            },
            marginRight: 36
        }
    },

    closeButton: {
        position: "absolute",
        color: "currentColor",
        top: 9,
        right: 9,
        width: 24,
        height: 24,
        padding: 0,
    },

    icon: {
        marginTop: 1,
        marginRight: 8,
        flexShrink: 0,
        // No icon in mobile view
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },

    content: {
        padding: "12px 12px 12px 24px",
        display: "flex",
        flexShrink: 1,
        [theme.breakpoints.down("sm")]: {
            flexBasis: "100%"
        }
    },

    actions: {
        flexShrink: 0,
        margin: "5px 12px 0 auto",
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            marginBottom: 12
        }
    },

    ...theme.messageStyles
});

const useStyles = makeStyles(styles);

interface Props {
    variant: MessageVariant;
    children: React.ReactNode;
    onClose: () => void;
    closed: boolean;
    actions?: MessageActions;
    hasClose?: boolean;
}

function SystemMessage(props: Props) {
    const { closed, variant, children, onClose, hasClose } = props;
    const classes = useStyles();
    const [closing, setClosing] = useState(false);

    if (closed) {
        return null;
    }

    const rootClasses = classNames(
        classes.root,
        classes[variant],
        hasClose && classes.hasClose
    );

    const closeButton = hasClose ? (
        <IconButton className={classes.closeButton}
            disabled={closing}
            onClick={() => setClosing(true)}
        >
            <Icon small id="close" />
        </IconButton>
    ) : null;

    const actions = (props.actions !== undefined) ? (
        <div className={classes.actions}>
            {props.actions}
        </div>
    ) : null;

    return (
        <MessageThemeProvider variant={variant}>
            <Slide direction="left" appear in={!closing} onExited={onClose}>
                <div className={rootClasses}>
                    {closeButton}
                    <div className={classes.content}>
                        <Icon small className={classes.icon} id={messageIcons[variant]} />
                        <div>{children}</div>
                    </div>
                    {actions}
                </div>
            </Slide>
        </MessageThemeProvider>
    );
}

export default SystemMessage;