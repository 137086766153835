export default function scrollIntoView(element: HTMLElement, opts: ScrollIntoViewOptions) {
    if (typeof element.scrollIntoView !== "function") {
        return;
    }

    // Some scrollIntoView options (ex: block: "nearest")
    // are not supported in older browsers
    try {
        element.scrollIntoView(opts);
    } catch (e) {
        element.scrollIntoView({
            behavior: "smooth",
        });
    }
}