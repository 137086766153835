let supportsPassive: boolean | undefined = undefined;

export function detectPassiveEventListenerSupport(): boolean {
    if (supportsPassive === undefined) {
        supportsPassive = false;

        const options = Object.defineProperty({}, "passive", {
            get() {
                supportsPassive = true;
            }
        });

        try {
            window.addEventListener("test", null as any, options);
            // eslint-disable-next-line no-empty
        } catch (e) { }
    }

    return supportsPassive;
}

export function detectModernBrowser(): boolean {
    return "Map" in window &&
        "Promise" in window &&
        "finally" in Promise.prototype &&
        "fetch" in window &&
        "classList" in Element.prototype &&
        "startsWith" in String.prototype &&
        "trim" in String.prototype &&
        "includes" in String.prototype &&
        "includes" in Array.prototype &&
        "assign" in Object &&
        "entries" in Object &&
        "assign" in Object &&
        "keys" in Object;
}

export function detectPushNotifications(): boolean {
    return "serviceWorker" in navigator
        && "PushManager" in window
        && "Notification" in window
        && "fetch" in window;
}

export function detectMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
