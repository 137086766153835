import React from "react";

import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import InlineBadge, { InlineBadgeProps } from "app/InlineBadge";

const styles = (theme: Theme) => createStyles({
    root: {
        marginLeft: theme.spacing(1.5),
        marginRight: -theme.spacing(1),
        justifySelf: "flex-end",
    }
});

type Props = InlineBadgeProps & WithStyles<typeof styles>;

function ListItemBadge(props: Props) {
    const { classes, ...others } = props;

    return <InlineBadge className={classes.root} {...others} />;
}

export default withStyles(styles)(ListItemBadge);