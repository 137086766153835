import _ from "app/lang";
import Device from "app/models/Device";
import { versionCompare } from "app/utils";

import { DrawerMenuItem } from "../DrawerMenu";

export default function (baseUrl: string, device: Device): DrawerMenuItem[] {
    const fe2Url = "/v2/cloud/:cloudId/site/:siteId/device/:deviceId";

    const menuItems = [
        {
            icon: "dashboard",
            text: _("Dashboard"),
            href: `${baseUrl}/dashboard`
        },
        {
            icon: "ports",
            text: _("Ports"),
            href: `${baseUrl}/ports`
        },
        {
            icon: "list_alt",
            text: _("Activity"),
            href: `${baseUrl}/activity`
        },
    ];

    if (device.mgmtdVersion && versionCompare(device.mgmtdVersion, "1.5.0") >= 0) {
        menuItems.push({
            icon: "description",
            text: _("Files"),
            href: `${fe2Url}/device-files`
        });
    }

    return menuItems;
}
