interface I18nMessages {
    readonly locale: string;
    messageIndex(n: number): number;
    readonly messages: {
        [key: string]: Readonly<string> | Readonly<string[]>;
    };
}

declare const __i18n: I18nMessages | undefined;

interface Replacements {
    [key: string]: any;
}

export function getLocale() {
    if (typeof __i18n === "undefined") {
        return "en";
    }

    return __i18n.locale;
}

function doReplacements(s: string, replacements?: Replacements): string {
    if (replacements === undefined) {
        return s;
    }

    s = s.replace(/\B:(\w+)\b/g, (match, tag: string) => {
        if (replacements[tag] !== undefined) {
            return replacements[tag];
        }

        console.warn(`i18n: missing replacement tag ':${tag}'`);
        return match;
    });

    return s;
}

export function gettext(msgid: string, replacements?: Replacements): string {
    // Production + English
    if (typeof __i18n === "undefined") {
        return doReplacements(msgid, replacements);
    }

    const entry = __i18n.messages[msgid];

    if (entry === undefined || entry.length === 0) {
        return doReplacements(msgid, replacements);
    }

    let message: string;

    // Plural msgid? Use the singular form.
    if (typeof entry === "string") {
        message = entry;
    } else if (entry instanceof Array) {
        message = entry[0];
    } else {
        throw new TypeError(`invalid translation entry for '${msgid}'`);
    }

    return doReplacements(message, replacements);
}

export function ngettext(msgid: string, msgidPlural: string, count: number, replacements: Replacements = {}): string {
    replacements.count = count;

    const replace = () => doReplacements(count === 1 ? msgid : msgidPlural, replacements);

    // Production + English
    if (typeof __i18n === "undefined") {
        return replace();
    }

    const messages = __i18n.messages[msgid];

    if (messages === undefined) {
        return replace();
    }

    if (!(messages instanceof Array)) {
        console.warn(`ngettext(): '${msgid}' has no plural translations`);
        return replace();
    }

    const messageIndex = __i18n.messageIndex(count);
    const message = messages[messageIndex];

    if (message === undefined || message.length === 0) {
        return replace();
    }

    return doReplacements(message, replacements);
}

export default gettext;
