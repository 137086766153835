import React from "react";

import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";

const styles = createStyles({
    root: {
        verticalAlign: "middle",
        marginTop: -2,
        height: 16,
        width: 16
    }
});

function PathSeparator(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Icon className={classes.root} id="chevron_right" />
    );
}

export default withStyles(styles)(PathSeparator);
