let visibilityEvent: string | null = null;
let hiddenAttr: string | null = null;

if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hiddenAttr = "hidden";
    visibilityEvent = "visibilitychange";

} else if (typeof (document as any).msHidden !== "undefined") {
    hiddenAttr = "msHidden";
    visibilityEvent = "msvisibilitychange";

} else if (typeof (document as any).webkitHidden !== "undefined") {
    hiddenAttr = "webkitHidden";
    visibilityEvent = "webkitvisibilitychange";
}

/**
 * If browser does not support visibility api, it will
 * seem that page is always visible
 *
 * @return {boolean} TRUE if feature unsupported or page is visible
 */
export function isPageVisible(): boolean {
    return hiddenAttr === null || (document as any)[hiddenAttr] === false;
}

/**
 * If browser does not support visibility api, it will
 * seem that page is never invisible
 *
 * @return {boolean} TRUE if feature supported and page is invisible
 */
export function isPageInvisible(): boolean {
    return hiddenAttr !== null && (document as any)[hiddenAttr] === true;
}

export function onPageVisibilityChange(callback: () => void) {
    if (visibilityEvent) {
        document.addEventListener(visibilityEvent, callback);
    } else {
        console.warn("Browser does not support visibility API.");
    }
}