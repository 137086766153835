import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import InlineBadge from "app/InlineBadge";
import _ from "app/lang";

import ActionBody from "../ActionBody";
import ActionFooter from "../ActionFooter";

import TaskList from "./TaskList";
import { Task } from "./models";

export type TaskCategory = "finished" | "in-progress";
export const taskTrackerWidth = 400;
const numberOfTabs = 2;

const styles = (theme: Theme) => createStyles({
    tabContainer: {
        minHeight: 0,
        flexShrink: 0,
        flexGrow: 0
    },

    progressLabelWrapper: {
        display: "flex",
        justifyContent: "center"
    },

    progressCount: {
        marginLeft: theme.spacing(1)
    },

    tab: {
        minHeight: 0,
        flexBasis: "50%"
    },

    loading: {
        margin: "32px auto"
    },

    tabIndicator: {
        minWidth: taskTrackerWidth / numberOfTabs
    }
});

interface Props extends WithStyles<typeof styles> {
    tasks: Task[] | null;
    initialCategory: TaskCategory;
    onTaskClick: (taskId: string, category: TaskCategory) => void;
    onLinkClick: (href: string) => void;
    onSeeMoreClick: (category: TaskCategory) => void;
    trackingId: string;
}

function TaskTracker(props: Props) {
    const { tasks, onTaskClick, onLinkClick, onSeeMoreClick, classes } = props;
    const [activeCategory, setActiveCategory] = useState<TaskCategory>(props.initialCategory);

    const handleTabChange = (event: React.ChangeEvent<{}>, category: TaskCategory) => {
        setActiveCategory(category);
    };

    let filteredTasks: Task[] | null = null;
    let progressCount = 0;

    if (tasks !== null) {
        const filterCallback = (activeCategory === "finished")
            ? (task: Task) => task.isFinished()
            : (task: Task) => !task.isFinished();

        filteredTasks = tasks.filter(filterCallback);

        progressCount = (activeCategory === "finished")
            ? tasks.length - filteredTasks.length
            : filteredTasks.length;
    }

    const handleTaskClick = (taskId: string) => {
        onTaskClick(taskId, activeCategory);
    };

    const handleLinkClick = (href: string) => {
        onLinkClick(href);
    };

    const handleSeeMoreClick = () => {
        onSeeMoreClick(activeCategory);
    };

    const noTasksMessage = (activeCategory === "finished")
        ? _("No recently finished tasks")
        : _("No in-progress tasks");

    const disableSeeMore = activeCategory === "in-progress"
        && filteredTasks !== null
        && filteredTasks.length === 0;

    const progressLabel = (
        <div className={classes.progressLabelWrapper}>
            {_("In Progress")}
            <InlineBadge
                className={classes.progressCount}
                value={progressCount}
            />
        </div>
    );

    return (
        <>
            <Tabs classes={{root: classes.tabContainer, indicator: classes.tabIndicator}}
                variant="fullWidth"
                indicatorColor="primary"
                value={activeCategory}
                onChange={handleTabChange}
            >
                <Tab className={classes.tab} label={_("Finished")} value="finished" data-tracking-id={`${props.trackingId}-tab-finished`} />
                <Tab className={classes.tab} label={progressLabel} value="in-progress" data-tracking-id={`${props.trackingId}-tab-inProgress`} />
            </Tabs>
            <ActionBody>
                <TaskList tasks={filteredTasks}
                    noTasksMessage={noTasksMessage}
                    onTaskClick={handleTaskClick}
                    onLinkClick={handleLinkClick}
                    trackingId={`${props.trackingId}-taskList`}
                />
            </ActionBody>
            <ActionFooter buttons alignRight>
                <Button disabled={disableSeeMore}
                    size="small"
                    color="primary"
                    onClick={handleSeeMoreClick}
                    data-tracking-id={`${props.trackingId}-seeMore`}
                >
                    {_("See more on Activity page")}
                </Button>
            </ActionFooter>
        </>
    );
}

export default withStyles(styles)(TaskTracker);