export class Model<T> {
    constructor(public attrs: T) {}

    get<K extends keyof T>(key: K): T[K] {
        return this.attrs[key];
    }

    set<K extends keyof T>(key: K, val: T[K]): void {
        this.attrs[key] = val;
    }
}