import React, { useContext, useState } from "react";

import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import Icon from "app/Icon";
import Trans from "app/Trans";
import { detectPushNotifications } from "app/detect";
import request from "app/http";
import _ from "app/lang";
import { loadNotifications } from "app/notifications";
import { CloudContext, pageContext } from "app/page/ContextProvider";
import { showSystemMessage } from "app/page/Page";

export default function PushAlertsToggle() {
    const context = useContext(pageContext) as CloudContext;

    const pushAlertsEnabled = context.userCloud.push_alerts
        && detectPushNotifications()
        && Notification.permission === "granted";

    const [ pushAlerts, setPushAlerts ] = useState<boolean|null>(pushAlertsEnabled);

    function handlePushAlertsChange() {
        if (pushAlerts === null) {
            return;
        }

        setPushAlerts(null);

        const desiredState = !pushAlerts;

        const loadPromise = desiredState ? loadNotifications() : Promise.resolve();

        loadPromise.then(
            () => {
                setPushAlerts(desiredState);

                // This will not trigger page re-render
                // we don't want that anyways
                context.userCloud.push_alerts = desiredState;
            }
        ).then(
            () => request({
                url: "/apiv2/cloud/:cloudId",
                method: "POST",
                data: {
                    user: {
                        push_alerts: desiredState
                    }
                }
            })
        ).catch(() => {
            showSystemMessage({
                variant: "error",
                content: _(
                    "Failed to start push notifications. " +
                    "Your browser might not support this feature " +
                    "or it is blocked in browser settings."
                )
            });
        });
    }

    let iconId;
    let tooltipText;

    if (pushAlerts === null) {
        iconId = "bell_plus_outline";
        tooltipText =  (
            <Trans text="Please allow notifications in your browser" />
        );
    } else if (pushAlerts) {
        iconId = "bell_ring";
        tooltipText =  (
            <Trans
                text="Push notifications are enabled for :cloud cloud"
                vars={{cloud: context.cloud.name}}
            />
        );
    } else {
        iconId = "bell_off_outline";
        tooltipText =  (
            <Trans
                text="Push notifications are disabled for :cloud cloud"
                vars={{cloud: context.cloud.name}}
            />
        );
    }

    return (
        <Tooltip title={tooltipText}>
            <IconButton onClick={handlePushAlertsChange}>
                <Icon id={iconId} />
            </IconButton>
        </Tooltip>
    );
}