import classNames from "classnames";
import React from "react";

import Divider from "@material-ui/core/Divider";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1, 2),
        flexShrink: 0,
        flexGrow: 0
    },

    buttons: {
        margin: theme.spacing(0, -1)
    },

    alignRight: {
        textAlign: "right"
    }
});

interface Props extends WithStyles<typeof styles> {
    divider?: boolean;
    buttons?: boolean;
    alignRight?: boolean;
    children: React.ReactNode;
}

function ActionFooter(props: Props) {
    const { divider = true, buttons, alignRight, children, classes } = props;

    const innerClassName = classNames({
        [classes.buttons]: buttons,
        [classes.alignRight]: alignRight
    });

    return (
        <>
            {divider ? <Divider /> : null}
            <div className={classes.root}>
                <div className={innerClassName}>
                    {children}
                </div>
            </div>
        </>
    );
}

export default withStyles(styles)(ActionFooter);
