import React, { useContext } from "react";

import MuiAppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import Icon from "app/Icon";
import { pageContext } from "app/page/ContextProvider";
import { appBarHeight, drawerWidth } from "app/theme";

import ActionBar from "./ActionBar";
import Breadcrumb, { NavigationPath } from "./Breadcrumb";

const styles = (theme: Theme) => createStyles({
    root: {
        position: "fixed",
        right: 0,
        height: appBarHeight,
        backgroundColor: theme.colors.appBar.background,
        padding: 0,
        zIndex: theme.zIndex.appBar,
        alignItems: "flex-end",
        flexWrap: "nowrap",
        display: "flex",
        boxSizing: "border-box",
        width: "100%",
        flexDirection: "row",
        "@media print": {
            display: "none"
        },
    },

    drawerToggle: {
        position: "fixed",
        left: 0,
        top: 0,
        height: appBarHeight,
        width: appBarHeight,
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        zIndex: theme.zIndex.appBar + 1,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        },
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },

    logoContainer: {
        flexShrink: 0,
        height: 46,
        overflowX: "hidden",
        cursor: "pointer",

        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            paddingLeft: 37
        },

        [theme.breakpoints.down("sm")]: {
            maxWidth: 200,
            paddingLeft: appBarHeight + 18
        },

        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },

    logoImage: {
        maxHeight: 42
    },

    breadcrumb: {
        margin: 0,
        boxSizing: "border-box",
        flexGrow: 1,
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }
});

const useStyles = makeStyles(styles);

interface Props {
    path: NavigationPath;
    onToggleDrawer: () => void;
}

export default function AppBar(props: Props) {
    const { path, onToggleDrawer } = props;
    const classes = useStyles();
    const context = useContext(pageContext);
    const theme = useTheme<Theme>();

    const onLogoClick = () => {
        window.location.href = "/";
    };

    let logo;

    if (theme.logoImage !== undefined) {
        logo = <img className={classes.logoImage} src={theme.logoImage} />;
    } else {
        logo = (
            <svg height={38} width={116}>
                <use href="#ignitenet_logo" xlinkHref="#ignitenet_logo" />
            </svg>
        );
    }

    return (
        <MuiAppBar
            id="navbar" // used in legacy pages
            component="header"
            className={classes.root}
            elevation={0}
        >
            <IconButton className={classes.drawerToggle} onClick={onToggleDrawer}>
                <Icon id="menu" />
            </IconButton>
            <Tooltip enterDelay={1000} title={"Application version: " + context.appVersion}>
                <span className={classes.logoContainer} onClick={onLogoClick}>
                    {logo}
                </span>
            </Tooltip>
            <div className={classes.breadcrumb}>
                <Breadcrumb path={path} />
            </div>
            <ActionBar />
        </MuiAppBar>
    );
}
