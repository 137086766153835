import merge from "deepmerge";
import React from "react";

import { ThemeProvider, useTheme } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { MessageVariant } from "app/private/SystemMessage";

function createTheme(variant: MessageVariant, baseTheme: Theme) {
    // Not 100% sure this is the correct way to clone a theme but it works fine
    const theme = merge<Theme>({}, baseTheme);

    const { color, backgroundColor } = theme.messageStyles[variant];
    const contrastText = "#ffffff";

    theme.overrides = theme.overrides || {};
    theme.overrides.Button = {
        outlined: {
            borderColor: color,
            color,
            background: backgroundColor,
            "&:hover": {
                background: color,
                borderColor: color,
                color: contrastText
            }
        },

        contained: {
            background: color,
            borderColor: color,
            color: backgroundColor,
            "&:hover": {
                background: color,
                borderColor: color,
                color: contrastText
            }
        },

        text: {
            padding: theme.spacing(1),
            "&:hover": {
                background: "rgba(0, 0, 0, 0.08)"
            }
        }
    };

    theme.palette.primary.main = color!;
    theme.palette.primary.dark = color!;
    theme.palette.primary.contrastText = contrastText;

    return theme;
}

const themes: Partial<Record<MessageVariant, Theme>> = {};

interface Props {
    variant: MessageVariant;
    children: React.ReactNode;
}

function MessageThemeProvider(props: Props) {
    const outerTheme = useTheme<Theme>();

    let theme = themes[props.variant];

    if (theme === undefined) {
        theme = createTheme(props.variant, outerTheme);
        themes[props.variant] = theme;
    }

    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}

export default MessageThemeProvider;
