import React from "react";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";
import { cloudRoute } from "app/route";

import { Cloud } from "./models";

const styles = (theme: Theme) => createStyles({
    root: {
        paddingLeft: theme.spacing(4),
        // MenuItem component currently does not accept disabled class
        // This workaround seems to do what we want
        // https://github.com/mui-org/material-ui/issues/14378
        "&.Mui-disabled": {
            opacity: 1
        },
    },

    selected: {
        color: theme.colors.level.cloud.main
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    cloud: Cloud;
    isCurrent: boolean;
    trackingId: string;
}

function CloudListItem(props: Props) {
    const { cloud, isCurrent, classes } = props;

    const item = (
        <MenuItem
            className={classes.root}
            key={cloud.id}
            disabled={isCurrent}
            component="a"
            href={cloudRoute(cloud.id)}
            data-tracking-id={props.trackingId}
        >
            <ListItemIcon>
                <Icon small
                    id={isCurrent ? "cloud" : "cloud_queue"}
                    className={isCurrent ? classes.selected : ""}
                />
            </ListItemIcon>
            <ListItemText
                primary={cloud.name}
                className={isCurrent ? classes.selected : ""}
                disableTypography={isCurrent}
            />
        </MenuItem>

    );

    return item;
}

export default withStyles(styles)(CloudListItem);