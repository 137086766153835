import React, { useContext, useRef } from "react";

import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import Icon from "app/Icon";
import _ from "app/lang";
import { User } from "app/models";
import { pageContext } from "app/page/ContextProvider";
import Link from "app/page/Link";

import { ActionProps } from "../Action";
import ActionButton from "../ActionButton";
import ActionPopover from "../ActionPopover";

interface Props extends ActionProps {
    showMenuButton?: boolean;
    trackingId: string;
}

export default function UserAction(props: Props) {
    const context = useContext(pageContext);
    const user = new User(context);

    const buttonElement = useRef<HTMLButtonElement>(null);

    const handleClick = () => props.onClick(props.name);
    const handleClose = () => props.onClose(props.name);

    const { externalLinks } = context;

    const menuButton = props.showMenuButton ? (
        <ActionButton
            onClick={handleClick}
            tooltip={props.tooltip}
            iconId={props.iconId}
            dropdownText={props.dropdownText}
            dropdownIcon={props.dropdownIcon}
            active={props.open}
            dropdown={props.dropdown}
            buttonRef={buttonElement}
            trackingId={props.trackingId}
        />
    ) : null;

    return (
        <>
            {menuButton}

            <ActionPopover open={props.open}
                anchorEl={buttonElement.current}
                onClose={handleClose}
                title={props.tooltip}
                fullScreen={props.fullScreen}
                showTitleOnlyWhenFullScreen={true}
            >
                <Link to="/v2/cloud/:cloudId/profile" onClick={handleClose}>
                    <MenuItem data-tracking-id={`${props.trackingId}-profile`}>
                        <ListItemIcon>
                            <Icon small id="account_circle" />
                        </ListItemIcon>
                        <ListItemText primary={_("Your Profile")} />
                    </MenuItem>
                </Link>
                <Link to="/v2/cloud/:cloudId/account-security" onClick={handleClose}>
                    <MenuItem  data-tracking-id={`${props.trackingId}-accountSecurity`}>
                        <ListItemIcon>
                            <Icon small id="verified_user" />
                        </ListItemIcon>
                        <ListItemText primary={_("Account Security")} />
                    </MenuItem>
                </Link>
                {user.isAdministrator ? (
                    <MenuItem component="a" href="/admintools" data-tracking-id={`${props.trackingId}-adminTools`}>
                        <ListItemIcon>
                            <Icon small id="settings" />
                        </ListItemIcon>
                        <ListItemText primary={_("Admin Panel")} />
                    </MenuItem>
                ) : null}
                {externalLinks.privacyPolicy !== null || externalLinks.help !== null ? (
                    <Divider />
                ) : null}
                {externalLinks.privacyPolicy !== null ? (
                    <MenuItem component="a"
                        href={externalLinks.privacyPolicy}
                        target="_blank"
                        rel="noopener"
                        onClick={handleClose}
                        data-tracking-id={`${props.trackingId}-privacyPolicy`}
                    >
                        <ListItemIcon>
                            <Icon small id="lock" />
                        </ListItemIcon>
                        <ListItemText primary={_("Privacy Policy")} />
                        <ListItemIcon>
                            <Icon small id="open_in_new" />
                        </ListItemIcon>
                    </MenuItem>
                ) : null}
                {externalLinks.help !== null ? (
                    <MenuItem component="a"
                        href={externalLinks.help}
                        target="_blank"
                        rel="noopener"
                        onClick={handleClose}
                        data-tracking-id={`${props.trackingId}-help`}
                    >
                        <ListItemIcon>
                            <Icon small id="help" />
                        </ListItemIcon>
                        <ListItemText primary={_("Help")} />
                        <ListItemIcon>
                            <Icon small id="open_in_new" />
                        </ListItemIcon>
                    </MenuItem>
                ) : null}
                <Divider />
                <MenuItem component="a" href="/welcome/logout" data-tracking-id={`${props.trackingId}-logout`}>
                    <ListItemIcon>
                        <Icon small id="power" />
                    </ListItemIcon>
                    <ListItemText primary={_("Logout")} />
                </MenuItem>
            </ActionPopover>
        </>
    );
}
