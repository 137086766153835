import classNames from "classnames";
import moment from "moment-timezone";
import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";
import { DeviceHealth } from "app/models/Device";
import Link from "app/page/Link";
import { colorRed, colorYellow } from "app/theme";

import PathSeparator from "../PathSeparator";

import { RecentAlert } from "./models";

const DEFAULT_COLOR = "";
const WARNING_COLOR = colorYellow;
const CRITICAL_COLOR = colorRed;

const styles = (theme: Theme) => createStyles({
    root: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#f3f3f3"
        },
    },
    unseen: {
        backgroundColor: "#FCF2E7",
        "&:hover": {
            backgroundColor: "#FEF9F3"
        },
    },
    unseenText: {
        fontWeight: theme.typography.fontWeightMedium
    },
    listIcon: {
        marginTop: "5px"
    }
});

const healthColors: Record<DeviceHealth, string> = {
    HEALTH_UNKNOWN: DEFAULT_COLOR,
    HEALTH_NORMAL: DEFAULT_COLOR,
    HEALTH_WARNING: WARNING_COLOR,
    HEALTH_CRITICAL: CRITICAL_COLOR
};

const healthIcons: Record<DeviceHealth, string> = {
    HEALTH_UNKNOWN: "help_outline",
    HEALTH_NORMAL: "check",
    HEALTH_WARNING: "warning",
    HEALTH_CRITICAL: "clear"
};

interface Props extends WithStyles<typeof styles> {
    alert: RecentAlert;
    showSite: boolean;
    divider?: boolean;
    onLinkClick?: (href: string) => void;
    onAlertClick?: (alertId: string) => void;
    trackingId: string;
}

function AlertListItem(props: Props) {
    const { alert, showSite, divider = true, onLinkClick, classes, onAlertClick } = props;

    let handleLinkClick;

    if (onLinkClick) {
        handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            event.stopPropagation();
            onLinkClick(event.currentTarget.href);
        };
    }

    const listItemClass = alert.get("is_viewed") ? classes.root : classNames(classes.root, classes.unseen);
    const primaryClass = alert.get("is_viewed") ? undefined : classes.unseenText;
    let alertAge = null;

    if (alert.get("problem_at")) {
        alertAge = <AlertAge alert={alert} />;
    }

    let handleAlertClick;

    if (onAlertClick) {
        handleAlertClick = () => onAlertClick(alert.get("id"));
    }

    return (
        <>
            {divider && <Divider />}
            <ListItem dense
                alignItems="flex-start"
                className={listItemClass}
                onClick={handleAlertClick}
                data-tracking-id={props.trackingId}
            >
                <ListItemAvatar classes={{ root: classes.listIcon }}>
                    <Avatar style={{ backgroundColor: healthColors[alert.get("health_id")] }}>
                        <Icon id={healthIcons[alert.get("health_id")]} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    classes={{ primary: primaryClass }}
                    primary={alert.message}
                    secondary={(
                        <>
                            <AlertOrigin
                                alert={alert}
                                showSite={showSite}
                                onLinkClick={handleLinkClick}
                                trackingId={`${props.trackingId}-origin`}
                            />
                            <br />
                            {alertAge}
                        </>
                    )}
                />
            </ListItem>
        </>
    );
}

interface AlertOriginProps {
    alert: RecentAlert;
    showSite: boolean;
    onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    trackingId: string;
}

function AlertOrigin(props: AlertOriginProps) {
    const { alert, showSite, onLinkClick } = props;
    const isSnvrDevice = alert.attrs.snvr_device_id;

    // use alert.get() cannot check which type of device in ConditionalProps,
    // so use alert.attrs to get the attributes
    const deviceLink = (
        <Link
            to={
                isSnvrDevice ? `/smartnvr/${alert.attrs.snvr_device_id}` : `/device/${alert.attrs.device_id}`
            }
            onClick={onLinkClick}
            trackingId={
                isSnvrDevice ? `${props.trackingId}-smartnvr-device` : `${props.trackingId}-device`
            }
        >
            {isSnvrDevice ? alert.attrs.snvr_device_name : alert.attrs.device_name}
        </Link>
    );

    if (!showSite) {
        return deviceLink;
    }

    const siteLink = (
        <Link
            to={`/site/${alert.get("site_id")}`}
            onClick={onLinkClick}
            trackingId={`${props.trackingId}-site`}
        >
            {alert.get("site_name")}
        </Link>
    );

    return (
        <>
            {siteLink}<PathSeparator />{deviceLink}
        </>
    );
}

interface AlertAgeProps {
    alert: RecentAlert;
}

function AlertAge(props: AlertAgeProps) {
    return (
        <Typography color="textSecondary" variant="caption">
            {moment.utc(props.alert.get("problem_at")).fromNow()}
        </Typography>
    );
}

export default withStyles(styles)(AlertListItem);
