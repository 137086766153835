import React from "react";
import isEqual from "react-fast-compare";

import Backdrop from "@material-ui/core/Backdrop";
import { createStyles, Theme, withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import withTheme from "@material-ui/core/styles/withTheme";

import PushNotifications from "app/PushNotifications";
import _ from "app/lang";
import Advertisement from "app/page/Advertisement";
import QuickStartGuide from "app/page/QuickStartGuide";
import AppBar, { NavigationPath, NavigationPathItem } from "app/private/AppBar";
import CheckCountryCode from "app/private/CheckCountryCode";
import CheckExpiredStatus from "app/private/CheckExpiredStatus";
import CssBaseline from "app/private/CssBaseline";
import Drawer from "app/private/Drawer";
import { DrawerState } from "app/private/Drawer/Drawer";
import ErrorBoundaryInner from "app/private/ErrorBoundaryInner";
import SystemMessage, { MessageActions, MessageVariant } from "app/private/SystemMessage";
import Toast from "app/private/Toast";
import { routePrefix } from "app/route";
import { appBarHeight, darkBgColor, drawerWidth } from "app/theme";
import { SystemDialogContextProvider } from "shared/dialogs/SystemDialog";

import { pageContext, PartialContext } from "./ContextProvider";

const styles = (theme: Theme) => createStyles({
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "stretch",
        top: appBarHeight
    },

    content: {
        minWidth: 0, // So the Typography noWrap works
        flexGrow: 1,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        [theme.breakpoints.up("md")]: {
            marginLeft: drawerWidth
        }
    },

    contentBg: {
        backgroundColor: theme.palette.background.default,
    },

    contentDarkerBg: {
        backgroundColor: darkBgColor
    },

    contentFixed: {
        position: "fixed",
        top: appBarHeight,
        left: 0,
        right: 0,
        [theme.breakpoints.up("md")]: {
            marginLeft: drawerWidth
        },
        zIndex: theme.zIndex.drawer - 1
    },

    drawerBackdrop: {
        zIndex: theme.zIndex.appBar + 1,
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    }
});

export interface PageProps {
    legacyBreadcrumbs?: NavigationPath;
    darkBg?: boolean;
    parentTitle?: string;
    title?: string;
}

interface Props extends PageProps, WithStyles<typeof styles>, WithTheme {
}

interface SystemMessageItem extends Required<SystemMessageOptions> {
    closed: boolean;
}

interface SystemDialogItem {
    dialog: React.ReactElement<any>;
    domain?: string;
}

interface State {
    systemMessages: SystemMessageItem[];
    toastOpen: boolean;
    toastVariant: MessageVariant;
    toastContent: React.ReactNode;
    toastActions?: MessageActions;
    toastKeepOpen?: boolean;
    drawerState: DrawerState;
    systemDialogs: SystemDialogItem[];
    lastBreadcrumb?: string;
    drawerTransitionResolve(): void;
}

interface ToastQueueItem {
    variant: MessageVariant;
    content: React.ReactNode;
    actions?: MessageActions;
    options?: ToastOptions;
}

interface ToastOptions {
    keepOpen: boolean;
}

// Capture the document title before we change it
const documentTitle = document.title;

let pageInstance: Page;

const systemMessageDefaults = {
    variant: "success" as MessageVariant,
    actions: null,
    domain: "default-domain",
    autoClose: null,
    hasClose: true,
};

class Page extends React.Component<Props, State> {
    static contextType = pageContext;
    context!: PartialContext;

    private toastQueue: ToastQueueItem[] = [];

    constructor(props: Props, context?: PartialContext) {
        super(props, context);

        this.state = {
            systemMessages: [],
            toastOpen: false,
            toastVariant: "success",
            toastContent: null,
            drawerState: "closed",
            systemDialogs: [],
            drawerTransitionResolve: () => { },
        };

        this.handleToastExited = this.handleToastExited.bind(this);
        this.handleToastClose = this.handleToastClose.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);

        pageInstance = this;

        window.fe2.setLastBreadcrumb = (text) => {
            pageInstance.setLastBreadcrumb(text);
        };

        window.fe2.showSystemMessage = (opts) => {
            pageInstance.showSystemMessage({ domain: "legacy-page", ...opts });
        };

        window.fe2.clearSystemMessage = () => {
            pageInstance.clearSystemMessage("legacy-page");
        };
    }

    render() {
        const { classes, darkBg } = this.props;
        const {
            systemMessages,
            toastOpen,
            toastVariant,
            toastContent,
            toastActions,
            toastKeepOpen,
            drawerState,
            systemDialogs,
            lastBreadcrumb
        } = this.state;

        const path = this.props.legacyBreadcrumbs || this.composePath();
        const contentBgColorClass = darkBg ? classes.contentDarkerBg : classes.contentBg;

        if (lastBreadcrumb) {
            path.pop();

            path.push({
                title: lastBreadcrumb
            });
        }

        if (this.props.title) {
            document.title = this.props.title + " - " + documentTitle;
        }

        return (
            <CssBaseline darkBg={darkBg}>
                <PushNotifications />
                <CheckCountryCode />
                <CheckExpiredStatus />
                <Advertisement />
                <QuickStartGuide />
                <AppBar path={path}
                    onToggleDrawer={this.toggleDrawer}
                />
                <div className={classes.contentContainer}>
                    <Backdrop className={classes.drawerBackdrop}
                        open={drawerState === "open"}
                        onClick={this.toggleDrawer}
                    />
                    <Drawer state={drawerState} onAnimationEnd={this.state.drawerTransitionResolve} />
                    <div className={classes.contentFixed}>
                        {systemMessages.map((message, index) => (
                            <SystemMessage variant={message.variant}
                                actions={message.actions}
                                closed={message.closed}
                                hasClose={message.hasClose}
                                onClose={() => this.closeSystemMessage(index)}
                                key={index}
                            >
                                {message.content}
                            </SystemMessage>
                        ))}
                    </div>
                    <div className={`main ${classes.content} ${contentBgColorClass}`}>
                        <ErrorBoundaryInner>
                            {this.props.children}
                        </ErrorBoundaryInner>
                    </div>
                </div>
                <Toast open={toastOpen}
                    onExited={this.handleToastExited}
                    onClose={this.handleToastClose}
                    variant={toastVariant}
                    actions={toastActions}
                    keepOpen={toastKeepOpen}
                >
                    {toastContent}
                </Toast>
                {systemDialogs.map((item, index) => (
                    <SystemDialogContextProvider key={index}
                        value={this.createSystemDialogContext(item.dialog)}
                    >
                        {item.dialog}
                    </SystemDialogContextProvider>
                ))}
            </CssBaseline>
        );
    }

    async closeDrawer(): Promise<void> {
        if (["closing", "closed"].includes(this.state.drawerState)) {
            return;
        }

        return new Promise(resolve => {
            // will rely on this timeout if for some reason browser does not fire animationend event
            setTimeout(resolve, this.props.theme.transitions.duration.enteringScreen + 100);

            this.setState({
                drawerState: "closing",
                drawerTransitionResolve: resolve
            });
        });
    }

    setLastBreadcrumb(lastBreadcrumb: string) {
        this.setState({ lastBreadcrumb });
    }

    showToast(variant: MessageVariant, content: React.ReactNode, actions?: MessageActions, options?: ToastOptions) {
        this.toastQueue.push({ variant, content, actions, options });

        if (!this.state.toastOpen) {
            this.processToastQueue();
        }
    }

    hideToast() {
        this.setState({
            toastOpen: false,
        });
    }

    showSystemMessage(opts: SystemMessageOptions) {
        const { systemMessages } = this.state;
        const options = { ...systemMessageDefaults, ...opts };

        // Make sure no other messages from the same domain are shown
        for (const message of systemMessages) {
            if (systemMessagesIdentical(message, options)) {
                return;
            }

            if (options.domain !== undefined && message.domain === options.domain) {
                message.closed = true;
            }
        }

        const messageItem = { closed: false, ...options };

        if (options.autoClose) {
            window.setTimeout(() => {
                messageItem.closed = true;
                this.setState({});
            }, options.autoClose);

            if (messageItem.hasClose === undefined) {
                messageItem.hasClose = false;
            }
        }

        systemMessages.push(messageItem);

        this.setState({ systemMessages });

        if (options.variant === "error") {
            window.setTimeout(notifySystemErrorListeners, 0);
        }
    }

    clearSystemMessage(domain?: string) {
        const { systemMessages } = this.state;

        for (const message of systemMessages) {
            if (!domain || message.domain === domain) {
                message.closed = true;
            }
        }

        this.setState({ systemMessages });
    }

    showSystemDialog(dialog: React.ReactElement<any>, domain?: string) {
        const { systemDialogs } = this.state;

        let domainIndex = -1;

        if (domain !== undefined) {
            domainIndex = systemDialogs.findIndex(item => item.domain === domain);
        }

        if (domainIndex !== -1) {
            systemDialogs.splice(domainIndex, 1);
        }

        systemDialogs.push({ dialog, domain });

        this.setState({ systemDialogs });
    }

    hideSystemDialog(domain: string) {
        const { systemDialogs } = this.state;

        const domainIndex = systemDialogs.findIndex(item => item.domain === domain);

        if (domainIndex !== -1) {
            systemDialogs.splice(domainIndex, 1);
        }

        this.setState({ systemDialogs });
    }

    private closeSystemMessage(index: number) {
        const { systemMessages } = this.state;

        systemMessages[index].closed = true;

        this.setState({ systemMessages });
    }

    private toggleDrawer() {
        this.setState({
            drawerState: this.state.drawerState === "open" ? "closing" : "open"
        });
    }

    private processToastQueue() {
        const nextToast = this.toastQueue.shift();

        if (nextToast !== undefined) {
            this.setState({
                toastOpen: true,
                toastVariant: nextToast.variant,
                toastContent: nextToast.content,
                toastActions: nextToast.actions,
                toastKeepOpen: nextToast.options?.keepOpen,
            });
        }
    }

    private handleToastClose() {
        this.setState({ toastOpen: false });
    }

    private handleToastExited() {
        this.processToastQueue();
    }

    private composePath() {
        const context = this.context;

        if (!context.cloud || !this.props.title) {
            return [{ title: "" }];
        }

        const fullPath: NavigationPathItem[] = [{
            title: context.cloud.name,
            href: `${routePrefix}/cloud/${context.cloud.id}`
        }];

        if (context.site) {
            fullPath.push({
                title: context.site.name,
                href: `${routePrefix}/site/${context.site.id}`
            });
        }

        if (context.device && context.site) {
            fullPath.push({
                title: _("Devices"),
                href: `${routePrefix}/site/${context.site.id}/devices`
            }, {
                title: context.device.name,
                href: `${routePrefix}/site/${context.site.id}/device/${context.device.id}`
            });
        }

        if (context.smartNVR) {
            fullPath.push({
                title: _("Devices"),
                href: `${routePrefix}/v2/cloud/${context.cloud.id}/smartnvrs`
            }, {
                title: context.smartNVR.name,
                href: `${routePrefix}/smartnvr/${context.smartNVR.id}`
            });
        }

        if (this.props.parentTitle !== undefined) {
            fullPath.push({ title: this.props.parentTitle });
        }

        fullPath.push({ title: this.props.title });

        return fullPath;
    }

    private createSystemDialogContext(dialog: React.ReactElement<any>) {
        return (done: () => void) => {
            const systemDialogs = this.state.systemDialogs.filter(
                item => item.dialog !== dialog
            );

            this.setState({ systemDialogs }, done);
        };
    }
}

function systemMessagesIdentical(message: SystemMessageItem, opts: SystemMessageOptions) {
    return message.variant === opts.variant
        && isEqual(message.content, opts.content)
        && isEqual(message.actions, opts.actions)
        && message.domain === opts.domain;
}

export function showToast(
    variant: MessageVariant,
    content: React.ReactNode,
    actions?: MessageActions,
    options?: ToastOptions
) {
    pageInstance.showToast(variant, content, actions, options);
}

export function hideToast() {
    pageInstance.hideToast();
}

export interface SystemMessageOptions {
    variant?: MessageVariant;
    content: React.ReactNode;
    actions?: MessageActions;
    domain?: string;
    autoClose?: number | null;
    hasClose?: boolean;
}

export function showSystemMessage(options: SystemMessageOptions) {
    pageInstance.showSystemMessage(options);
}

export function showSystemDialog(systemDialog: React.ReactElement<any>, domain?: string) {
    pageInstance.showSystemDialog(systemDialog, domain);
}

export function hideSystemDialog(domain: string): void {
    pageInstance.hideSystemDialog(domain);
}

export function closeDrawer() {
    return pageInstance.closeDrawer();
}

type SystemErrorListener = () => void;

const systemErrorListeners: SystemErrorListener[] = [];

export function addSystemErrorListener(listener: SystemErrorListener) {
    systemErrorListeners.push(listener);
}

export function removeSystemErrorListener(listener: SystemErrorListener) {
    const index = systemErrorListeners.indexOf(listener);

    if (index !== -1) {
        systemErrorListeners.splice(index, 1);
    }
}

function notifySystemErrorListeners() {
    for (const listener of systemErrorListeners) {
        listener();
    }
}

export default withTheme(withStyles(styles)(Page));
