export type AddonSuspendType = "CLOUD_SUSPENDED" | "ADDONS_SUSPENDED";

export interface AddonAttributes {
    id: string;
    name: string;
    license_quota: number;
}

export class Addon {
    readonly id: string;
    readonly name: string;
    readonly quota: number;

    constructor(attrs: AddonAttributes) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.quota = attrs.license_quota;
    }
}

export default Addon;