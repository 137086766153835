let now: Date;

// because:
// 1. performance implication of creating date objects for every row render?
// 2. consistent date for each render
function upDate() {
    now = new Date();
    setTimeout(upDate, 1000);
}

upDate();

// encapsulates new Date
// can be improved to use server time
export function getCurrentTime() {
    return now;
}