import React, { useRef } from "react";

import Badge from "@material-ui/core/Badge";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import Icon from "app/Icon";
import _ from "app/lang";

import { ActionProps } from "../Action";
import ActionButton from "../ActionButton";
import ActionMenu from "../ActionMenu";

interface Props extends ActionProps {
    onInboxClick: () => any;
    onCloudClick: () => any;
    onUserClick: () => any;
    unreadPostCount: number;
}

export default function ActionBar(props: Props) {
    const {
        name,
        open,
        tooltip,
        iconId,
        dropdownText,
        dropdownIcon,
        dropdown,
        onClick,
        onClose,
        onInboxClick,
        onCloudClick,
        onUserClick,
        unreadPostCount
    } = props;

    const buttonElement = useRef<HTMLButtonElement>(null);

    const handleClick = () => onClick(name);
    const handleClose = () => onClose(name);

    return (
        <>
            <ActionButton onClick={handleClick}
                tooltip={tooltip}
                iconId={iconId}
                dropdownText={dropdownText}
                dropdownIcon={dropdownIcon}
                active={open}
                dropdown={dropdown}
                buttonRef={buttonElement}
                badgeContent={unreadPostCount}
                dotBadge={true}
                trackingId={props.trackingId}
            />

            <ActionMenu open={open}
                anchorEl={buttonElement.current}
                onClose={handleClose}
            >
                <MenuItem onClick={onInboxClick}>
                    <ListItemIcon>
                        <Badge color="default" badgeContent={unreadPostCount} max={99} showZero={false}>
                            <Icon small id="inbox" />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={_("Latest News")} />
                </MenuItem>
                <MenuItem onClick={onCloudClick}>
                    <ListItemIcon>
                        <Icon small id="cloud" />
                    </ListItemIcon>
                    <ListItemText primary={_("Cloud")} />
                </MenuItem>
                <MenuItem onClick={onUserClick}>
                    <ListItemIcon>
                        <Icon small id="account_circle" />
                    </ListItemIcon>
                    <ListItemText primary={_("User")} />
                </MenuItem>
            </ActionMenu>
        </>
    );
}
