import React from "react";

import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Icon from "app/Icon";
import { colorBlue, colorGrey, colorOrangeWarning, colorRed } from "app/theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "inline-flex",
    },
    inFormControlWithMargin: {
        marginTop: theme.spacing(.5)
    },
    nextToFormControlWithoutMargin: {
        height: theme.spacing(4),

        "& $main": {
            alignSelf: "center"
        },

        "& $helperText": {
            alignSelf: "center"
        }
    },
    main: {
        width: 50,
        verticalAlign: "text-bottom"
    },
    icon: {
        marginLeft: theme.spacing(1)
    },
    default: {
        color: colorBlue,
    },
    muted: {
        color: colorGrey,
    },
    danger: {
        color: colorRed
    },
    warning: {
        color: colorOrangeWarning,
    },
    helperText: {
        marginLeft: theme.spacing(.5),
        verticalAlign: "top"
    },
    helpTipContainerCenter: {
        display: "flex",
        alignItems: "center"

    },
    helpTipContainerStart: {
        display: "flex",
        alignItems: "flex-start"
    }
}));

interface Props {
    title: string | React.ReactFragment;
    placement?: TooltipProps["placement"];
    classes?: TooltipProps["classes"];
    iconId?: string;
    variant?: "muted" | "default" | "danger" | "warning";
    filled?: boolean;
    helperText?: string;
    size?: number;
    inFormControlWithMargin?: boolean;
    nextToFormControlWithoutMargin?: boolean;
}

export default function HelpTip(props: Props) {
    const classes = useStyles();
    const { placement = "top", variant = "default", size = 18, helperText } = props;
    const iconId = props.filled ? "help" : "help_outline";
    const marginClass = props.inFormControlWithMargin ? classes.inFormControlWithMargin :
            props.nextToFormControlWithoutMargin ? classes.nextToFormControlWithoutMargin :
                "";

    return (
        <div className={`${classes.root} ${marginClass}`}>
            <Tooltip title={props.title} placement={placement} classes={props.classes}>
                <span className={classes.main}>
                    <Icon id={iconId} className={`${classes.icon} ${classes[variant]}`} size={size} />
                </span>
            </Tooltip>
            {helperText ? (
                <Typography variant="caption" className={`${classes.helperText} ${classes[variant]}`}>
                    {helperText}
                </Typography>
            ) : null}
        </div>
    );
}

interface HelpTipContainerProps {
    children: React.ReactNode;
    containsInputWithHelperText?: boolean;
}

export function HelpTipContainer(props: HelpTipContainerProps) {
    const classes = useStyles();
    const className = props.containsInputWithHelperText
        ? classes.helpTipContainerStart
        : classes.helpTipContainerCenter;

    return (
        <div className={className}>
            {props.children}
        </div>
    );
}
