import React, { useEffect } from "react";

import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Icon from "app/Icon";
import MessageThemeProvider from "app/private/MessageThemeProvider";
import { MessageActions, MessageVariant } from "app/private/SystemMessage";

import { messageIcons, messageLinkStyles } from "./SystemMessage";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        maxWidth: 400,
        [theme.breakpoints.up("md")]: {
            top: 70,
            right: 20
        },

        [theme.breakpoints.down("sm")]: {
            left: 0,
            right: 0,
            top: "auto",
            bottom: 0
        }
    },

    content: {
        position: "relative",
        paddingRight: 40,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 3,

        // No rounded border in mobile view
        [theme.breakpoints.down("sm")]: {
            borderRadius: 0
        },

        ...messageLinkStyles
    },

    icon: {
        width: 16,
        height: 16,
        marginLeft: -8,
        marginRight: 8,
        flexShrink: 0,
        marginTop: 1,
        // No icon in mobile view
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },

    ...theme.messageStyles,

    message: {
        display: "flex",
        flexWrap: "nowrap",
    },

    action: {
        paddingLeft: 12
    },

    closeButton: {
        position: "absolute",
        width: 24,
        height: 24,
        padding: 0,
        top: 12,
        right: 8
    }
}));

interface Props {
    open: boolean;
    variant: MessageVariant;
    onClose: () => void;
    onExited: () => void;
    actions?: MessageActions;
    children: React.ReactNode;
    keepOpen?: boolean;
}

const SUCCESS_TOAST_HIDE_DELAY = 5000;

let autoHideTimer: number | undefined;

function ToastTransition<TransitionProps>(props: TransitionProps) {
    // Change the transition here... currently slide in from the right
    return <Slide {...props} direction="left" />;
}

export default function Toast(props: Props) {
    const classes = useStyles();

    useEffect(() => {
        if (props.open && props.variant === "success" && !props.keepOpen) {
            const delay = (props.actions !== undefined)
                ? SUCCESS_TOAST_HIDE_DELAY * 2
                : SUCCESS_TOAST_HIDE_DELAY;

            window.clearTimeout(autoHideTimer);
            autoHideTimer = window.setTimeout(props.onClose, delay);
        }
    }, [props.open]);

    function handleMessageClick(event: React.MouseEvent<HTMLDivElement>) {
        if (event.target instanceof HTMLAnchorElement || event.target instanceof HTMLButtonElement) {
            props.onClose();
        }
    }

    const message = (
        <div className={classes.message} onClick={handleMessageClick}>
            <Icon id={messageIcons[props.variant]} className={classes.icon} />
            <div>{props.children}</div>
            <IconButton onClick={props.onClose} key="close" color="inherit" className={classes.closeButton}>
                <Icon id="close" size={16} />
            </IconButton>
        </div>
    );

    return (
        <MessageThemeProvider variant={props.variant}>
            <Snackbar open={props.open}
                classes={{ root: classes.root }}
                TransitionComponent={ToastTransition}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onExited={props.onExited}
            >
                <SnackbarContent
                    className={classes[props.variant]}
                    classes={{ root: classes.content, action: classes.action }}
                    message={message}
                    action={props.actions}
                />
            </Snackbar>
        </MessageThemeProvider>
    );
}
