import moment from "moment-timezone";
import React, { useContext, useEffect } from "react";
import { Router } from "react-router-dom";

import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { browserHistory, pageContext } from "app/page/ContextProvider";
import { handleJavaScriptError } from "app/private/errorHandling";
import { createTheme } from "app/theme";

interface Props {
    children: React.ReactNode;
}

function initialize() {
    window.addEventListener("unhandledrejection", (event) => {
        handleJavaScriptError(event.reason);
    });

    window.addEventListener("error", (event) => {
        handleJavaScriptError(event.error);
    });
}

const momentLocales: { [key: string]: string } = {
    "en": "en",
    "es_419": "es-us",
    "hu": "hu",
    "ja": "ja",
    "lt": "lt",
    "zh_CN": "zh-cn",
    "zh_TW": "zh-tw"
};

function setMomentLocale(locale: string) {
    const momentLocale = momentLocales[locale];

    if (momentLocale === undefined) {
        throw new Error(`can't map locale '${locale}' to moment.js locale`);
    }

    moment.locale(momentLocale);
}

export default function Application(props: Props) {
    const context = useContext(pageContext);

    useEffect(initialize, []);
    useEffect(() => setMomentLocale(context.user.preferred_language));

    return (
        <ThemeProvider theme={createTheme(context.pageLevel, context.theme)}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()} libInstance={moment}>
                <Router history={browserHistory}>
                    {props.children}
                </Router>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
}
