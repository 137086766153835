import React from "react";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Button from "app/Button";
import _ from "app/lang";
import SystemDialog from "shared/dialogs/SystemDialog";

interface Props {
    title: string;
    text?: string;
    children?: React.ReactNode;
    buttonText?: string;
    onClick?: () => void;
}

function InformationalDialog(props: Props) {
    return (
        <SystemDialog title={props.title} canClose={false}>
            <DialogContent>
                <DialogContentText>
                    {props.text}
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.onClick && (
                    <Button variant="contained" onClick={props.onClick}>
                        {props.buttonText || _("Continue")}
                    </Button>
                  )}
            </DialogActions>
        </SystemDialog>
    );
}

export default InformationalDialog;
