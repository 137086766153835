import React, { useContext } from "react";

import _ from "app/lang";
import { pageContext, PartialContext } from "app/page/ContextProvider";

import DrawerMenu, { DrawerMenuItem } from "./DrawerMenu";

export function getMenuItems(context: PartialContext) {
    if (!context.smartNVR) {
        return [];
    }

    const legacyUrl = "/smartnvr/:smartnvrId";
    const fe2Url = "/v2/cloud/:cloudId/smartnvr/:smartnvrId";

    const items: DrawerMenuItem[] = [
        {
            icon: "dashboard",
            text: _("Dashboard"),
            href: `${legacyUrl}/dashboard`
        },
        {
            icon: "ip_camera",
            text: _("IP Cameras"),
            href: `${fe2Url}/ipcam`
        },
        {
            icon: "list_alt",
            text: _("Activity"),
            href: `${legacyUrl}/activity`
        },
    ];

    return items;
}

export default function SNVRMenu() {
    const context = useContext(pageContext);
    const items = getMenuItems(context);

    return <DrawerMenu items={items} />;
}
