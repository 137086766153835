import moment from "moment-timezone";
import React, { useContext, useEffect } from "react";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Button from "app/Button";
import Trans from "app/Trans";
import request from "app/http";
import _ from "app/lang";
import { CloudAttributes, User } from "app/models";
import { pageContext } from "app/page/ContextProvider";
import { navigate } from "app/page/Link";
import { hideSystemDialog, showSystemDialog, showToast } from "app/page/Page";
import { MessageVariant } from "app/private/SystemMessage";
import { debounce } from "app/simpleDebounce";
import { disableAlert, shouldShowAlert } from "app/userAlertStore";
import InformationalDialog from "shared/dialogs/InformationalDialog";
import SystemDialog from "shared/dialogs/SystemDialog";

const CLOUD_EXPIRATION_CHECK_DELAY = 2000;

export default function CheckExpiredStatus() {
    const context = useContext(pageContext);
    const user = new User(context);
    const cloudId = context?.cloud?.id;

    useEffect(() => {
        if (!cloudId || window.location.href.match(/\/licenses/)) {
            return;
        }

        debounce(() => {
            request(`/apiv2/cloud/${cloudId}/license-status`)
                .then(res => {
                    showExpiredStatus(context.cloud!, res, user);
                });
        }, CLOUD_EXPIRATION_CHECK_DELAY, "expiredStatus");
    }, [cloudId]);

    return null;
}

interface CloudExpiredStatus {
    suspended: boolean;
    expired: boolean;
    cloudWillExpire: boolean;
    licensesTotal: number;
    licensesUsed: number;
    freeLicensesTotal: number;
    freeLicensesUsed: number;
    planPurchasedAt: string;
    planExpiresAt: string;
    cloudCreatedAt: string;
}

function SuspendedDialogAdmin() {
    return (
        <InformationalDialog
            title={_("Cloud has been suspended")}
            text={_("Your cloud has been suspended. Please proceed to payment page.")}
            onClick={() => navigate("/cloud/:cloudId/licenses")}
        />
    );
}

function SuspendedDialogGuest() {
    return (
        <InformationalDialog
            title={_("Cloud has been suspended")}
            text={_("Your cloud has been suspended. Please contact cloud administrator.")}
        />
    );
}

function CloudDowngradeDialog(status: CloudExpiredStatus) {

    async function handleIgnore() {
        await disableAlert("FREE_TRIAL_50_DEVICES");

        hideSystemDialog("suspendedError");
    }

    async function handleOpenBilling() {
        await disableAlert("FREE_TRIAL_50_DEVICES");

        navigate("/cloud/:cloudId/licenses");
    }

    return (
        <SystemDialog
            title={_(
                "Cloud is now free for up to :num devices!",
                { num: status.freeLicensesTotal }
            )}
            canClose={true}
        >
            <DialogContent>
                <DialogContentText>
                    <Trans text={
                        "You are currently using :number_of_licenses device licenses " +
                        "and are eligible for a free trial cloud. Please check the updated " +
                        "cloud pricing on the Licences & Billing page by clicking on \"Change " +
                        "Plan\". Note that free trial plan comes with a reduced data retention period."
                    }
                        vars={{
                        number_of_licenses: status.licensesUsed + status.freeLicensesUsed
                    }}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleIgnore}>
                    {_("Ignore")}
                </Button>
                <Button variant="contained" onClick={handleOpenBilling}>
                    {_("Show Billing")}
                </Button>
            </DialogActions>
        </SystemDialog>
    );
}

async function showExpiredStatus(cloud: CloudAttributes, status: CloudExpiredStatus, user: User) {
    if (status.suspended) {
        if (user.hasPermission("manage_licenses")) {
            showSystemDialog(<SuspendedDialogAdmin />, "suspendedError");
        } else {
            showSystemDialog(<SuspendedDialogGuest />, "suspendedError");
        }
        return;
    }

    const planPurchasedAt = moment.utc(status.planPurchasedAt);
    const planExpiresAt = moment.utc(status.planExpiresAt);
    const cloudCreatedAt = moment.utc(status.cloudCreatedAt);

    // TODO: this whole dialog can be removed after 2021-10-01
    // by this time all customers will be aware of plan changes
    if (
        status.licensesUsed === 0
        && cloud.plan_type !== "trial"
        && user.hasPermission("manage_licenses")
        && planPurchasedAt < moment().subtract(3, "months")
        && planExpiresAt < moment().add(3, "months")
        && cloudCreatedAt < moment("2020-08-01")
        && await shouldShowAlert("FREE_TRIAL_50_DEVICES")
    ) {
        showSystemDialog(<CloudDowngradeDialog {...status} />, "suspendedError");
    }

    const message = [];
    let messageType: MessageVariant | undefined;

    if (status.expired) {
        message.push(
            <React.Fragment key="cloud_expired">
                <Trans text="Your cloud plan has expired." />&nbsp;
            </React.Fragment>
        );
        messageType = "error";
    } else if (status.cloudWillExpire) {
        message.push(
            <React.Fragment key="cloud_will_expire">
                <Trans text="Your cloud plan will expire soon." />&nbsp;
            </React.Fragment>
        );
        messageType = "warning";
    }

    if (messageType) {
        message.push(
            <Trans key="please_renew"
                text="Please go to <a href=':link_url'>:link</a> to renew your plan."
                vars={{
                link_url: `/cloud/${cloud.id}/licenses`,
                link: _("Licenses & Billing")
            }}
            />
        );

        showToast(messageType, <>{message}</>);
    }
}