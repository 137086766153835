import React from "react";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { WithStyles, withStyles } from "@material-ui/core/styles";

import drawerStyles from "app/private/Drawer/drawerStyles";

import DrawerSubmenu from "./DrawerSubmenu";
import SimpleMenuItem, { isItemSelected, SimpleItem } from "./SimpleMenuItem";

interface SubheaderItem {
    text: string;
}

interface SubmenuItem {
    text: string;
    icon: string;
    subitems: SimpleItem[];
}

export type DrawerMenuItem = SimpleItem | SubheaderItem | SubmenuItem;

export function isSimpleItem(item: DrawerMenuItem): item is SimpleItem {
    return item.hasOwnProperty("icon")
        && item.hasOwnProperty("href");
}

function isSubmenuItem(item: DrawerMenuItem): item is SubmenuItem {
    return item.hasOwnProperty("icon")
        && item.hasOwnProperty("subitems");
}

const stylesInjector = withStyles(drawerStyles);

interface Props extends WithStyles<typeof drawerStyles> {
    items: DrawerMenuItem[];
}

function isSubmenuOpen(item: Readonly<SubmenuItem>) {
    return item.subitems.some(isItemSelected);
}

class DrawerMenu extends React.Component<Props> {
    render() {
        const { items, classes } = this.props;

        return (
            <List component="nav" disablePadding className={classes.root}>
                {items.map((item, index) => this.renderItem(item, index))}
            </List>
        );
    }

    private renderItem(item: DrawerMenuItem, key: number) {
        const { classes } = this.props;

        if (isSimpleItem(item)) {
            return <SimpleMenuItem {...item} key={key} classes={classes} />;
        }

        if (isSubmenuItem(item)) {
            return (
                <DrawerSubmenu items={item.subitems}
                    icon={item.icon}
                    text={item.text}
                    classes={classes}
                    open={isSubmenuOpen(item)}
                    key={key}
                />
            );
        }

        return (
            <ListSubheader component="div" disableGutters disableSticky className={classes.subheader} key={key}>
                {item.text}
            </ListSubheader>
        );

    }
}

export default stylesInjector(DrawerMenu);