import React from "react";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Button from "app/Button";
import SystemDialog, { SystemDialogCommands } from "shared/dialogs/SystemDialog";

interface Props {
    title: string;
    submitLabel: string;
    cancelLabel: string;
    children: React.ReactNode;
    onClose: (confirmed: boolean) => void;
    dangerous?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

function ConfirmationDialog(props: Props) {
    const { title, children, submitLabel, cancelLabel, onClose } = props;

    let dialogCommands: SystemDialogCommands;

    return (
        <SystemDialog title={title}
            commandsRef={commands => dialogCommands = commands}
            onClose={onClose}
            maxWidth={props?.maxWidth}
        >
            <DialogContent>
                <DialogContentText component={"div"}>
                    {children}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => dialogCommands.close(false)}>
                    {cancelLabel}
                </Button>
                <Button
                    onClick={() => dialogCommands.close(true)}
                    variant="contained"
                    dangerous={props.dangerous}
                    data-test-id="button-confirm"
                >
                    {submitLabel}
                </Button>
            </DialogActions>
        </SystemDialog>
    );
}

export default ConfirmationDialog;
