import React from "react";

import { withStyles, WithStyles } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "app/Icon";
import Link, { insertRouteParams } from "app/page/Link";

import drawerStyles from "./drawerStyles";

type SimpleMenuItemProps = SimpleItem & WithStyles<typeof drawerStyles>;

export interface SimpleItem {
    text: string;
    icon: string;
    href: string;
}

export function isItemSelected(item: Readonly<SimpleItem>) {
    const actualHref = location.pathname + location.search + location.hash;
    const actualPath = location.pathname + location.search;
    const itemHref = insertRouteParams(item.href);

    if (itemHref === actualHref || itemHref === actualPath) {
        return true;
    }

    if (location.hash.length > 0) {
        // This is needed to recognize active menu item of config pages
        let pieces = itemHref.split("#");

        if (pieces.length >= 2) {
            pieces = pieces[pieces.length - 1].split("-");
        }

        if (pieces.length >= 2) {
            const locationHashPrefix = "#" + pieces[0];

            if (location.hash.indexOf(locationHashPrefix) === 0) {
                return true;
            }
        }
    }

    return false;
}

function SimpleMenuItem(props: SimpleMenuItemProps) {
    const { href, classes } = props;

    return (
        <Link to={href}>
            <ListItem button
                className={classes.itemHover}
                classes={{ root: classes.item, gutters: classes.gutters, selected: classes.selectedItem }}
                selected={isItemSelected(props)}
            >
                <ListItemIcon className={classes.icon}>
                    <Icon size={14} id={props.icon} />
                </ListItemIcon>
                <ListItemText disableTypography className={classes.text} primary={props.text} />
            </ListItem>
        </Link>
    );
}

export default withStyles(drawerStyles)(SimpleMenuItem);