import React from "react";

import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { WithStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";

import SimpleMenuItem, { isItemSelected, SimpleItem } from "./SimpleMenuItem";
import drawerStyles from "./drawerStyles";

export interface SubmenuProps extends WithStyles<typeof drawerStyles> {
    icon: string;
    text: string;
    items: SimpleItem[];
    open: boolean;
}

export interface SubmenuState {
    isOpen: boolean;
}

export default class DrawerSubmenu extends React.Component<SubmenuProps, SubmenuState> {
    constructor(props: SubmenuProps) {
        super(props);

        this.state = {
            isOpen: props.open
        };

        this.openIfClosed = this.openIfClosed.bind(this);
        this.onHashChange = this.onHashChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    onHashChange() {
        // Do we need to highlight a different component?
        if (this.props.items.some(isItemSelected)) {
            this.setState({ isOpen: true });
        }
    }

    componentDidMount() {
        addEventListener("hashchange", this.onHashChange);
    }

    componentWillUnmount() {
        removeEventListener("onhashchange", this.onHashChange);
    }

    render() {
        const { items, icon, text, classes } = this.props;
        const { isOpen } = this.state;

        return (
            <>
                <ListItem button
                    onClick={this.onToggle}
                    classes={{ root: classes.item, gutters: classes.gutters }}
                    className={classes.itemHover}
                >
                    <ListItemIcon className={classes.icon}>
                        <Icon size={14} id={icon} />
                    </ListItemIcon>
                    <ListItemText disableTypography primary={text} className={classes.text} />
                    <Icon id={isOpen ? "expand_less" : "expand_more"} className={classes.toggleIcon} />
                </ListItem>
                <Collapse in={isOpen}
                    timeout="auto"
                    classes={{
                    container: classes.submenuCollapseScrollSnap,
                    entered: classes.submenuCollapseScrollSnapDisable,
                    hidden: classes.submenuCollapseScrollSnapDisable,
                }}
                >
                    <List className={classes.submenu} onFocus={this.openIfClosed} disablePadding>
                        {items.map((item, index) => <SimpleMenuItem {...item} key={index} classes={classes} />)}
                    </List>
                </Collapse>
            </>
        );
    }

    private onToggle(event: React.MouseEvent<HTMLElement>) {
        this.setState({ isOpen: !this.state.isOpen });

        // If this is a real mouse click then remove the focus indication
        if (event.screenX !== undefined && event.screenY !== undefined) {
            event.currentTarget.blur();
        }
    }

    private openIfClosed() {
        if (!this.state.isOpen) {
            this.setState({ isOpen: true });
        }
    }
}
