import Long from "long";

import { ClientLiveData } from "shared/mgmtd";

export type ClientStatus = "blocked" | "online" | "offline";
export interface ClientAttributes {
    blocked: boolean | null;
    bssid: string;
    name: string;
    status: ClientStatus;
    host_name: string | null;
    label: string | null;
    os_name: string;
    ip_address: string;
    last_seen: string;
    mac: string;
    device_id: string;
    device_name: string;
    bytes_up: string;
    bytes_down: string;
    security: string;
    ssid: string;
    session_start: string;
    session_end: number;
    snr: number;
    bytes_up_rate: number;
    bytes_down_rate: number;
    link_time: number;
    rssi: number;
    remote_rssi: number | null;
    freq: number;
    rate_up: string;
    rate_down: string;
}

export interface ClientDataSnapshot {
    rssi: number;
    frequency: number;
    snr: number;
    bytesUp: Long;
    bytesDown: Long;
    bytesUpRate: number;
    bytesDownRate: number;
}

export function macToDec(mac: string) {
    mac = mac.replace(/[^0-9A-Fa-f]/ig, "");

    return parseInt(mac, 16);
}

export default class Client {
    readonly mac: string;
    readonly deviceId: string;
    readonly deviceName: string;
    readonly updateHistory: [Date, ClientDataSnapshot][];

    name: string;
    security: string;
    lastSeen: Date;
    sessionStart: Date;
    bytesUpRate: number;
    bytesDownRate: number;
    status: ClientStatus;

    bytesUp: Readonly<Long>;
    bytesDown: Readonly<Long>;

    rssi: number;
    snr: number;
    ipAddress: string;
    hostname?: string;
    osName: string;
    ssid: string;
    linkTime: number;
    rateUp: string;
    rateDown: string;
    frequency: number;

    constructor(attrs: ClientAttributes) {
        this.mac = attrs.mac.toLowerCase();

        this.name = attrs.label || attrs.name || attrs.host_name || this.mac;

        this.hostname = attrs.host_name || undefined;
        this.status = attrs.status;
        this.osName = attrs.os_name;

        this.ipAddress = attrs.ip_address;
        this.ssid = attrs.ssid;
        this.linkTime = attrs.link_time;
        this.snr = attrs.snr;
        this.frequency = attrs.freq;
        this.rssi = attrs.rssi;
        this.rateUp = attrs.rate_up;
        this.rateDown = attrs.rate_down;

        this.deviceId = attrs.device_id;
        this.deviceName = attrs.device_name;
        this.security = attrs.security;
        this.lastSeen = new Date(attrs.last_seen);
        this.sessionStart = new Date(attrs.session_start);
        this.bytesUpRate = attrs.bytes_up_rate;
        this.bytesDownRate = attrs.bytes_down_rate;

        this.bytesUp = Long.fromString(attrs.bytes_up, 10);
        this.bytesDown = Long.fromString(attrs.bytes_down, 10);

        this.updateHistory = [];
    }

    update(data: ClientLiveData) {
        this.rssi = this.parseRSSI(data.rssi || data.remote_rssi);
        this.frequency = this.parseFrequency(data.frequency);
        this.rateUp = String(data.rx_r);
        this.rateDown = String(data.tx_r);

        if (data.hostname) {
            this.hostname = data.hostname;

            if (this.name === this.mac) {
                this.name = data.hostname;
            }
        }

        if (data.os_type) {
            this.osName = data.os_type;
        }

        if (data.ip && data.ip !== "0.0.0.0") {
            this.ipAddress = data.ip;
        }

        this.linkTime = data.link_time;
        this.snr = this.rssi + 100; // 100 is the noise floor

        if (data.rx_64.isZero()) {
            this.bytesUp = Long.fromNumber(data.rx, true);
        } else {
            this.bytesUp = data.rx_64;
        }

        if (data.tx_64.isZero()) {
            this.bytesDown = Long.fromNumber(data.tx, true);
        } else {
            this.bytesDown = data.tx_64;
        }

        this.bytesUpRate = data.tx_s;
        this.bytesDownRate = data.rx_s;

        this.updateHistory.push([
            new Date(),
            {
                rssi: this.rssi,
                frequency: this.frequency,
                snr: this.snr,
                bytesUp: this.bytesUp,
                bytesDown: this.bytesDown,
                bytesUpRate: this.bytesUpRate,
                bytesDownRate: this.bytesDownRate,
            }
        ]);
    }

    isOnline() {
        return this.status === "online";
    }

    isOffline() {
        return this.status === "offline";
    }

    isBlocked() {
        return this.status === "blocked";
    }

    markOnline() {
        this.status = "online";
    }

    markOffline() {
        this.status = "offline";
    }

    markBlocked() {
        this.status = "blocked";
    }

    private parseRSSI(rssi: string) {
        const pieces = rssi.replace(/[^\d-]/g, "").split(/\s+/);

        return parseInt(pieces[0], 10);
    }

    private parseFrequency(frequency: string) {
        frequency = frequency.replace(/[^\d\.]/g, "");

        return Math.floor(parseFloat(frequency) * 1000);
    }
}