import React from "react";

import Dialog from "app/Dialog";

type CloseCallback = (done: () => void) => void;

const Context = React.createContext<CloseCallback | null>(null);

export const SystemDialogContextProvider = Context.Provider;

export interface SystemDialogCommands {
    close: (callbackArgument?: any) => void;
}
interface Props {
    title: string;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    canClose?: boolean;
    onClose?: (argument?: any) => void;
    commandsRef?: (commands: SystemDialogCommands) => void;
}

interface State {
    closing: boolean;
    onCloseArgument?: any;
}

class SystemDialog extends React.PureComponent<Props, State> {
    static contextType = Context;

    context!: React.ContextType<typeof Context>;

    constructor(props: Props) {
        super(props);
        this.state = { closing: false };
        this.handleExited = this.handleExited.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.updateCommandsRef();
    }

    componentDidUpdate() {
        this.updateCommandsRef();
    }

    render() {
        const { title, maxWidth, canClose, children } = this.props;
        const { closing } = this.state;

        return (
            <Dialog open={!closing}
                title={title}
                canClose={canClose}
                onClose={this.handleClose}
                onExited={this.handleExited}
                maxWidth={maxWidth}
            >
                {children}
            </Dialog>
        );
    }

    private handleClose() {
        this.setState({ closing: true });
    }

    private handleExited() {
        const { onClose } = this.props;
        const { onCloseArgument } = this.state;

        const invokeOnClose = () => {
            if (onClose !== undefined) {
                onClose(onCloseArgument);
            }
        };

        if (this.context !== null) {
            this.context(invokeOnClose);
        } else {
            invokeOnClose();
        }
    }

    private updateCommandsRef() {
        const { commandsRef } = this.props;

        if (commandsRef !== undefined) {
            const commands = {
                close: (argument?: any) => {
                    this.setState({
                        closing: true,
                        onCloseArgument: argument
                    });
                }
            };

            commandsRef(commands);
        }
    }
}

export default SystemDialog;
