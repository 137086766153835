import React, { useContext, useEffect } from "react";

import request from "app/http";
import _ from "app/lang";
import { User } from "app/models";
import { pageContext } from "app/page/ContextProvider";
import { showSystemDialog } from "app/page/Page";
import { debounce } from "app/simpleDebounce";
import { CloudBilling } from "shared/billing/billing";
import InformationalDialog from "shared/dialogs/InformationalDialog";

const COUNTRYCODE_CHECK_DELAY = 1000;

interface CloudProps {
    badge_of_courage: boolean;
    billing: CloudBilling;
    description: string | null;
    enabled: boolean;
    id: string;
    name: string;
}

export default function CheckCountryCode() {
    const context = useContext(pageContext);
    const user = new User(context);
    const cloudId = context?.cloud?.id;

    useEffect(() => {
        if (user.role !== "ROLE_OWNER" && user.role !== "ROLE_ADMIN") {
            return;
        }

        if (!cloudId || window.location.href.match(/\/cloud\/\d+\/edit/) || window.location.href.match(/\/cloud\/\d+\/licenses/)) {
            return;
        }

        debounce(() => {
            request(`/apiv2/cloud/${cloudId}`)
                .then((cloud: CloudProps) => {
                    countryCodeCheck(cloud, user);
                });
        }, COUNTRYCODE_CHECK_DELAY, "countryCodeError");
    }, [window.location.href]);

    return null;
}

function NoCountryCodeDialog() {
    const context = useContext(pageContext);
    const cloudId = context?.cloud?.id;

    return (
        <InformationalDialog
            title={_("Cloud Properties")}
            text={_("Please proceed to Cloud properties page and fill in your billing information.")}
            onClick={() => {
                window.location.href = `/v2/cloud/${cloudId}/edit`;
            }}
        />
    );
}

function countryCodeCheck(cloud: CloudProps, user: User) {
    if (!cloud.billing.address || cloud.billing.address.country === "") {
        if (user.hasPermission("manage_licenses")) {
            showSystemDialog(<NoCountryCodeDialog />, "countryCodeError");
        }
    }
}