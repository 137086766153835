export class ExtendableError extends Error {
    constructor(message?: string) {
        // Error breaks the prototype chain here
        super(message);
        // Get the actual prototype
        const actualPrototype = new.target.prototype;
        // Fix prototype chain

        Object.setPrototypeOf(this, actualPrototype);
        // Fix the stack trace
        // @ts-expect-error Error.captureStackTrace is available in node.js
        if (Error.captureStackTrace) {
            // @ts-expect-error Error.captureStackTrace is available in node.js
            Error.captureStackTrace(this, new.target);
        }
        // Adjust the name
        this.name = new.target.name;
    }
}

export default ExtendableError;
