import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import Icon from "app/Icon";
import ListItemCaret from "app/ListItemCaret";
import SearchField from "app/SearchField";
import _ from "app/lang";
import CloudListContents from "app/private/AppBar/CloudAction/CloudListContents";
import { Cloud } from "app/private/AppBar/CloudAction/models";

const SHOW_FILTER_THRESHOLD = 10;

interface Props {
    clouds?: Cloud[];
    opened: boolean;
    onMenuClick: (opened: boolean) => void;
    trackingId: string;
}

const collapseStyles = makeStyles({
    entered: {
        overflow: "auto"
    }
});

function getFilterClouds(clouds: Cloud[], filter: string) {
    return clouds.filter(cloud => {
        const lowercaseFilter = filter.toLowerCase();

        return cloud.name.toLowerCase().includes(lowercaseFilter) ||
            cloud.company && cloud.company.toLowerCase().includes(lowercaseFilter);
    });
}

function CloudList(props: Props) {
    const { clouds = [], opened, onMenuClick } = props;
    const [filter, setFilter] = useState("");
    const collapseClasses = collapseStyles();

    // Cloud list is loading, display a disabled menu item with spinner
    if (clouds.length === 0) {
        return (
            <MenuItem disabled>
                <ListItemIcon><CircularProgress size={16} /></ListItemIcon>
                <ListItemText primary={_("Switch Clouds")} />
            </MenuItem>
        );
    }

    const filteredClouds = getFilterClouds(clouds, filter);

    function getSearchField() {
        if (clouds && clouds.length >= SHOW_FILTER_THRESHOLD) {
            return (
                <SearchField
                    onChange={setFilter}
                    placeholder={_("Filter")}
                    wrapperStyle={{padding: "5px 15px"}}
                />
            );
        } else {
            return null;
        }
    }

    return (
        <>
            <MenuItem button onClick={() => onMenuClick(!opened)} data-tracking-id={`${props.trackingId}-switchClouds`}>
                <ListItemIcon><Icon small id="autorenew" /></ListItemIcon>
                <ListItemText primary={_("Switch Clouds")} />
                <ListItemCaret open={opened} />
            </MenuItem>
            <Collapse classes={collapseClasses} in={opened} appear>
                {getSearchField()}
                <CloudListContents filter={filter} clouds={filteredClouds} trackingId={props.trackingId} />
            </Collapse>
            <Divider />
        </>
    );
}

export default CloudList;
