import { createStyles, Theme } from "@material-ui/core";

const drawerStyles = (theme: Theme) => createStyles({
    root: {
        userSelect: "none"
    },

    item: {
        paddingTop: 14,
        paddingBottom: 14,
        "&$selectedItem": {
            background: theme.colors.drawer.selectedItemBackground
        }
    },

    gutters: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },

    itemHover: {
        "&:hover": {
            background: theme.colors.drawer.itemHoverBackground
        },
        "&:focus": {
            background: theme.colors.drawer.itemHoverBackground
        }
    },

    selectedItem: {
        "& $icon": {
            color: theme.palette.primary.main
        }
    },

    text: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: 13,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: 0
    },

    icon: {
        minWidth: 0,
        color: theme.colors.drawer.main
    },

    toggleIcon: {
        marginRight: theme.spacing(1),
        height: theme.spacing(2),
        width: theme.spacing(2)
    },

    submenu: {
        "& $item": {
            paddingLeft: 24
        }
    },

    submenuCollapseScrollSnap: {
        scrollSnapAlign: "end",
    },

    submenuCollapseScrollSnapDisable: {
        scrollSnapAlign: "none",
    },

    subheader: {
        color: "#979797",
        fontSize: 11,
        fontWeight: 600,
        lineHeight: "16px",
        margin: "24px 0 8px 16px"
    }
});

export default drawerStyles;