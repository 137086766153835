import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { darkBgColor, defaultTextColor, fontFamily, fontSize } from "app/theme";

const styles = (theme: Theme) => createStyles({
    "@global": {
        "html": {
            boxSizing: "border-box",
            fontSize: 16
        },
        "html *, html *::before, html *::after": {
            boxSizing: "inherit"
        },
        body: {
            fontFamily,
            fontSize,
            color: defaultTextColor,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            overflowY: "scroll", // this prevents page from resizing while components are loading
            "@media print": {
                // Save printer ink.
                backgroundColor: theme.palette.common.white
            },
        },
        "button::-moz-focus-inner": {
            border: "none"
        },

        // We want the dom element [data-tracking-id] to capture the click
        // as first element (not bubbling from many layers of children).
        "[data-tracking-id]": {
            "& > *": {
                "pointer-events": "none"
            },
            "& a, button": {
                "pointer-events": "initial"
            }
        }
    }
});

const stylesBgDefault = (theme: Theme) => createStyles({
    "@global": {
        body: {
            backgroundColor: theme.palette.background.default,
        }
    }
});

const stylesBgDark = (theme: Theme) => createStyles({
    "@global": {
        body: {
            backgroundColor: darkBgColor,
        }
    }
});

const useStyles = makeStyles(styles);
const useStylesBgDefault = makeStyles(stylesBgDefault);
const useStylesBgDark = makeStyles(stylesBgDark);

export default function CssBaseline(props: { children: React.ReactNode; darkBg?: boolean }) {
    useStyles();

    if (props.darkBg) {
        useStylesBgDark();
    } else {
        useStylesBgDefault();
    }

    return <>{props.children}</>;
}
