import { detectMobile, detectPushNotifications } from "app/detect";
import { staticContext } from "app/page/ContextProvider";
import { registerTimer } from "app/timers";
import { importExternalScript } from "app/utils";

let notifications: any;

function startNotifications(): Promise<void> {
    return notifications.setup().then(function () {
        if (!detectMobile()) {
            // I'm alive message
            // When desktop clients are not alive
            // we'll send to mobile devices
            registerTimer("notifications-timer", 4 * 60 * 1000, function () {
                notifications.tokenRefresh();
            });
        }
    });
}

function showConfirmationDialog(): Promise<boolean> {
    // Not sure if this is needed.
    return Promise.resolve(true);
}

export function setupNotifications(): Promise<void> {
    if (!window.IgniteNotifications) {
        console.warn("IgniteNotifications did not load");

        return Promise.reject();
    }

    try {
        notifications = new window.IgniteNotifications(window.firebase, {
            csrfToken: staticContext.csrfToken,
            isMobile: detectMobile(),
        });

        if (
            Notification.permission === "default"
            && !window.localStorage.getItem("user-dismiss-ignite-notifications")
        ) {
            return showConfirmationDialog().then(confirmed => {
                if (confirmed) {
                    return startNotifications();
                } else {
                    window.localStorage.setItem("user-dismiss-ignite-notifications", "1");
                }
            });
        } else if (Notification.permission === "granted") {
            return startNotifications();
        }
    } catch (err) {
        // Firebase does some extra checks to see whether browser is supported
        // and we don't want an explicit error when it's not
        console.warn(err);
    }

    return Promise.reject();
}

export function loadNotifications(): Promise<void> {
    if (!detectPushNotifications()) {
        console.warn("This browser does not support real time notifications");
        return Promise.reject();
    }

    if (notifications !== undefined) {
        return Promise.resolve();
    }

    notifications = null;

    return importExternalScript("https://www.gstatic.com/firebasejs/6.2.3/firebase-app.js")
        .then(() => importExternalScript("https://www.gstatic.com/firebasejs/6.2.3/firebase-messaging.js"))
        .then(() => importExternalScript("/js/es8/notifications.js?" + staticContext.appVersion))
        .then(setupNotifications);
}