import { useContext, useEffect } from "react";

import { detectPushNotifications } from "app/detect";
import { loadNotifications } from "app/notifications";
import { pageContext } from "app/page/ContextProvider";

export default function PushNotifications() {
    const context = useContext(pageContext);

    useEffect(() => {
        if (
            context.userCloud
            && context.userCloud.push_alerts
            && detectPushNotifications()
            && Notification.permission === "granted"
        ) {
            loadNotifications();
        }
    }, [context.userCloud]);

    return null;
}