import classNames from "classnames";
import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";
import _ from "app/lang";
import { debounce } from "app/simpleDebounce";

const styles = (theme: Theme) => createStyles({
    root: {
    },

    wrapper: {
        display: "flex",
        justifyItems: "stretch",
        alignItems: "center",
        padding: "0 4px",
        borderRadius: 4,
        border: "1px solid #f2f2f0",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.025)"
        }
    },

    busyIndicator: {
        flexGrow: 0,
        padding: 4
    },

    searchButton: {
        flexGrow: 0,
        padding: 4
    },

    clearButton: {
        flexGrow: 0,
        padding: 4
    },

    hidden: {
        visibility: "hidden"
    },

    cursorText: {
        cursor: "text"
    },

    input: {
        flexGrow: 1,
        padding: "2px 4px"
    },

    notice: {
        marginTop: 2,
        fontSize: 11,
        color: "rgba(0, 0, 0, 0.54)"
    }
});

export interface SearchFieldActions {
    focus(): void;
}

interface Props extends WithStyles<typeof styles> {
    className?: any;
    placeholder?: string;
    debounceDelay?: number;
    status?: string;
    autoFocus?: boolean;
    lazy?: boolean;
    busy?: boolean;
    minLength?: number;
    onBlur?: () => void;
    onFocus?: () => void;
    onChange: (value: string) => void;
    action?: (actions: SearchFieldActions) => void;
    wrapperStyle?: any;
    initialValue?: string;
}

interface State {
    value: string;
}

const DEFAULT_DEBOUNCE_DELAY = 1000;

class SearchField extends React.Component<Props, State> {
    private inputElement: HTMLInputElement | null = null;
    private changeQueued = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.initialValue || ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        if (this.props.action !== undefined) {
            this.props.action({ focus: () => this.focus() });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.changeQueued && prevProps.busy && !this.props.busy) {
            this.changeQueued = false;
            this.props.onChange(this.state.value);
        }
    }

    private handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter" && this.inputElement) {
            this.handleChange(this.inputElement.value);
        }
    }

    private handleChange(value: string) {
        this.setState({ value: value }, () => {
            if (this.props.lazy) {
                return;
            }

            const minLength = this.props.minLength || 1;

            if (this.state.value.length >= minLength) {
                const timeout = this.props.debounceDelay !== undefined
                    ? this.props.debounceDelay
                    : DEFAULT_DEBOUNCE_DELAY;

                debounce(this.handleSearch, timeout, "SearchField.handleSearch");
            } else {
                this.props.onChange("");
            }
        });
    }

    private handleSearch() {
        if (this.props.busy) {
            this.changeQueued = true;
        } else {
            this.props.onChange(this.state.value);
        }
    }

    private handleClear() {
        this.setState({ value: "" }, () => {
            this.focus();
            this.props.onChange("");
        });
    }

    private focus() {
        if (this.inputElement !== null) {
            this.inputElement.focus();
        }
    }

    render() {
        const {
            classes,
            className,
            busy,
            status,
            wrapperStyle = {},
            placeholder = _("Search"),
            autoFocus = true,
            minLength = 1 } = this.props;
        const { value } = this.state;

        const disableSearch = value.length < minLength;

        const startAdornment = busy
            ? <CircularProgress className={classes.busyIndicator} size={26} />
            : (
                <IconButton className={classes.searchButton}
                    onClick={this.handleSearch}
                    disabled={disableSearch}
                >
                    <Icon id="search" size={18} />
                </IconButton>
            );

        let notice: React.ReactNode = null;

        if (minLength > 1) {
            const message = value.length < minLength
                ? _("Please enter at least :minLength characters.", { minLength })
                : status;

            notice = (
                <div className={classes.notice}>{message}</div>
            );
        } else if (status !== undefined) {
            notice = (
                <div className={classes.notice}>{status}</div>
            );
        }

        const clearButtonClasses = classNames({
            [classes.clearButton]: true,
            [classes.hidden]: value.length === 0
        });

        return (
            <div className={classNames(classes.root, className)} style={wrapperStyle}>
                <div className={classes.wrapper}
                    onKeyDown={this.handleKeyDown}
                >
                    {startAdornment}
                    <InputBase className={classes.input}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        onChange={event => this.handleChange(event.target.value)}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                        value={value}
                        spellCheck={false}
                        inputRef={element => this.inputElement = element}
                    />
                    <span className={classes.cursorText}>
                        <IconButton className={clearButtonClasses}
                            onClick={this.handleClear}
                            disabled={busy}
                        >
                            <Icon id="clear" size={18} />
                        </IconButton>
                    </span>
                </div>
                {notice}
            </div>
        );
    }
}

export default withStyles(styles)(SearchField);