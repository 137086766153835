import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import { breakpoints } from "app/theme";
import useWidth from "app/useWidth";

export function getBreakpoint(width: number) {
    let currentBreakpoint: Breakpoint;
    let breakpoint: Breakpoint = "xs";

    for (currentBreakpoint in breakpoints) {
        if (width >= breakpoints[currentBreakpoint]) {
            breakpoint = currentBreakpoint;
        }
    }

    return breakpoint;
}

export function useBreakpoint() {
    return getBreakpoint(useWidth());
}

export default useBreakpoint;