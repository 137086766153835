import React, { useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import request from "app/http";
import _ from "app/lang";
import { pageContext } from "app/page/ContextProvider";

import ActionFooter from "../ActionFooter";

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1, 2)
    }
});

interface Props extends WithStyles<typeof styles> {
    unreadCount: number;
    onMarkAllRead: () => void;
    trackingId: string;
}

function Subscription(props: Props) {
    const context = useContext(pageContext);
    const [subscribing, setSubscribing] = useState(false);
    const [showSubscribe, setShowSubscribe] = useState(context.user.subscribe_newsletter);
    const handleSubscribeClick = () => {
        setSubscribing(true);
        return request({ url: "/apiv2/user/newsletter", method: "POST", data: { "subscribe": true } }).then(() => {
            setSubscribing(false);
            setShowSubscribe(true);
        });
    };
    const handleUnsubscribeClick = () => {
        setSubscribing(true);
        return request({ url: "/apiv2/user/newsletter", method: "POST", data: { "subscribe": false } }).then(() => {
            setSubscribing(false);
            setShowSubscribe(false);
        });
    };
    const showMarkAsRead = props.unreadCount > 0;

    // Must wrap Collapse in an outer div or else Chrome renders its wrong under
    // certain circumstances
    return (
        <div>
            <Collapse in={true}>
                <ActionFooter buttons alignRight>
                    {showMarkAsRead && (
                        <Button variant="text"
                            color="primary"
                            onClick={props.onMarkAllRead}
                            data-tracking-id={`${props.trackingId}-markAsRead`}
                        >
                            {_("Mark As Read")}
                        </Button>
                    )}
                    {!showSubscribe && (
                        <Button variant="text"
                            color="primary"
                            disabled={subscribing}
                            onClick={handleSubscribeClick}
                            data-tracking-id={`${props.trackingId}-subscribeToNewsletter`}
                        >
                            {_("Subscribe to Newsletter")}
                        </Button>
                    )}
                    {showSubscribe && (
                        <Button variant="text"
                            color="primary"
                            disabled={subscribing}
                            onClick={handleUnsubscribeClick}
                            data-tracking-id={`${props.trackingId}-unsubscribeToNewsletter`}
                        >
                            {_("Unsubscribe to Newsletter")}
                        </Button>
                    )}
                </ActionFooter>
            </Collapse>
        </div>
    );
}

export default withStyles(styles)(Subscription);
