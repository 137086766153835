import React, { useContext, useEffect, useState } from "react";

import DialogActions from "@material-ui/core/DialogActions";

import Button from "app/Button";
import Dialog from "app/Dialog";
import _ from "app/lang";
import { CloudContext, pageContext } from "app/page/ContextProvider";
import storage from "app/storage";
import { disableAlert, shouldShowAlert } from "app/userAlertStore";

const INTRO_CLOSE_POSTPONE = 24 * 3600 * 1000;
const INTRO_SKIP_KEY = "intro-dashboard-welcome-skip";
const INTRO_ALERT_KEY = "INTRO_DASHBOARD_WELCOME";

export default function QuickStartGuide() {
    const context = useContext(pageContext) as CloudContext;
    const [quickStartGuideOpen, setQuickStartGuideOpen] = useState<boolean>(false);
    const shouldShowQuickStartDialog = (
        context.environment !== "integration" &&
        context?.userCloud?.role_id === "ROLE_OWNER" &&
        context.cloud?.plan_type === "trial" &&
        storage.getItem<number>(INTRO_SKIP_KEY, 0) < Date.now() - INTRO_CLOSE_POSTPONE
    );

    useEffect(() => {
        if (!shouldShowQuickStartDialog) {
            return;
        }

        (async function () {
            const shouldShowIntro = await shouldShowAlert(INTRO_ALERT_KEY);

            setQuickStartGuideOpen(shouldShowIntro);
        }());

    }, [shouldShowQuickStartDialog]);

    function handleClose() {
        setQuickStartGuideOpen(false);
        storage.setItem(INTRO_SKIP_KEY, Date.now());
    }

    function handleDisable() {
        setQuickStartGuideOpen(false);
        disableAlert(INTRO_ALERT_KEY);
    }

    return (
        <Dialog
            open={quickStartGuideOpen}
            title={_("Quick Start Guide")}
            onClose={handleClose}
            onExited={() => null}
        >
            <iframe
                id="ytplayer"
                src="https://www.youtube.com/embed/91osqSH5r10?autoplay=0"
                frameBorder="0"
                allowFullScreen={true}
                style={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginBottom: "8px",
                        width: "580px",
                        height: "340px"
                    }}
            />
            <DialogActions>
                <Button onClick={handleClose}>{_("Remind Me Later")}</Button>
                <Button onClick={handleDisable}>{_("Do Not Show Again")}</Button>
            </DialogActions>
        </Dialog>
    );
}
