import classNames from "classnames";
import React from "react";

import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Link from "app/page/Link";

import PathSeparator from "./PathSeparator";

export interface NavigationPathItem {
    title: string;
    href?: string;
}

export type NavigationPath = NavigationPathItem[];

const separatorColor = "#ccc";

const styles = (theme: Theme) => createStyles({
    root: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        paddingBottom: 13,
        color: separatorColor
    },

    item: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },

    link: {
        color: theme.colors.appBar.breadcrumbs,
        textDecoration: "none",
        "&:hover": {
            color: "#fff"
        },
        "&:focus": {
            color: separatorColor,
            outline: "none"
        }
    },

    active: {
        color: "#fff"
    }
});

interface Props extends WithStyles<typeof styles> {
    path: NavigationPath;
}

class Breadcrumb extends React.Component<Props> {
    render() {
        const { path, classes } = this.props;

        return (
            <Grid container wrap="nowrap" alignItems="center" className={classes.root}>
                {path.map((item, index) => this.renderItem(item, index))}
            </Grid>
        );
    }

    private renderItem(item: NavigationPathItem, index: number) {
        const { classes } = this.props;

        if (item.href !== undefined) {
            return (
                <Grid item key={index} className={classes.item}>
                    {this.renderSeparator(index)}
                    <Link className={classes.link} to={item.href} trackingId="breadcrumb">
                        {item.title}
                    </Link>
                </Grid>
            );
        }

        return (
            <Grid item key={index} className={classNames(classes.item, classes.active)}>
                {this.renderSeparator(index)}
                {item.title}
            </Grid>
        );
    }

    private renderSeparator(index: number) {
        if (index === 0) {
            return null;
        }

        return <PathSeparator />;
    }

}

export default withStyles(styles)(Breadcrumb);